import axiosWithDomain from "../../axios";
import {
  GET_LEAD_DETAILS_MAXIMO,
  GET_REPAYMENT_HISTORY_MAXEMO,
} from "../../../constants/urls";

export const getLeadDetailsMaxemo = async (Payload) => {
  const { leadId, headers } = Payload;

  const res = await axiosWithDomain.get(
    `${process.env.REACT_APP_API_BASE_URL}${GET_LEAD_DETAILS_MAXIMO}/${leadId}`,
    { headers }
  );
  return res?.data;
};

export const getRepaymentSchedule = async (Payload) => {
  const { headers, data } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${GET_REPAYMENT_HISTORY_MAXEMO}`,
    data,
    { headers }
  );
  return res?.data;
};
