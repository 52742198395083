import style from "../../LoanAmount/LoanAmount.module.css";
import ContinueButton from "../../../Buttons/SlideContinueButton/ContinueButton";
import * as React from "react";
import Box from "@mui/material/Box";
import queryString from "query-string";
import CustomerContext from "../../../../context/CustomerContext";
import SlideStyle from "../../../../styles/Slide/style.module.css";
import { ConsentCard } from "./ConsentCard";
import { TOTAL_SLIDES } from "../../../../constants";

export default function GSTConsentSlides({
  switchToNextSlide,
  updateJourneyData,
}) {
  const { customerDetails, setCustomerDetails } =
    React.useContext(CustomerContext);
  let queryValues = queryString.parse(window.location.search);
  const JourneyVersion = queryValues?.jrnyTyp;
  let gstinsArr = customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
    ?.gstins
    ? JSON.parse(
        customerDetails?.customerDetails?.UdyamRegistrationDetails[0]?.gstins
      )
    : [];
  let gstins = gstinsArr?.length > 0 ? gstinsArr : [];

  const handleNextSlide = async () => {
    let data = {};
    const slidePayload = {
      slideName: "GST Consent",
      slideIndex: 42,
      slideIcon: "GSTConsent",
      totalSlides: TOTAL_SLIDES,
    };

    if (customerDetails?.company_type === "Proprietorship") {
      switchToNextSlide(10);
      return;
    }

    await updateJourneyData(data, slidePayload);
    if (JourneyVersion === "J3") {
      switchToNextSlide(44);
    } else {
      switchToNextSlide(43);
    }
  };

  return (
    <div className={style.slide}>
      <div className={style.slideWrapper}>
        <div className={style.slideHeader}>
          <h1 className={style.slideHeading}>GST Consent</h1>
          <p>Provide consent to fetch GST Details</p>
        </div>
        {console.log(
          "GST New Data =>",
          customerDetails,
          gstins,
          typeof gstins,
          gstins?.length
        )}
        {gstins && gstins?.length > 0
          ? gstins?.map((data) => <ConsentCard data={data} />)
          : ""}

        <div className={style.btnBox}>
          {/* <ContinueButton loading={loading} handler={addLoanAmount} /> */}
          <div className={SlideStyle.continueBtn}>
            <ContinueButton handler={handleNextSlide} />
          </div>
        </div>
      </div>
    </div>
  );
}
