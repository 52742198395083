import "../TextInputField/TextInputField.css";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ErrorContext from "../../../context/ErrorProvider";
import { useContext } from "react";

// * Components
import DatePickerInput from "../../DatePicker/DatePicker";

const DateInputField = ({
  label,
  name,
  required,
  value,
  errorType,
  handler,
  disabled,
}) => {
  const { error } = useContext(ErrorContext);

  return (
    <div className="inputContainer">
      {label && (
        <label htmlFor={name}>
          {label}
          {required ? <span style={{ color: "red" }}>*</span> : null}
        </label>
      )}
      <div className="dataFieldBox">
        <div className={`dataField`}>
        <DatePickerInput
          size="lg"
          format="dd MMM yyyy"
          value={value}
          handler={handler}
          disabled={disabled}
        />
        </div>
        {error.error && error.type === errorType ? (
          <div className="errorBox">
            <ErrorOutlineRoundedIcon style={{ fontSize: "14px" }} />
            <p>{error.message}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DateInputField;
