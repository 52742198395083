const setItem = (key, value) => {
  if (typeof value === "object") {
    value = JSON.stringify(value); // Convert object to JSON string
  }
  localStorage.setItem(key, value);
};

const getItem = (key) => {
  let value = localStorage.getItem(key);
  try {
    value = JSON.parse(value); // Convert JSON string to object
  } catch (e) {
    // Not JSON, return as is
  }
  return value;
};

const removeItem = (key) => {
  localStorage.removeItem(key);
};

const clearStorage = () => {
  localStorage.clear();
};

const checkItem = (key) => {
  return localStorage.getItem(key) !== null;
};

export { setItem, getItem, removeItem, clearStorage, checkItem };
