export const AUTH_TOKEN = "authToken";
export const APP_ID = "appID";
export const CONTACT_ID = "contactID";
export const PIPELINE_ID = "pipelineID";
export const LEAD_ID = "leadID";
export const AADHAAR_FRONT_DOC_ID = "aadhaarDocumentID";
export const AADHAAR_BACK_DOC_ID = "aadhaarBackDocumentID";
export const AADHAAR_ADDRESS_FRONT_DOC_ID = "aadhaarAddressDocumentID";
export const AADHAAR_ADDRESS_BACK_DOC_ID = "aadhaarAddressBackDocumentID";
export const JOURNEY_AUTH_TOKEN = "token";
export const LAST_ACTIVE_JOURNEY_SLIDE = "lastSlide";
export const EMPLOYEMENT_TYPE = "employmentType";
export const HAS_CO_APPLICANT = "hasCoApplicant";
export const CUSTOMER_AGE = "customerAge";
export const CUSTOMER_DETAILS = "customerDetails";
export const SLIDE_HISTORY = "slideHistory";
export const TOTAL_SLIDES = 35;
export const COOKIES_MAX_AGE = 172800; // 2 Days

// * Integration Creds

// * Digio credentials for LOCAL | DEV | STAGE
// export const DIGIO_ENVIRONMENT = "sandbox";
// export const DIGIO_SDK_SOURCE = "https://ext.digio.in/sdk/v11/digio.js";

// * Digio credentials for LOCAL | DEV | STAGE
export const DIGIO_ENVIRONMENT = "production";
export const DIGIO_SDK_SOURCE = "https://app.digio.in/sdk/v11/digio.js";

// * Loan Offer Agreement Attributes
export const LoanOfferAttributes = {
  "{loanAmount}": null,
  "{interestRate}": null,
  "{loanTenor}": null,
  "{executedAsOn}": null,
  "{signedBehalfOf}": null,
};
// export const LoanOfferAttributes = [
//   { "{loanAmount}": null },
//   { "{interestRate}": null },
//   { "{loanTenor}": null },
//   { "{executedAsOn}": null },
//   { "{signedBehalfOf}": null },
// ];
export const LoanOfferAttributesLabels = [
  "{loanAmount}",
  "{interestRate}",
  "{loanTenor}",
  "{executedAsOn}",
  "{signedBehalfOf}",
];

// * Customer Input Values
// export const MaxLoanAmount = 2500000;
export const MaxLoanAmount = 500000000;
export const MinLoanAmount = 10000;
export const genderOptions = [
  { name: "Male", value: "Male" },
  { name: "Female", value: "Female" },
];

export const qualificationExamples = [
  { name: "Undergraduate", value: "Undergraduate" },
  { name: "Graduate", value: "Graduate" },
  { name: "Post Graduate", value: "Post Graduate" },
  { name: "Other", value: "Other" },
];

export const coApplicantRelations = [
  { name: "Spouse", value: "Spouse" },
  { name: "Mother", value: "Mother" },
  { name: "Father", value: "Father" },
  { name: "Son", value: "Son" },
  { name: "Daughter", value: "Daughter" },
];

export const companyTypes = [
  { name: "Private Limited", value: "Private Limited" },
  { name: "Partnership Company", value: "Partnership Company" },
  { name: "Proprietor", value: "Proprietor" },
  { name: "Government/PSU", value: "Government/PSU" },
  { name: "LLP", value: "LLP" },
  { name: "Public", value: "Public" },
];
export const shopOwnershipTypes = [
  { name: "Sole Proprietorship", value: "Sole Proprietorship" },
  { name: "Partnership Firm", value: "Partnership Firm" },
  { name: "One-person Company", value: "One-person Company" },
  {
    name: "Limited Liability Partnership",
    value: "Limited Liability Partnership",
  },
  { name: "Private Limited Company", value: "Private Limited Company" },
  { name: "Public Limited Company", value: "Public Limited Company" },
];

export const salaryCreditTypes = [
  { name: "Bank Transfer", value: "Bank Transfer" },
  { name: "By Cash", value: "By Cash" },
  { name: "By Cheque", value: "By Cheque" },
];

export const natureOfBusinessOptions = [
  { name: "Distributor", value: "Distributor" },
  { name: "Manufacturing", value: "Manufacturing" },
  { name: "Retailer", value: "Retailer" },
  { name: "Trader", value: "Trader" },
  { name: "Services", value: "Services" },
  { name: "Others", value: "Others" },
];

export const residenceNature = [
  { name: "Self-Owned", value: "self_owned" },
  { name: "With Parents", value: "with_parents" },
  { name: "With Relatives", value: "with_relatives" },
  { name: "With Friends", value: "with_friends" },
  { name: "Rented", value: "rented" },
  { name: "Paying guest", value: "paying_guest" },
  { name: "Lease", value: "lease" },
  { name: "Pagadi", value: "pagadi" },
];

export const addressTypes = [
  { name: "Present", value: "present" },
  { name: "Permanent", value: "permanent" },
  { name: "Present and Permanent", value: "present_and_permanent" },
  { name: "Company", value: "company" },
];

export const bankAccountTypes = [
  { name: "Current", value: "current" },
  { name: "Savings", value: "savings" },
];

export const addressVerificationDocuments = [
  { name: "Aadhaar", value: "aadhaar" },
  // { name: "Rent Agreement", value: "rentAgreement" },
  // { name: "Water Bill", value: "waterBill" },
  // { name: "Electricity Bill", value: "electrictyBill" },
];

export const signModeOptions = [
  { name: "Signature Mode", value: "signatureMode" },
  { name: "Text Mode", value: "textMode" },
];
