import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
// import { journeyFieldReducer } from "./journeyFieldReducer";
import journeyFieldReducer from "./journeyFieldReducer";
// import realTimeLeadPushRed from "./realTimeLeadPushReducer";
import getProfileReducer from "./getProfileReducer";
import getBureauAutoPullDetailsReducer from "./bureauAutoPullReducer";
export const rootReducers = combineReducers({
  routing: routerReducer,
  journeyFieldData: journeyFieldReducer,
  getProfileReducer: getProfileReducer,
  getBureauAutoPullDetailsReducer: getBureauAutoPullDetailsReducer,
  // realTimeLeadPushRed:realTimeLeadPushRed
});
