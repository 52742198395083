import style from "../../LoanAmount/LoanAmount.module.css";
import { useContext } from "react";
import TextInputField from "../../../DataInputFields/TextInputField/TextInputField";
// DataInputFields/TextInputField/TextInputField";
import ContinueButton from "../../../Buttons/SlideContinueButton/ContinueButton";
import * as Errors from "../../../../utils/errors";
import ErrorContextt from "../../../../context/ErrorProvider";

export default function LoanAmountSlide({
  addJourneyLoanAmount,
  validateLoanAmount,
  loading,
}) {
  const { setError } = useContext(ErrorContextt);

  const handleLoanAmount = (loanAmount) => {
    if (!loanAmount) {
      setError({
        error: true,
        type: Errors.LOAN_AMOUNT_ERROR_TYPE,
        message: Errors.LOAN_AMOUNT_NULL_ERROR,
      });
    } else {
      validateLoanAmount(loanAmount);
    }
  };

  return (
    <div className={style.slide}>
      <div className={style.slideWrapper}>
        <div className={style.slideHeader}>
          <h1 className={style.slideHeading}>Loan Amount</h1>
          <p>Enter your desired loan amount.</p>
        </div>

        <div className={style.inputFields}>
          <TextInputField
            type="number"
            label="Loan Amount"
            placeholder="Enter Loan Amount"
            name="loanAmount"
            id="loanAmount"
            // reference={loanAmountRef}
            handler={handleLoanAmount}
            required={true}
            errorType={Errors.LOAN_AMOUNT_ERROR_TYPE}
          />
        </div>

        <div className={style.btnBox}>
          <ContinueButton loading={loading} handler={addJourneyLoanAmount} />
        </div>
      </div>
    </div>
  );
}