import "../styles/Landing/Landing.css";
import HeaderImage from "../assets/images/roopyaMoney.png";

export default function landing() {
  return (
    <>
      <div className="containetr-fluid" style={{ overflowX: "hidden" }}>
        <div className="row">
          <div className="loan-table">
            <div className="loan-logo text-center">
              {/* <a href="#"> */}
              <img className="loan-logo" src={HeaderImage} alt="img" />
              {/* </a> */}
            </div>

            <div class="loanHeading text-center">
              <h3>Apply Personal Loan</h3>
              <span style={{ fontSize: 14 }}>
                Instant Approval | Immediate Disbursal
              </span>
            </div>

            <div class="loanWhitebox">
              <div class="instant-loanBox d-flex">
                <div class="instant-loanIcon py-1">
                  <img
                    src="https://roopya.com/pl/landing/images/loan-icon1.png"
                    alt="Loan Icon"
                  />
                </div>
                <div class="instant-loanText">
                  <p>Instant Loan Upto Rs 25,00,000</p>
                </div>
              </div>
              <div class="loan-card">
                <div class="loan-topbox d-flex">
                  <div class="loan-topbox-icon">
                    <img
                      src="https://roopya.com/pl/landing/images/low-interest-icon.png"
                      alt="Interest Icon"
                    />
                  </div>
                  <div class="loan-topbox-text">
                    <h5>Loan Tenor &amp; Interest Rate</h5>
                  </div>
                </div>
                <p>Tenor 6 - 24 months : Starting 10.25% p.a.</p>
                <p>Tenor 24 - 48 months : Starting 10.50% p.a.</p>
                <p>Tenor 48 - 84 months : Starting 10.75% p.a.</p>
              </div>
              <div class="loan-card">
                <div class="loan-topbox d-flex">
                  <div class="loan-topbox-icon py-1">
                    <img
                      src="https://roopya.com/pl/landing/images/approved-icon.png"
                      alt="Approved Icon"
                    />
                  </div>
                  <div class="loan-topbox-text">
                    <h5>Fast Approval</h5>
                  </div>
                </div>
                <p>Instant approval in less then two minutes</p>
              </div>
              <div class="loan-card">
                <div class="loan-topbox d-flex">
                  <div class="loan-topbox-icon">
                    <img
                      src="https://roopya.com/pl/landing/images/low-interest-icon.png"
                      alt="low interest"
                    />
                  </div>
                  <div class="loan-topbox-text">
                    <h5>Other Charges</h5>
                  </div>
                </div>
                <p>Processing Fee : Starting 1.0% of Loan Amount</p>
                <p>Overdue Charges : Starting 2%</p>
              </div>
              <div class="loan-card loan-card-bottom">
                <div class="loan-topbox d-flex">
                  <div class="loan-topbox-icon py-1">
                    <img
                      src="https://roopya.com/pl/landing/images/secuirty-icon.png"
                      alt="Security icon"
                    />
                  </div>
                  <div class="loan-topbox-text">
                    <h5>Loan Repayment Example</h5>
                  </div>
                </div>
                <p>
                  For instance, if a person applies for a loan of Rs. 4.5 lakhs
                  at 15.5% to be repaid in 3 years, the EMI will come to Rs. 15,
                  710. To know more please read below.
                </p>
              </div>
            </div>

            {/* <div class="apply-btn">
              <a href="#">Click here to apply</a>
            </div> */}

            <div class="loanHeading text-center">
              <h3 class="mt-5 mb-3">How It works ?</h3>
            </div>

            <div class="loanWhitebox">
              <div class="loan-card">
                <div class="loan-topbox d-flex">
                  <div class="loan-topbox-icon">
                    <img
                      src="	https://roopya.com/pl/landing/images/apply-icon.png"
                      alt="Apply Icon"
                    />
                  </div>
                  <div class="loan-topbox-text">
                    <h5>Apply in 2 mins</h5>
                  </div>
                </div>
                <p>
                  100% paperless, easy loan application process can be completed
                  in 2 mins.
                </p>
              </div>
              <div class="loan-card">
                <div class="loan-topbox d-flex">
                  <div class="loan-topbox-icon py-1">
                    <img
                      src="https://roopya.com/pl/landing/images/instant-appro-icon.png"
                      alt="Instant approval icons"
                    />
                  </div>
                  <div class="loan-topbox-text">
                    <h5>Instant Approval</h5>
                  </div>
                </div>
                <p>
                  On successful submission of proper documents, we provide
                  instant approval.
                </p>
              </div>
              <div class="loan-card">
                <div class="loan-topbox d-flex">
                  <div class="loan-topbox-icon">
                    <img
                      src="https://roopya.com/pl/landing/images/revieve-money-icon.png"
                      alt="Revieve money icon"
                    />
                  </div>
                  <div class="loan-topbox-text">
                    <h5>Revieve Money in 1 Hour</h5>
                  </div>
                </div>
                <p>
                  Quickest loan disbursment directly to bank account within 1
                  hour of approval.
                </p>
              </div>
            </div>

            <div class="loanHeading text-center">
              <h3 class="mt-5 mb-3">Testimonial</h3>
            </div>

            {/* ================Carosel================ */}

            <div
              id="carouselExampleCaptions"
              data-interval="false"
              class="carousel slide loanWhitebox testimonial-text"
            >
              <div
                class="carousel-indicators align-items-center"
                style={{ marginBottom: "1.5rem" }}
              >
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to="0"
                  className="active styleBtn"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to="1"
                  className="styleBtn"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to="2"
                  className="styleBtn"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="testimonial-items">
                    <div class="testimonial-top">
                      <div class="client-review-stars">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                      </div>
                      <div class="testi-comment">
                        <i class="fa-solid fa-quote-left"></i>
                      </div>
                    </div>
                    <div class="testimonial-pera">
                      <p>
                        We got loan very quickly and good support from
                        Manoj.Good to see that Manoj listens to customer
                        requirments and fulfills accordingly. I recommend to
                        this others who are in need.Thanks
                      </p>
                    </div>
                    <div class="testimonial-heading">
                      <h4>Kiran Jangam</h4>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="testimonial-items">
                    <div class="testimonial-top">
                      <div class="client-review-stars">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                      </div>
                      <div class="testi-comment">
                        <i class="fa-solid fa-quote-left"></i>
                      </div>
                    </div>
                    <div class="testimonial-pera">
                      <p>
                        We got loan very quickly and good support from
                        Manoj.Good to see that Manoj listens to customer
                        requirments and fulfills accordingly. I recommend to
                        this others who are in need.Thanks
                      </p>
                    </div>
                    <div class="testimonial-heading">
                      <h4>Soulfree Traveller</h4>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="testimonial-items">
                    <div class="testimonial-top">
                      <div class="client-review-stars">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                      </div>
                      <div class="testi-comment">
                        <i class="fa-solid fa-quote-left"></i>
                      </div>
                    </div>
                    <div class="testimonial-pera">
                      <p>
                        We got loan very quickly and good support from
                        Manoj.Good to see that Manoj listens to customer
                        requirments and fulfills accordingly. I recommend to
                        this others who are in need.Thanks
                      </p>
                    </div>
                    <div class="testimonial-heading">
                      <h4>Sania</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ================Carosel End================ */}

            <div class="loanHeading text-center">
              <h3 class="mt-5 mb-3">
                Documents Required
                <br />
                &amp; Eligibility Criteria
              </h3>
            </div>

            <div class="loanWhitebox tablist-text">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Eligibility Criteria for Loan Approval
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    List of document required after approval
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade active show"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <ul>
                    <li>
                      <i class="fa fa-check-square" aria-hidden="true"></i> You
                      must have a business, or must be a salaried employee.
                    </li>
                    <li>
                      <i class="fa fa-check-square" aria-hidden="true"></i> Age
                      Limit : Minimum : 21 years, Maximum: 58 years.
                    </li>
                    <li>
                      <i class="fa fa-check-square" aria-hidden="true"></i> Must
                      be an Indian Citizen. Minimum monthly income should be Rs.
                      12,000.
                    </li>
                  </ul>
                </div>
                <div
                  class="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <ul>
                    <li>
                      <i class="fa fa-check-square" aria-hidden="true"></i> You
                      must have a business, or must be a salaried employee.
                    </li>
                    <li>
                      <i class="fa fa-check-square" aria-hidden="true"></i> Age
                      Limit : Minimum : 21 years, Maximum: 58 years.
                    </li>
                    <li>
                      <i class="fa fa-check-square" aria-hidden="true"></i> Must
                      be an Indian Citizen. Minimum monthly income should be Rs.
                      12,000.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="loanHeading text-center">
              <h3 class="mt-5 mb-3">Why Choose Roopya ?</h3>
            </div>

            <div class="loanWhitebox chooseroopya-text">
              <p>
                Our credit decision platform brings the best customised personal
                loan plans which matches your requirements. We approve your
                personal loan without physical documents.
              </p>
              <img
                src="	https://roopya.com/pl/landing/images/choose-roopya-pic.png"
                alt="Choose Roopya"
              />
            </div>

            <div class="loanHeading text-center">
              <h3 class="mt-5 mb-3">Numbers Speak</h3>
            </div>

            <div class="loanWhitebox speak-text">
              <div class="speak-topbox d-flex justify-content-between">
                <div class="speak-icon">
                  <img
                    src="https://roopya.com/pl/landing/images/speak-icon1.png"
                    alt="Speak Icon"
                  />
                  <h4>100K+</h4>
                  <span>Application Received</span>
                </div>
                <div class="speak-icon">
                  <img
                    src="https://roopya.com/pl/landing/images/speak-icon2.png"
                    alt="Speak Icon"
                  />
                  <h4>350cr+ </h4>
                  <span>Sanctions &amp; Counting</span>
                </div>
                <div class="speak-icon">
                  <img
                    src="https://roopya.com/pl/landing/images/speak-icon3.png"
                    alt="Speak Icon"
                  />
                  <h4>15k+ </h4>
                  <span>Indian Cities Covered</span>
                </div>
              </div>
              <p>
                When it comes to having a flexible financing option, personal
                loans are usually preferred given their flexibility of usage,
                minimum required documentation, quick processing time, and
                shorter repayment tenure of 1-5 years. So far as the Annual
                Percentage Rate, or APR, for a personal loan is concerned, it is
                determined based on the profile of the loan applicant as well as
                the lender. The rate may vary from 11.29% to 35%.
              </p>
              <p>
                For instance, if a person applies for a loan of Rs. 4.5 lakhs at
                15.5% to be repaid in 3 years, the EMI will come to Rs. 15, 710.
                This means, at the end of 3 years, the person will have to pay a
                total of Rs. 5,63,553, which will include the total payable
                interest of Rs 1,15,553. The amount that you finally pay,
                however, will not include any other applicable charges like a
                loan processing charge of 2%, which is equal to Rs. 9000 in this
                case, documentation charge of Rs. 500, and amortization schedule
                charge of Rs. 200.
              </p>
              <p>
                In addition, if there is any change in the mode of payment or
                there is any kind of swapping of a post-dated cheque, the
                additional fees are applicable accordingly. The prepayment
                options, which may or may not be available, and the applicable
                charges may vary based on the provisions of the lending
                institution.
              </p>
            </div>

            <div class="loanHeading text-center">
              <h3 class="mt-5 mb-3">Interest, Tenure, Charges</h3>
            </div>

            <div class="loanWhitebox tenure-text">
              <table width="100%">
                <tbody>
                  <tr>
                    <td>Annual percentage interest rate (APR) </td>
                    <td>Maximum of 35%</td>
                  </tr>
                  <tr>
                    <td>Tenure</td>
                    <td>Ranging from 10 months to 60 months</td>
                  </tr>
                  <tr>
                    <td>One-time Processing Fee </td>
                    <td>1% of total loan amount</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="loanHeading text-center">
              <h3 class="mt-5 mb-3">Payment Example</h3>
            </div>

            <div class="loanWhitebox payment-text">
              <p>
                For an approved loan amount of Rs.50,000 at an interest of 15%
                for a tenure of 12 months, the total of all the payments would
                be Rs.54,155.{" "}
              </p>

              <table width="100%">
                <tbody>
                  <tr>
                    <td>Approved Loan Amount</td>
                    <td>Rs.50,000 </td>
                  </tr>
                  <tr>
                    <td>Loan Tenure </td>
                    <td>12 months</td>
                  </tr>
                  <tr>
                    <td>Total of all payments</td>
                    <td>Rs.54,155</td>
                  </tr>
                  <tr>
                    <td>Principal</td>
                    <td>Rs.50,000</td>
                  </tr>
                  <tr>
                    <td>One-time Processing Fee</td>
                    <td>Rs.500 + gst</td>
                  </tr>
                  <tr>
                    <td>Total Interest Amount</td>
                    <td>Rs. 4,155</td>
                  </tr>
                </tbody>
              </table>

              <p>
                *The above example shows the schedule for a customer who makes
                all the suggested payments on time with a minimum payment of 15%
                of initial outstanding (principal). Interest Rate varies based
                on the risk profile of the customer. If you meet our simple loan
                eligibility criteria, you will receive instant cash in your bank
                account on the same day.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
