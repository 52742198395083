export const validatePAN = (PAN) => {
  if (PAN !== "") {
    // * PAN should contains 10 digits
    if (PAN.length !== 10) {
      return false;
    }
    // * First 5 digits of PAN should be only alphabets
    var alphabetsRegex = /^[a-zA-Z]+$/;
    if (!alphabetsRegex.test(PAN.slice(0, 5))) {
      return false;
    }
    // * PAN should be alphanumeric
    if (!/[0-9]/.test(PAN) || !/[a-zA-Z]/.test(PAN)) {
      return false;
    }
    // * PAN shouldn't contain any blank space character
    if (PAN.includes(" ")) {
      return false;
    }
    // * PAN shouldn't contain any special character
    let SpecialCharacterRegex = new RegExp(
      /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    );
    if (SpecialCharacterRegex.test(PAN)) {
      return false;
    }
    // * PAN fourth character should be either T,F,H,P or C
    const fourthCharacters = ["t", "f", "h", "p", "c"];
    if (!fourthCharacters.includes(PAN.charAt(3).toLowerCase())) {
      return false;
    }

    return true;
  } else {
    return false;
  }
};

// * From Thu Oct 19 2023 00:00:00 GMT+0530 (India Standard Time)  => 2023-10-19
export const formatDate = (inputDate) => {
  const dateObject = new Date(inputDate);

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
  const day = String(dateObject.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export function getBankIcon(bankName) {
  try {
    let lenderIcon;
    if (bankName) {
      lenderIcon = require(`../assets/images/banks/${bankName.toLowerCase()}.png`);
      return lenderIcon;
    }
    return false;
  } catch (err) {
    return false;
  }
}
