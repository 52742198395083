import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { CookiesProvider } from "react-cookie";
import { ErrorProvider } from "./context/ErrorProvider";
import { BrowserRouter } from "react-router-dom";
// * Context Providers
import { ModalContextProvider } from "./context/LoanAgreementModalContextProvider";
import { CustomerContextProvider } from "./context/CustomerContext";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClint = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClint}>
      <CookiesProvider>
        <BrowserRouter>
          <CustomerContextProvider>
            <ModalContextProvider>
              <ErrorProvider>
                <App />
              </ErrorProvider>
            </ModalContextProvider>
          </CustomerContextProvider>
        </BrowserRouter>
      </CookiesProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
