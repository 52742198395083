import { useRef, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import SlideStyle from "../../../../styles/Slide/style.module.css";
import { toast } from "react-toastify";
import style from "./style.module.css";
import { useLocation } from "react-router-dom";
// * Components
import OtpInputField from "../../../DataInputFields/OtpInputField/OtpInputField";
import ContinueButton from "../../../Buttons/SlideContinueButton/ContinueButton";
// * Cookies
import { useCookies } from "react-cookie";
// * Icons
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
// * Contexts
import ErrorContext from "../../../../context/ErrorProvider";
// * Utils
import * as Errors from "../../../../utils/errors";
// * API Handler
import { useMutation } from "@tanstack/react-query";
// * APIs
import {
  sendAadhaarOtp,
  verifyAadhaarOtp,
} from "../../../../api/services/documents";
// * Contexts
import CustomerContext from "../../../../context/CustomerContext";
// * Helpers
import { getCookiePathFromURL } from "../../../../helpers";
// * Constants
import {
  LAST_ACTIVE_JOURNEY_SLIDE,
  TOTAL_SLIDES,
  AADHAAR_FRONT_DOC_ID,
  AADHAAR_BACK_DOC_ID,
} from "../../../../constants";

export default function Slide({
  switchToNextSlide,
  switchToPreviousSlide,
  updateLastVisitSlide,
}) {
  // * API Key and API Secret
  const { apiKey, apiSecret } = useParams();
  let domainName = window.location.host.split(".")[0];
  const CookiePath = getCookiePathFromURL(window.location.href);
  const [_, setCookie] = useCookies(["user"]);

  const { customerDetails, setCustomerDetails } = useContext(CustomerContext);
  // * State to handle journey error
  const { error, setError } = useContext(ErrorContext);

  // * Refs
  const otpRefKey1 = useRef(null);
  const otpRefKey2 = useRef(null);
  const otpRefKey3 = useRef(null);
  const otpRefKey4 = useRef(null);
  const otpRefKey5 = useRef(null);
  const otpRefKey6 = useRef(null);

  useEffect(() => {
    otpRefKey1?.current?.focus();
  }, [otpRefKey1.current]);

  const [timer, setTimer] = useState(30);
  const [isResending, setIsResending] = useState(true);

  useEffect(() => {
    otpRefKey1?.current?.focus();
  }, [otpRefKey1.current]);

  useEffect(() => {
    let countdownInterval;

    if (isResending) {
      if (timer > 0) {
        countdownInterval = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      } else {
        setIsResending(false);
        setTimer(30);
        clearInterval(countdownInterval);
      }
    }

    return () => clearInterval(countdownInterval);
  }, [timer, isResending]);

  const clearOtpKeys = () => {
    otpRefKey1.current.value = "";
    otpRefKey2.current.value = "";
    otpRefKey3.current.value = "";
    otpRefKey4.current.value = "";
    otpRefKey5.current.value = "";
    otpRefKey6.current.value = "";
  };

  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  // * API to verify Aadhaar OTP
  const verifyAadhaarOtpAPI = useMutation({
    mutationKey: ["verifyAadhaarOtp"],
    mutationFn: (Payload) => verifyAadhaarOtp(Payload),
    onSuccess: (data) => handleVerifyAadhaarOTPSuccess(data),
    onError: (err) => handleVerifyAadhaarOTPError(err),
    retry: false,
  });
  const handleVerifyAadhaarOTPSuccess = (data) => {
    const slidePayload = {
      headers: { apiKey, apiSecret },
      leadId: customerDetails?.leadId,
      slideName: "Aadhaar OTP",
      // slideIndex: 27,
      slideIndex: null,
      slideIcon: "AadhaarOTP",
      totalSlides: TOTAL_SLIDES,
      journeyVersion: JourneyVersion,
    };
    // * Update Journey last visit slide
    updateLastVisitSlide(slidePayload);

    clearOtpKeys();

    switchToNextSlide();
    setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 27, { path: CookiePath });
  };
  const handleVerifyAadhaarOTPError = (err) => {
    clearOtpKeys();
    otpRefKey1?.current?.focus();
    toast.error(err.response.data.message);
  };

  // * API to resend Aadhaar OTP
  const sendAadhaarOtpAPI = useMutation({
    mutationKey: ["sendAadhaarOtp"],
    mutationFn: (Payload) => sendAadhaarOtp(Payload),
    onSuccess: (data) => handleResendAadhaarOTPSuccess(data),
    onError: (err) => handleResendAadhaarOTPError(err),
    retry: false,
  });
  const handleResendAadhaarOTPSuccess = (data) => {
    toast.success("OTP sent successfully.");
    const Provider = data?.data?.provider;
    console.log("Provider : ", Provider);

    if (Provider === "Cashfree") {
      setCustomerDetails({
        ...customerDetails,
        referenceId: data.data.ref_id,
      });
    } else if (Provider === "Decentro") {
      setCustomerDetails({
        ...customerDetails,
        referenceId: data.data.decentroTxnId,
      });
    }
    setIsResending(true);
  };
  const handleResendAadhaarOTPError = (err) => {
    setError({
      error: true,
      type: Errors.AADHAR_OTP_RESENT_ERROR_TYPE,
      message: "",
    });
    toast.error(err.response.data.message);
  };

  function handleOtp(otpKey, reference) {
    if (otpKey === "") {
      setError({
        error: true,
        type: Errors.OTP_ERROR_TYPE,
        message: Errors.NULL_OTP_ERROR,
      });
      reference.current.value = "";
      return false;
    }
    if (isNaN(otpKey)) {
      setError({
        error: true,
        type: Errors.OTP_ERROR_TYPE,
        message: Errors.INVALID_OTP_DIGIT_ERROR,
      });
      reference.current.value = "";
      return false;
    }
    if (error.error && error.type === Errors.OTP_ERROR_TYPE) {
      setError({
        error: false,
        type: "",
        message: "",
      });
    }
    if (reference.current.nextSibling) reference.current.nextSibling.focus();
    return true;
  }

  const handleOTPSubmit = async () => {
    const otpKey1 = otpRefKey1.current.value;
    const otpKey2 = otpRefKey2.current.value;
    const otpKey3 = otpRefKey3.current.value;
    const otpKey4 = otpRefKey4.current.value;
    const otpKey5 = otpRefKey5.current.value;
    const otpKey6 = otpRefKey6.current.value;

    let otpErrorObj = error;

    if (otpKey1 === "") {
      otpErrorObj = {
        error: true,
        type: Errors.AADHAAR_OTP_ERROR_TYPE,
        message: Errors.AADHAAR_OTP_ERROR,
      };
    }
    if (otpKey2 === "") {
      otpErrorObj = {
        error: true,
        type: Errors.AADHAAR_OTP_ERROR_TYPE,
        message: Errors.AADHAAR_OTP_ERROR,
      };
    }
    if (otpKey3 === "") {
      otpErrorObj = {
        error: true,
        type: Errors.AADHAAR_OTP_ERROR_TYPE,
        message: Errors.AADHAAR_OTP_ERROR,
      };
    }
    if (otpKey4 === "") {
      otpErrorObj = {
        error: true,
        type: Errors.AADHAAR_OTP_ERROR_TYPE,
        message: Errors.AADHAAR_OTP_ERROR,
      };
    }
    if (otpKey5 === "") {
      otpErrorObj = {
        error: true,
        type: Errors.AADHAAR_OTP_ERROR_TYPE,
        message: Errors.AADHAAR_OTP_ERROR,
      };
    }
    if (otpKey6 === "") {
      otpErrorObj = {
        error: true,
        type: Errors.AADHAAR_OTP_ERROR_TYPE,
        message: Errors.AADHAAR_OTP_ERROR,
      };
    }

    if (
      !otpErrorObj.error &&
      otpErrorObj.message !== Errors.AADHAAR_OTP_ERROR_TYPE
    ) {
      const OTP = otpKey1 + otpKey2 + otpKey3 + otpKey4 + otpKey5 + otpKey6;
      const headers = {
        apiKey: apiKey,
        apiSecret: apiSecret,
      };
      let Payload = {
        initiationTransactionId: customerDetails.referenceId,
        aadhaarNumber: customerDetails.aadhaarNumber,
        documentId: customerDetails[AADHAAR_FRONT_DOC_ID], // * Aadhaar Front
        documentId2: customerDetails[AADHAAR_BACK_DOC_ID], // * Aadhaar Front
        contactId: customerDetails.contactID,
        leadId: customerDetails.leadId,
        otp: OTP,
        headers,
      };

      verifyAadhaarOtpAPI.mutate(Payload);
    } else {
      setError(otpErrorObj);
    }
  };

  const handleResendOTP = async () => {
    if (customerDetails.aadhaarNumber) {
      const Headers = { apiKey, apiSecret };
      const Payload = {
        aadhaarNumber: customerDetails.aadhaarNumber,
        documentId: customerDetails[AADHAAR_FRONT_DOC_ID],
        headers: Headers,
      };
      sendAadhaarOtpAPI.mutate(Payload);
    } else {
      switchToPreviousSlide(20);
    }
  };

  return (
    <div className={SlideStyle.carouselSlideWrapper}>
      <div className={SlideStyle.typographyContainer}>
        <h1>Verify OTP</h1>
        <div>
          <p>
            Please enter the OTP sent to mobile number linked to your Aadhaar.
          </p>
        </div>
      </div>

      <div className={SlideStyle.dataFieldsContainer}>
        <div className={style.otpInputFields}>
          <OtpInputField
            reference={otpRefKey1}
            error={error}
            errorType={Errors.AADHAAR_OTP_ERROR_TYPE}
            // onEnterHandler={handleOTPSubmit}
            handler={(otpKey) => handleOtp(otpKey, otpRefKey1)}
            size="small"
            tabIndex={1}
          />
          <OtpInputField
            reference={otpRefKey2}
            error={error}
            errorType={Errors.AADHAAR_OTP_ERROR_TYPE}
            // onEnterHandler={handleOTPSubmit}
            handler={(otpKey) => handleOtp(otpKey, otpRefKey2)}
            tabIndex={2}
          />
          <OtpInputField
            reference={otpRefKey3}
            error={error}
            errorType={Errors.AADHAAR_OTP_ERROR_TYPE}
            // onEnterHandler={handleOTPSubmit}
            handler={(otpKey) => handleOtp(otpKey, otpRefKey3)}
            tabIndex={3}
          />
          <OtpInputField
            reference={otpRefKey4}
            error={error}
            errorType={Errors.AADHAAR_OTP_ERROR_TYPE}
            // onEnterHandler={handleOTPSubmit}
            handler={(otpKey) => handleOtp(otpKey, otpRefKey4)}
            tabIndex={4}
          />
          <OtpInputField
            reference={otpRefKey5}
            error={error}
            errorType={Errors.AADHAAR_OTP_ERROR_TYPE}
            // onEnterHandler={handleOTPSubmit}
            handler={(otpKey) => handleOtp(otpKey, otpRefKey5)}
            tabIndex={5}
          />
          <OtpInputField
            reference={otpRefKey6}
            error={error}
            errorType={Errors.AADHAAR_OTP_ERROR_TYPE}
            // onEnterHandler={handleOTPSubmit}
            handler={(otpKey) => handleOtp(otpKey, otpRefKey6)}
            tabIndex={6}
          />
        </div>

        {error.error && error.type === Errors.OTP_ERROR_TYPE ? (
          <div className="errorBox">
            <ErrorOutlineRoundedIcon style={{ fontSize: "14px" }} />
            <p>{error.message}</p>
          </div>
        ) : null}

        <div className="resendOtpBtnBox">
          {isResending ? (
            <div className="timerBox">
              <p>Resend OTP in {timer} seconds</p>
            </div>
          ) : (
            <button
              className="resendOtpBtn"
              onClick={handleResendOTP}
              disabled={sendAadhaarOtpAPI.isPending}
            >
              {sendAadhaarOtpAPI.isPending ? "Resending..." : "Resend"}
            </button>
          )}
          {/* {error.error && error.type === "resendOtpError" ? (
            <div className="otpResendError">
              <ErrorOutlineRoundedIcon
                style={{ fontSize: "15px", color: "red" }}
              />
              <p>{error.message}</p>
            </div>
          ) : null} */}
        </div>
      </div>

      <div className={style.continueBtnBox}>
        <ContinueButton
          label="Next"
          loading={verifyAadhaarOtpAPI.isPending || sendAadhaarOtpAPI.isPending}
          handler={handleOTPSubmit}
          // handler={switchToNextSlide}
          tabIndex={7}
          disabled={
            verifyAadhaarOtpAPI.isPending || sendAadhaarOtpAPI.isPending
          }
        />
      </div>
    </div>
  );
}
