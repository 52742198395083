import axios from "axios";
import axiosWithDomain from "../../axios";
import {
  GET_AUTH_TOKEN,
  CREATE_CLIENT,
  CREATE_CLIENT_IDENTIFIER,
  CREATE_ADDRESS_DETAILS,
  CREATE_BANK_ACCOUNT,
  UPLOAD_IDENTIFIER_DOCUMENTS,
  UPLOAD_CLEINT_DOCUMENT,
  CREATE_LOAN,
  APPROVE_LOAN_AMOUNT,
  DISBURSE_LOAN_AMOUNT,
} from "../../../constants/urls";

export const getAuthToken = async (data) => {
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${GET_AUTH_TOKEN}`,
    { data }
  );
  return res?.data;
};

export const createClient = async (payload) => {
  const { data, headers } = payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${CREATE_CLIENT}`,
    data,
    { headers }
  );
  return res?.data;
};

export const createClientIdentifier = async (payload) => {
  const { data, headers } = payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${CREATE_CLIENT_IDENTIFIER}`,
    data,
    { headers }
  );
  return res?.data;
};

export const createAddressDetails = async (payload) => {
  const { data, headers } = payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${CREATE_ADDRESS_DETAILS}`,
    data,
    { headers }
  );
  return res?.data;
};

export const createBankAccount = async (payload) => {
  const { data, headers } = payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${CREATE_BANK_ACCOUNT}`,
    data,
    { headers }
  );
  return res?.data;
};

export const createIdentifierDocuments = async (payload) => {
  const { data, headers } = payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${UPLOAD_IDENTIFIER_DOCUMENTS}`,
    data,
    { headers }
  );
  return res?.data;
};

export const createClientDocument = async (payload) => {
  const { data, headers } = payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${UPLOAD_CLEINT_DOCUMENT}`,
    data,
    { headers }
  );
  return res?.data;
};

export const createLoan = async (payload) => {
  const { data, headers } = payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${CREATE_LOAN}`,
    data,
    { headers }
  );
  return res?.data;
};

export const approveLoanAmount = async (payload) => {
  const { data, headers } = payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${APPROVE_LOAN_AMOUNT}`,
    data,
    { headers }
  );
  return res?.data;
};

export const disburseLoanAmount = async (payload) => {
  const { data, headers } = payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${DISBURSE_LOAN_AMOUNT}`,
    data,
    { headers }
  );
  return res?.data;
};
