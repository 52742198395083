import React from "react";
import { v4 as uuidv4 } from "uuid";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { View, Text, pdf, StyleSheet } from "@react-pdf/renderer";

// * Utilities
import { formatDateTime2 } from "../../helpers/Date/Date.helpers";
import compressBase64Image, { blobToBase64 } from "../ImageCompress";

// * Helpers
import { formatNumber } from "../../helpers/Others/Others.helpers";
import { calculateEMI } from "../../helpers";

export const PreSetVariables = {
  "Loan Amount": "Loan Amount",
  "Interest (%)": "Interest Rate",
  "Loan Tenure": "Loan Tenure",
  "Tenure (Months)": "Loan Tenure",
  "Processing Fee": "Processing Fee",
  // "Processing Fee Amount": "Processing Fee Amount",
  // "Processing Fee Amount With GST": "Processing Fee Amount With GST",
  Pan: "Pan",
  Name: "Name",
  Address: "Address",
  "Borrower Name": "Name",
  "Borrower Address": "Address",
};

export const createPreSetValues = (
  contact,
  leadDetails,
  consent,
  offerSettings,
  loanDetails,
  pipelineSettings,
  bankDetails,
  domainName,
  loanAmt,
  tenure,
  netDisbursalAmt,
  interestAmt,
  emiAPI,
  emiDate,
  emi
) => {
  const {
    phone,
    pan,
    customer_id,
    email,
    CustomerDocuments,
    full_name,
    address,
    address2,
    officeaddress1,
    officeaddress2,
  } = contact;
  let preSetValues = {
    PAN: pan || "",
    "Borrower Name": full_name || "",
    "Borrower Residential Address":
      address || address2 ? `${address} ${address2}` : "",
    "Borrower Office Address":
      officeaddress1 || officeaddress2
        ? `${officeaddress1} ${officeaddress2}`
        : "NA",
    "Loan No": leadDetails.application_id,
    "Borrwer Phone Number": phone || "",
    "Executed As On": new Date(),
    "Loan Sanction Date": new Date(),
    "Customer Id": customer_id || "",
    "Borrwer Email Id": email || "",
    "Type Of Loan": "Unsecured Loan",
    // "Date1 To31": pipelineSettings?.firstCollectionDate || "",
  };

  preSetValues["Date1 To31"] = new Date().getDate();

  // * Assign Aadhaar and PAN Number
  if (CustomerDocuments && CustomerDocuments.length) {
    const aadhaarDocument = CustomerDocuments.filter(
      (doc) => doc.docType === "aadhaar"
    );
    if (aadhaarDocument.length) {
      const aadhaar = aadhaarDocument[0];
      preSetValues["Aadhaar No"] = aadhaar.docNumber;
    }
    const panDocument = CustomerDocuments.filter(
      (doc) => doc.docType === "pan"
    );
    if (panDocument.length) {
      const pan = panDocument[0];
      const fatherName =
        pan?.customerOcrVerifiedStatuses[0]?.customerOcrVerifiedStatusDetail
          ?.father_name;
      preSetValues["Relation"] = fatherName || "NA";
    }
  }

  // * Consent
  if (consent) {
    const date = new Date(consent.otp_submitted_time);
    const dateOfApplication = date.toISOString().split("T")[0];
    preSetValues["Date Of Application"] = dateOfApplication;
  }

  // * Assigned Offer Settings
  if (offerSettings.length) {
    let settings = {};
    offerSettings.forEach((setting) => {
      const { offer_name, offer_type, JourneyOfferSetingsDetail } = setting;
      if (PreSetVariables[offer_name]) {
        const { single_value, max_value, min_value } =
          JourneyOfferSetingsDetail;

        if (offer_type === "multiple") {
          settings[PreSetVariables[offer_name]] = Math.ceil(
            (max_value + min_value) / 2
          );
        } else {
          settings[PreSetVariables[offer_name]] = single_value;
        }
      }
    });

    // * For Maximo Assign Loan Amount and Tenure
    if (domainName === "maxemocapital") {
      preSetValues["Loan Amount"] = parseInt(loanAmt);
      preSetValues["Sanction Loan Amount"] = parseInt(loanAmt);
      preSetValues["Loan Tenure"] = parseInt(tenure);
      preSetValues["Interest Rate"] = loanDetails.interest;
    } else {
      preSetValues["Loan Amount"] = loanDetails.loanAmount;
      preSetValues["Sanction Loan Amount"] = loanDetails.loanAmount;
      preSetValues["Loan Tenure"] = loanDetails.tenureInMonths;

      if (domainName === "myfindoc") {
        preSetValues["Interest Rate"] = loanDetails.interest * 12;
      } else {
        preSetValues["Interest Rate"] = loanDetails.interest;
      }
    }

    // * Assign Total Interest Amount
    if (domainName === "maxemocapital") {
      preSetValues["Total Interest Amount"] = interestAmt;
    } else {
      preSetValues["Total Interest Amount"] =
        preSetValues["Loan Amount"] *
        (preSetValues["Interest Rate"] / 100) *
        preSetValues["Loan Tenure"];
    }

    const currentdate = new Date();
    const currentDateWithFormat = currentdate.toISOString().split("T")[0];
    preSetValues["Datof Signing Of Loan Offer Agreement"] =
      currentDateWithFormat;
    preSetValues["First Emi Date"] = emiDate;
    preSetValues["Date Of Commencement"] = currentDateWithFormat;
    const month = currentdate.toLocaleString("default", { month: "long" });
    preSetValues["Month Jan To Dec"] = month;

    if (
      preSetValues["Loan Amount"] &&
      preSetValues["Interest Rate"] &&
      preSetValues["Loan Tenure"]
    ) {
      if (domainName === "maxemocapital") {
        let loan = preSetValues["Loan Amount"];
        let newTenure = preSetValues["Loan Tenure"];

        // preSetValues.EMI = Math.ceil(loan / newTenure);
        preSetValues.EMI = Math.ceil(loan / newTenure);
      } else if(domainName === "myfindoc"){
        preSetValues.EMI = emi;
      }else  {
        const loanDetails = calculateEMI(
          preSetValues["Loan Amount"],
          preSetValues["Interest Rate"],
          preSetValues["Loan Tenure"]
        );

        preSetValues.EMI = Math.floor(loanDetails.EMI);
      }

      preSetValues.Installments =
        domainName === "maxemocapital" ? tenure : loanDetails.tenureInMonths;
    }

    // * Calculate Monthly Interest Rate, Total Interest Amount
    if (preSetValues["Interest Rate"]) {
      const annualInterestRate = preSetValues["Interest Rate"];
      if (!isNaN(annualInterestRate)) {
        let monthlyInterestRate =
          (Math.pow(
            1 + annualInterestRate / 100,
            1 / preSetValues["Loan Tenure"]
          ) -
            1) *
          100;
        const factor = Math.pow(10, 3);

        if (domainName === "myfindoc") {
          preSetValues["Monthly Interest Rate"] = loanDetails.interest;
        } else {
          preSetValues["Monthly Interest Rate"] =
            Math.floor(monthlyInterestRate * factor) / factor;
        }
      }
    }

    const GSTPercentage = 18; // * 18%

    // * Calculate Processing Fee Amount
    if (settings["Processing Fee"] && preSetValues["Loan Amount"]) {
      const processingFee = settings["Processing Fee"];
      // preSetValues["Processing Fee"] = processingFee; // * In Percentage(%)

      const sanctionLoanAMount = preSetValues["Loan Amount"];

      const processingFeeAmountWithoutGST =
        (sanctionLoanAMount / 100) * processingFee;
      const GstAmount = (processingFeeAmountWithoutGST / 100) * GSTPercentage;
      const processingFeeAmountWithGST =
        processingFeeAmountWithoutGST + GstAmount;
      preSetValues["Processing Fee"] = processingFeeAmountWithGST;
      // preSetValues["Processing Fee Amount"] = processingFeeAmountWithGST;
      // preSetValues["Processing Fee Amount"] = processingFeeAmountWithoutGST;
      // preSetValues["Processing Fee Amount With GST"] = processingFeeAmountWithGST;
      preSetValues["Gst"] = GSTPercentage;
      preSetValues["Gst Amount"] = GSTPercentage;
    }

    // * Calculate File Charge for Disbursal Loan Amount
    const fileCharge = 100;
    const fileChargeWithGST = (fileCharge / 100) * GSTPercentage;
    const fileChargeAmount = fileCharge + fileChargeWithGST;
    preSetValues["File Charge"] = fileCharge;
    preSetValues["File Charge With GST"] = fileChargeAmount;

    // * Calculating Disbursal Loan Amount
    preSetValues["Disbursal Loan Amount"] =
      domainName === "maxemocapital"
        ? // ? "Rs." + netDisbursalAmt.toFixed(2).toString()
          netDisbursalAmt
        : preSetValues["Loan Amount"] -
          preSetValues["Processing Fee"] -
          preSetValues["File Charge With GST"];
  }

  // * Assign Bank Details
  if (bankDetails) {
    preSetValues["Bank Name"] = bankDetails?.bank_name;
    preSetValues["Bank Account Number"] = bankDetails?.acct_number;
    preSetValues["Ifsc Code"] = bankDetails?.ifsccode;
    preSetValues["Bank Branch"] = bankDetails?.branch;
    preSetValues["Name Of Account Holder"] = bankDetails?.account_holder_name;
  }
  return preSetValues;
};

export function removeVariableParentheses(inputString) {
  let trimmedString = inputString.replace(/[{}]/g, "");
  let result;

  if (trimmedString === trimmedString.toUpperCase()) {
    result = trimmedString;
  } else {
    let words = trimmedString.split(/(?=[A-Z])/).map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    result = words.join(" ");
  }

  return result;
}

function getVariableDataType(variable) {
  const variableDataTypes = {
    "{loanAmount}": "number",
    "{interestRate}": "number",
    "{loanTenor}": "number",
    "{executedAsOn}": "date",
    "{installments}": "number",
    "{EMI}": "number",
    "{borrowerResidentialAddress}": "text",
    "{borrowerOfficeAddress}": "text",
    "{borrowerAddress}": "text",
    "{PAN}": "text",
    "{loanSanctionDate}": "date",
    "{loanNo}": "text",
    "{dateOfApplication}": "date",
    "{processingFee}": "text",
  };
  if (variableDataTypes[variable]) {
    return variableDataTypes[variable];
  } else {
    return null;
  }
}

export function fetchDocumentVariables(inputString, preSetValues) {
  const regex = /\{([^}]+)\}/g;
  const variables = inputString.match(regex);

  if (variables) {
    return variables.map((variable) => {
      const label = removeVariableParentheses(variable);
      return (variable = {
        id: uuidv4(),
        label: label,
        variable: variable,
        dataType: getVariableDataType(variable),
        isChanged: false,
        location: null,
        endingLocation: null,
        value: preSetValues[label] ? preSetValues[label].toString() : "",
        preSet: preSetValues[label] ? "preset" : "custom",
        preSetValue: preSetValues[label] ? preSetValues[label].toString() : "",
        canChange: true,
      });
    });
  } else {
    return [];
  }
}

export function formatVariableOutput(variableValue, variableType) {
  let updatedVariableValue = null;
  switch (variableType) {
    case "{loanAmount}":
    case "{totalInterestAmount}":
    case "{disbursalLoanAmount}":
    case "{processingFee}":
    case "{EMI}":
      let amount;
      amount = formatNumber(variableValue);
      updatedVariableValue = amount ? `Rs.${amount}` : 0;
      break;
    case "{interestRate}":
    case "{monthlyInterestRate}":
      updatedVariableValue = `${variableValue}%`;
      break;
    case "{executedAsOn}":
      updatedVariableValue = variableValue
        ? formatDateTime2(variableValue)
        : "";
      break;
    case "{loanSanctionDate}":
      updatedVariableValue = variableValue
        ? formatDateTime2(variableValue)
        : "";
      break;
    case "{dateOfApplication}":
      updatedVariableValue = variableValue
        ? formatDateTime2(variableValue)
        : "";
      break;
    case "{PAN}":
      updatedVariableValue = variableValue.toString().toUpperCase();
      break;
    case "{date1To31}":
      if (variableValue && !isNaN(variableValue)) {
        updatedVariableValue = getOrdinalDate(parseInt(variableValue));
      } else {
        updatedVariableValue = variableValue;
      }
      break;
    default:
      updatedVariableValue = variableValue;
  }
  return updatedVariableValue;
}

const getOrdinalDate = (day) => {
  {
    if (typeof day !== "number" || day < 1 || day > 31) {
      throw new Error("Invalid day: please provide a number between 1 and 31.");
    }

    const suffixes = ["th", "st", "nd", "rd"];
    const remainder = day % 10;

    // Handle special cases for 11th, 12th, and 13th
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }

    return `${day}${suffixes[remainder > 3 ? 0 : remainder]}`;
  }
};

export function replaceAtLocation(
  location,
  inputString,
  searchValue,
  replaceValue
) {
  // Check if location is valid
  if (location < 0 || location >= inputString.length) {
    return {
      location: -1,
      updatedString: inputString,
    };
  }

  // Find the index of searchValue starting from the specified location
  const index = inputString.indexOf(searchValue, location);

  if (index !== -1) {
    // Replace searchValue with replaceValue
    const updatedString =
      inputString.substring(0, index) +
      replaceValue +
      inputString.substring(index + searchValue.length);

    return {
      location: index,
      updatedString: updatedString,
    };
  } else {
    // searchValue not found starting from the specified location
    return {
      location: -1,
      updatedString: inputString,
    };
  }
}

export function replaceLabel(inputString, searchValue, replaceValue) {
  const index = inputString.indexOf(searchValue);

  if (index !== -1) {
    // The searchValue was found in the inputString
    const updatedString = inputString.replace(searchValue, replaceValue);

    return {
      updatedString: updatedString,
      location: index,
    };
  } else {
    // The searchValue was not found in the inputString
    return {
      updatedString: inputString,
      location: -1,
    };
  }
}

export function handleDocumentVariableChange(
  template,
  variables,
  value,
  currentVariable
) {
  let htmlContent = template;
  let updatedContent, formattedValue;
  currentVariable = new Object(currentVariable);

  if (currentVariable.isChanged) {
    formattedValue = formatVariableOutput(value, currentVariable.variable);
    currentVariable.value = formatVariableOutput(
      currentVariable.value,
      currentVariable.variable
    );

    updatedContent = replaceAtLocation(
      currentVariable.location,
      htmlContent,
      currentVariable.value,
      formattedValue
    );
  } else {
    formattedValue = formatVariableOutput(value, currentVariable.variable);
    updatedContent = replaceLabel(
      htmlContent,
      `<span style="background-color: yellow;"> ${currentVariable.variable} </span>`,
      formattedValue
    );
  }
  const { updatedString, location } = updatedContent;

  currentVariable.location = location;
  currentVariable.endingLocation = location + (formattedValue.length - 1);
  currentVariable.isChanged = true;

  template = updatedString;

  const tempVariables = new Object(variables);
  const updatedVariables = tempVariables.map((variable) => {
    if (variable.id === currentVariable.id) {
      variable.value = value?.toString();
      variable.location = currentVariable.location;
      variable.endingLocation = currentVariable.endingLocation;
      variable.isChanged = currentVariable.isChanged;
    }
    return variable;
  });

  return { template, updatedVariables };
}

export function pdfGenerator(
  rootElementId,
  download,
  downloadFileName,
  quality
) {
  const input = document.getElementById(rootElementId);
  if (!input) {
    console.error("Element not found:", rootElementId);
    return;
  }

  return html2canvas(input, {
    scale: quality,
    logging: true,
    useCORS: true,
  }).then((canvas) => {
    const imgWidth = 210; // A4 width in mm
    const pageHeight = 295; // A4 height in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    const imgData = canvas.toDataURL("image/png");
    let pdf = new jsPDF("p", "mm");
    let position = 0;

    pdf.addImage(imgData, "PNG", 0, position, imgWidth, pageHeight, "", "FAST");
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(
        imgData,
        "png",
        0,
        position,
        imgWidth,
        imgHeight,
        "",
        "FAST"
      );
      heightLeft -= pageHeight;
    }

    if (download) pdf.save(`${downloadFileName}.pdf`);

    const pdfBase64String = pdf.output("datauristring");
    return `data:application/pdf;base64,${pdfBase64String
      .split(";base64,")
      .pop()}`;
  });
}

export async function generatePdfFromHtmlString(
  htmlString,
  filename,
  quality,
  download
) {
  // Create a temporary container for the HTML content
  const container = document.createElement("div");
  container.style.position = "absolute";
  container.style.left = "-9999px";
  container.style.top = "-9999px";
  container.style.width = "210mm"; // A4 width
  document.body.appendChild(container);

  // Set the inner HTML with padding
  container.innerHTML = `<div style="padding: 10mm;">${htmlString}</div>`;

  // Render the container with html2canvas and generate a PDF
  return html2canvas(container, {
    scale: quality,
    useCORS: true,
    logging: true,
    letterRendering: true,
  })
    .then(async (canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const compressedImage = await compressBase64Image(imgData);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      const pageWidth = 190; // A4 width - 20mm padding
      const pageHeight = 277; // A4 height - 20mm padding
      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let position = 0;

      // Initial image on first page
      pdf.addImage(compressedImage, "PNG", 10, position, imgWidth, imgHeight);

      // Check if additional pages are needed
      position += pageHeight;

      while (position < imgHeight) {
        pdf.addPage();
        pdf.addImage(
          compressedImage,
          "PNG",
          10,
          -position,
          imgWidth,
          imgHeight
        );
        position += pageHeight;
      }

      if (download) pdf.save(filename);

      // Clean up: remove the temporary container
      document.body.removeChild(container);

      const pdfBase64String = pdf.output("datauristring");

      return `data:application/pdf;base64,${pdfBase64String
        .split(";base64,")
        .pop()}`;
    })
    .catch((err) => {
      console.error("Failed to render canvas", err);
      // Clean up: remove the temporary container
      document.body.removeChild(container);
    });
}
export const renderHtmlElement2 = (element) => {
  if (typeof element === "string") {
    return <Text key={uuidv4()}>{element}</Text>;
  }
  if (React.isValidElement(element)) {
    const { type, props } = element;
    switch (type) {
      case "h1":
        return <Text key={uuidv4()}>{props.children}</Text>;
      case "p":
        return <Text key={uuidv4()}>{props.children}</Text>;
      case "div":
        return (
          <View key={uuidv4()}>{props.children.map(renderHtmlElement)}</View>
        );
      default:
        return <Text key={uuidv4()}>{props.children}</Text>;
    }
  }
  return null;
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 40,
    position: "relative",
  },
  header: {
    fontSize: 20,
    marginBottom: 0,
    textAlign: "center",
  },
  paragraph: {
    marginBottom: 0,
    fontSize: 12,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 0,
  },
  footerContainer: {
    position: "absolute",
    bottom: 0,
    left: 20,
    right: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    borderTopWidth: 1,
    borderTopColor: "#000",
    paddingTop: 10,
    paddingBottom: 100,
  },
  image1: {
    width: 100,
    height: 20,
  },
  image2: {
    width: 150,
    height: 40,
  },
  // pageNumber: {
  //     fontSize: 12,
  //     color: 'grey',
  // },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    padding: 10,
  },
  contactNumber: {
    fontSize: 12,
    color: "grey",
  },
  ol: {
    marginVertical: 10,
  },
  li: {
    marginBottom: 5,
    flexDirection: "row",
    alignItems: "flex-start",
  },
  liIndex: {
    fontSize: 12,
    marginRight: 5,
  },
  liText: {
    marginLeft: 10,
    fontSize: 16,
  },
  table: {
    border: "1px solid black",
    borderCollapse: "collapse",
    marginVertical: 10,
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1px solid black",
  },
  tableCell: {
    borderRight: "1px solid black",
    padding: 5,
    flex: 1,
  },
  sup: {
    fontSize: 10,
    lineHeight: 0,
    verticalAlign: "super",
  },
  sub: {
    fontSize: 10,
    lineHeight: 0,
    verticalAlign: "sub",
  },
});
export const renderHtmlElement = (element, index = 1) => {
  if (typeof element === "string") {
    return <Text style={styles.paragraph}>{element}</Text>;
  }
  if (React.isValidElement(element)) {
    const { type, props } = element;

    const renderChildren = () => {
      if (Array.isArray(props.children)) {
        return props.children.map((child, idx) =>
          renderHtmlElement(child, idx + 1)
        );
      } else if (type === "p" && typeof props.children === "object") {
        return (
          <Text style={styles.paragraph}>
            {renderHtmlElement(props.children)}
          </Text>
        );
      } else if (typeof props.children === "object") {
        return renderHtmlElement(props.children);
      } else {
        return <Text style={styles.paragraph}>{props.children}</Text>;
      }
    };

    switch (type) {
      case "strong":
        return <Text style={styles.header}>{renderChildren()}</Text>;
      case "h1":
        return <Text style={styles.header}>{renderChildren()}</Text>;
      case "p":
        return <Text style={styles.paragraph}>{renderChildren()}</Text>;
      case "div":
        return <View style={styles.section}>{renderChildren()}</View>;
      case "table":
        return <View style={styles.table}>{renderChildren()}</View>;
      case "tr":
        return <View style={styles.tableRow}>{renderChildren()}</View>;
      case "td":
        return <View style={styles.tableCell}>{renderChildren()}</View>;
      case "li":
        return (
          <View style={styles.li}>
            <Text style={styles.liIndex}>{index}. </Text>
            <Text style={styles.liText}>{renderChildren()}</Text>
          </View>
        );
      case "sup":
        return <Text style={styles.sup}>{renderChildren()}</Text>;
      case "sub":
        return <Text style={styles.sub}>{renderChildren()}</Text>;
      case "ol":
        return (
          <View style={styles.ol}>
            {props?.children &&
              props?.children?.length > 0 &&
              props?.children?.map((child, idx) => (
                <View key={idx} style={styles.listItem}>
                  {renderHtmlElement(child, idx + 1)}
                </View>
              ))}
          </View>
        );
      default:
        return <Text style={styles.paragraph}>{renderChildren()}</Text>;
    }
  }

  return null;
};
export const generatePdfBase64 = async ({ document }) => {
  const blob = await pdf(document).toBlob();
  const base64 = await blobToBase64(blob);
  return base64;
};

export function getDateByOffset(dayOffset) {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + dayOffset);
  const formattedDate = currentDate.toISOString().split("T")[0];
  return formattedDate;
}
