import style from "./Signature.module.css";

import { useEffect, useRef, useState, useContext } from "react";

import Webcam from "react-webcam";
import SignatureCanvas from "react-signature-canvas";
import HtmlToReact from "html-to-react";

// * Components
import DropDownField from "../DataInputFields/DropDownField/DropDownField";
import ContinueButton from "../Buttons/SlideContinueButton/ContinueButton";
import PDF from "../PDF/PDF";

// * Contexts
import CustomerContext from "../../context/CustomerContext";

// * Utilities
import {
  generatePdfBase64,
  generatePdfFromHtmlString,
  renderHtmlElement,
} from "../../utils/SanctionLetter/SanctionLetter.utils";

// * Constants
import { signModeOptions } from "../../constants";
import { toast } from "react-toastify";
import { Image, View, pdf } from "@react-pdf/renderer";
import { blobToBase64 } from "../../utils/ImageCompress";

export default function Signature({
  loading,
  documentType,
  document,
  setDocument,
  uploadGeneratedSanctionAndAgreement,
  handleUploadDocumentToDigio,
  uploadCustomerSelfie,
  docQuality,
}) {
  const htmlToReactParser = new HtmlToReact.Parser();
  const { customerDetails } = useContext(CustomerContext);

  const signatureCanvasRef = useRef(null);
  const signaturePadRef = useRef(null);
  const webcamRef = useRef(null);

  const initialCustomSignature = {
    signature: null,
    font: null,
  };
  const [customSignature, setCustomSignature] = useState(
    initialCustomSignature
  );
  const [conversionLoading, setConversionLoading] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(400);
  const [signatureMode, setSignatureMode] = useState("signatureMode");
  const videoConstraints = {
    facingMode: "user",
  };

  useEffect(() => {
    // Function to handle resizing
    const handleResize = () => {
      if (signatureCanvasRef.current) {
        setCanvasWidth(signaturePadRef.current?.offsetWidth);
      }
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const clearCustomSignature = () => setCustomSignature(initialCustomSignature);
  const clearSignature = () => signatureCanvasRef.current.clear();

  const handleModeChange = (mode) => {
    setSignatureMode(mode);
    if (customSignature.signature) clearCustomSignature();
  };
  const handleCameraAccessFailure = (error) => {
    console.log("Camera Access Error : ", error);
  };

  const handleSubmit = async () => {
    let signature;
    if (signatureMode === "signatureMode") {
      const signatureImage = signatureCanvasRef.current.toDataURL();
      signature = `
      <div style="display:flex; justify-content: flex-end;">
      <img src=${signatureImage} alt="customer signature" style="height: 120px; width: auto"/>
      </div>
      `;
    } else {
      if (customSignature.signature) {
        signature = `
          <div style="display:flex; justify-content: flex-end;">
            <div>
              <p style="font-family: ${customSignature.font};font-size: 30px;">${customSignature.signature}</p>
            </div>
          </div>
          `;
      } else {
        toast.error("Please choose a signature.");
      }
    }

    const documentHtml = document.document;
    const content = document.content; // * Contains Header/Footer images as of now.

    const documentWithSign = documentHtml.concat(signature);
    setDocument((prev) => ({ ...prev, documentWithSign }));

    // * Convert normal HTML to JSX
    const JSX = htmlToReactParser.parse(documentWithSign);

    // * Render updated HTML content
    const pdfCode = JSX.map((ele) => renderHtmlElement(ele));

    // * Format document
    const blob = await pdf(
      <PDF
        size="A4"
        body={pdfCode}
        content={content}
      />
    ).toBlob();
    const base64 = await blobToBase64(blob);

    const contact = customerDetails?.leadDetails?.Contact;
    setConversionLoading(true);

    let documentPdfName;
    if (documentType === "agreement") {
      documentPdfName = `${contact?.full_name}_agreement`;
    } else {
      documentPdfName = `${contact?.full_name}_sanction_letter`;
    }

    // const documentBase64 = await generatePdfFromHtmlString(
    //   documentWithSign,
    //   documentPdfName,
    //   docQuality,
    //   false
    // );

    if (base64) {
      setConversionLoading(false);
      let payloadDetails = {};
      if (documentType === "agreement") {
        payloadDetails = {
          fileName: documentPdfName,
          documentName: "Agreement",
          docType: "loanOfferAgreement",
        };
      } else {
        payloadDetails = {
          fileName: documentPdfName,
          documentName: "Sanction Letter",
          docType: "sanctionLetter",
        };
      }

      uploadGeneratedSanctionAndAgreement(base64, payloadDetails);

      const clickedSelfie = webcamRef.current.getScreenshot();
      uploadCustomerSelfie(clickedSelfie);

      let fileName = "";
      if (documentType === "agreement") {
        fileName = `${customerDetails.fullName}_agreement.pdf`;
      } else {
        fileName = `${customerDetails.fullName}_sanction_letter.pdf`;
      }
      handleUploadDocumentToDigio(base64.split("base64,")[1], fileName);
    }
  };
  const handleCustomSignature = (font, signature) => {
    setCustomSignature({ signature, font });
  };

  return (
    <>
      <div className={style.cameraContainer}>
        <Webcam
          audio={false}
          videoConstraints={videoConstraints}
          imageSmoothing={true}
          screenshotQuality={1}
          ref={webcamRef}
          mirrored={false}
          className={style.webCam}
          onUserMediaError={(error) => handleCameraAccessFailure(error)}
          screenshotFormat="image/jpeg"
        />
      </div>

      <div className={style.signModeSelector}>
        <DropDownField
          label="Select Signature Mode"
          name="signatureMode"
          required={true}
          options={signModeOptions}
          defaultValue={signatureMode}
          nameKey="name"
          valueKey="value"
          handler={(value) => handleModeChange(value)}
        />
      </div>

      {signatureMode === "signatureMode" ? (
        <div className={style.signatureMode}>
          <div className={style.signaturePad} ref={signaturePadRef}>
            <SignatureCanvas
              ref={signatureCanvasRef}
              penColor="black"
              dotSize={0.5}
              canvasProps={{
                width: canvasWidth ? canvasWidth : 400,
                height: 200,
              }}
            />
          </div>
          <div className={style.signatureActions}>
            <button
              className={`${style.signatureActionBtn} ${style.secondaryBtn}`}
              onClick={clearSignature}
              disabled={conversionLoading || loading}
            >
              Clear
            </button>
          </div>
        </div>
      ) : (
        <div className={style.textMode}>
          {customSignature.signature ? (
            <div className={style.customSignatureBox}>
              <div className={style.customSignature}>
                <p
                  style={{
                    fontSize: "2.5rem",
                    fontFamily: customSignature.font,
                  }}
                >
                  {customSignature.signature}
                </p>
              </div>
              <button
                className={`${style.signatureActionBtn} ${style.secondaryBtn}`}
                onClick={clearCustomSignature}
                disabled={conversionLoading || loading}
              >
                Clear
              </button>
            </div>
          ) : (
            <>
              <p className={style.textModeHeading}>Choose a signature</p>
              <div className={style.signatures}>
                <button
                  style={{ fontFamily: "Shadows Into Light, cursive" }}
                  onClick={() =>
                    handleCustomSignature(
                      "Shadows Into Light, cursive",
                      customerDetails?.leadDetails?.Contact?.full_name
                    )
                  }
                >
                  {customerDetails?.leadDetails?.Contact?.full_name}
                </button>
                <button
                  style={{ fontFamily: "Caveat, cursive" }}
                  onClick={() =>
                    handleCustomSignature(
                      "Caveat, cursive",
                      customerDetails?.leadDetails?.Contact?.full_name
                    )
                  }
                >
                  {customerDetails?.leadDetails?.Contact?.full_name}
                </button>
                <button
                  style={{ fontFamily: "Abel, sans-serif" }}
                  onClick={() =>
                    handleCustomSignature(
                      "Abel, sans-serif",
                      customerDetails?.leadDetails?.Contact?.full_name
                    )
                  }
                >
                  {customerDetails?.leadDetails?.Contact?.full_name}
                </button>
                <button
                  style={{ fontFamily: "Playfair Display, serif" }}
                  onClick={() =>
                    handleCustomSignature(
                      "Playfair Display, serif",
                      customerDetails?.leadDetails?.Contact?.full_name
                    )
                  }
                >
                  {customerDetails?.leadDetails?.Contact?.full_name}
                </button>
                <button
                  style={{ fontFamily: "Satisfy, cursive" }}
                  onClick={() =>
                    handleCustomSignature(
                      "Satisfy, cursive",
                      customerDetails?.leadDetails?.Contact?.full_name
                    )
                  }
                >
                  {customerDetails?.leadDetails?.Contact?.full_name}
                </button>
              </div>
            </>
          )}
        </div>
      )}

      <div className={style.slideFooter}>
        <ContinueButton
          loading={conversionLoading || loading}
          handler={handleSubmit}
        />
      </div>
    </>
  );
}
