import AadhaarVerificationComponents from "./AadharVerificationSlideComponent";

export default function AadhaarVerificationSlide({
  activeSlide,
  switchToNextSlide,
  updateLastVisitSlide,
}) {
  return (
    <div className="carouselSlideWrapper" style={{ marginTop: "30px" }}>
      <div className="typographyContainer">
        <h1>Aadhaar Verification</h1>
        <p>Please upload your Aadhaar Card.</p>
      </div>

      <AadhaarVerificationComponents
        activeSlide={activeSlide}
        updateLastVisitSlide={updateLastVisitSlide}
        switchToNextSlide={switchToNextSlide}
      />
    </div>
  );
}
