// * Styles
import style from "./TakeSelfie.module.css";
import { useState, useCallback, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
// * Components
import SelfieModal from "./SelfieModal/SelfieModal";
import queryString from "query-string";
// import ContinueButton from "../../Buttons/UploadButton/UploadButton";
import ContinueButton from "../../Buttons/SlideContinueButton/ContinueButton";
// * Images
import SelfieImg from "../../../assets/images/selfie.png";
// * Icons
// import RestartAltIcon from "@mui/icons-material/RestartAlt";
// * APIs and API handler
import { useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import {
  verifyCustomerDocument,
  uploadCustomerDocument,
} from "../../../api/services/documents";
// * Cookies
import { useCookies } from "react-cookie";
// * Notificaiton
import { toast } from "react-toastify";
// * Helpers
import {
  convertBase64,
  validateImageQuality,
  getCookiePathFromURL,
  getLocationPermission,
  getFileSize,
} from "../../../helpers";
// * Constants
import { LAST_ACTIVE_JOURNEY_SLIDE, TOTAL_SLIDES } from "../../../constants";
// * Contexts
import CustomerContext from "../../../context/CustomerContext";

const TakeSelfie = ({ switchToNextSlide, updateLastVisitSlide }) => {
  let domainName = window.location.host.split(".")[0];
  // let domainName = "maxemocapital";
  const { apiKey, apiSecret } = useParams();
  const { customerDetails } = useContext(CustomerContext);
  const [_, setCookie] = useCookies(["user"]);
  const CookiePath = getCookiePathFromURL(window.location.href);

  const [selfie, setSelfie] = useState(null);
  const [isSelfieVerified, setSelfieVerified] = useState(false);
  // const [hasDocument, setHasDocument] = useState(false);
  const [toggleSelfieModal, setSelfieModal] = useState(false);

  const retakeSelfie = useCallback(() => {
    setSelfie(false);
    setSelfieVerified(false);
    // setSelfieModal(true);
  }, []);

  // * API to verify the document
  const verifyCustomerDocumentAPI = useMutation({
    mutationKey: ["verifyCustomerDocument"],
    mutationFn: (Payload) => verifyCustomerDocument(Payload),
    onSuccess: (data) => handleVerifyCustomerDocumentSuccess(data),
    onError: (error) => handleVerifyCustomerDocumentError(error),
    retry: false,
  });

  const handleVerifyCustomerDocumentSuccess = (data) => {
    setSelfieVerified(true);

    // if (customerDetails.employment_type === "Self-Employed") {
    //   switchToNextSlide(21);
    //   setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 21, { path: CookiePath });
    // } else {
    //   if(domainName === "maxemocapital"){
    //     switchToNextSlide(23); // * Redirect Address Verification slide.
    //   }else{
    //     switchToNextSlide();
    //   }
    //   setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 20, { path: CookiePath });
    // }
  };

  const handleVerifyCustomerDocumentError = (error) => {
    toast.error(
      "Document verification failed! please try again or upload a correct document."
    );
  };

  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  const uploadCustomerDocumentAPI = useMutation({
    mutationKey: ["uploadCustomerDocument"],
    mutationFn: (Payload) => uploadCustomerDocument(Payload),
    onSuccess: (data) => handleUploadCustomerDocumentSuccess(data),
    onError: (error) => handleUploadCustomerDocumentError(error),
    retry: false,
  });
  const handleUploadCustomerDocumentSuccess = (data) => {
    const headers = {
      apiKey,
      apiSecret,
    };

    const slidePayload = {
      headers,
      leadId: customerDetails?.leadId,
      slideName: "Salary Slip",
      slideIndex: 20,
      slideIcon: "Salary Slip",
      totalSlides: TOTAL_SLIDES,
      journeyVersion: JourneyVersion,
    };
    // * Update Journey last visit slide
    updateLastVisitSlide(slidePayload);

    // * Verify Document
    verifyCustomerDocumentAPI.mutate({
      ContactID: data.data.contact_id,
      DocumentID: data.data.id,
      headers,
    });

    if (domainName === "maxemocapital") {
      switchToNextSlide(24); // * Redirect Address Verification slide.
    } else {
      console.log(
        "customerDetails.employment_type : ",
        customerDetails.employment_type
      );
      if (customerDetails.employment_type === "Self-Employed") {
        switchToNextSlide(22);
      } else {
        switchToNextSlide();
      }
    }
  };
  const handleUploadCustomerDocumentError = (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("authToken");
      handleSelfieUpload();
    } else {
      toast.error("Something went wrong! please try again.");
    }
  };

  const handleSelfieImage = (file) => {
    const fileSize = getFileSize(file);
    if (fileSize > 700) {
      toast.error("File size cannot be more than 700kb.");
    } else {
      setSelfie(file);
    }
  };

  const handleSelfieUpload = async () => {
    if (selfie) {
      const imageQuality = await validateImageQuality(selfie);

      if (imageQuality > 50 && !uploadCustomerDocumentAPI.isPending) {
        const headers = {
          apiKey,
          apiSecret,
        };
        let Payload = {
          docUpload:
            typeof selfie === "string" ? selfie : await convertBase64(selfie),
          contact_id: customerDetails.contactID,
          pipeline_id: customerDetails.pipelineID,
          lead_id: customerDetails.leadId,
          fileName: "Photograph",
          docType: "photograph",
          docFormat: "image",
          docNumber: "",
          source_name: "Journey",
          headers: headers,
        };

        const location = await getLocationPermission();
        if (location) {
          Payload = { ...Payload, ...location };
        }

        uploadCustomerDocumentAPI.mutate(Payload);
      } else {
        toast.error(
          "Your selife seems to dark, please take a selfie in proper light."
        );
      }
    }
  };

  const handleSelfieUploadChange = (e) => {
    const file = e.target.files[0];
    const fileSize = getFileSize(file);
    if (fileSize > 700) {
      toast.error("File size cannot be more than 700kb.");
    } else {
      setSelfie(file);
    }
  };

  const nextSlide = () => {
    if (domainName === "maxemocapital") {
      switchToNextSlide(24);
    } else if (customerDetails.employment_type === "Self-Employed") {
      switchToNextSlide(22);
    } else {
      switchToNextSlide();
    }
  };

  return (
    <div className={style.selfieSlide}>
      <div className={style.typographyContainer}>
        <h1>Take Selfie</h1>
        <p>Verify your identity by taking a selfie.</p>
      </div>
      <div className={style.selfieModalContainer}>
        {toggleSelfieModal ? (
          <SelfieModal
            setSelfieModal={setSelfieModal}
            setSelfie={handleSelfieImage}
          />
        ) : null}

        {selfie ? (
          <div className={style.selfiPreviewBox}>
            <img
              src={
                typeof selfie === "string"
                  ? selfie
                  : URL.createObjectURL(selfie)
              }
              alt="Selfie Preview"
            />
            <button className={style.retakeSelfieBtn} onClick={retakeSelfie}>
              Remove
            </button>
          </div>
        ) : (
          <div className={style.selfiBtnBox}>
            <div className={style.selfiImg}>
              <img src={SelfieImg} alt="selfie" />
            </div>
            <div className={style.selfieBtnBox}>
              <button
                className={style.takeSelfieBtn}
                onClick={() => setSelfieModal(true)}
              >
                Take Selfie
              </button>
              <input
                type="file"
                id="selfieUploadInput"
                onChange={handleSelfieUploadChange}
                accept="image/*"
                hidden
              />
              {/* <label
                className={style.uploadSelfieBtn}
                htmlFor="selfieUploadInput"
              >
                Upload Photo
              </label> */}
            </div>
          </div>
        )}
      </div>
      <div className={style.selfiContinueBtn}>
        <ContinueButton
          label={selfie ? "Continue" : "Upload"}
          // label="Continue"
          disabled={
            verifyCustomerDocumentAPI.isPending ||
            uploadCustomerDocumentAPI.isPending ||
            !selfie
              ? true
              : false
          }
          loading={
            verifyCustomerDocumentAPI.isPending ||
            uploadCustomerDocumentAPI.isPending
              ? true
              : false
          }
          handler={() =>
            isSelfieVerified ? nextSlide() : handleSelfieUpload()
          }
        />
      </div>
    </div>
  );
};

export default TakeSelfie;
