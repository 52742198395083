import style from "./DocumentContent.module.css";

export default function DocumentContent({
  content,
  reference,
  handleScroll,
  details,
}) {
  return (
    <div className={style.content} ref={reference} onScroll={handleScroll}>
      {details && <div className={style.documentDetails}>
        <p>
          <span>Name:</span> {details.name}
        </p>
        <p>
          <span>Loan Amount:</span> Rs. {details.loanAmount}
        </p>
        <p>
          <span>Sanction Letter ID: </span>
          {details.documentId}
        </p>
      </div>}
      <div
        id="template"
        className={style.template}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
}
