import style from "../styles/Journey/Journey.module.css";
// import { useContext } from "react";
import JourneyRightSection from "./JourneyForm";
import JourneyLeftSection from "../components/JourneyLeftSection/JourneyLeftSection";
import { useState } from "react";
// import LoanAgreementModal from "../components/Modal/LoanAgreementModal/LoanAgreementModal";
// * Context/Providers
// import LoanAgreementModalContext from "../context/LoanAgreementModalContextProvider";

export default function JourneyPage() {
  // const { toggleModal } = useContext(LoanAgreementModalContext);
  const [userType,setUserType] = useState();
  const getUserType = (userType) => {
    console.log('user type from left journey section',userType);
    setUserType(userType);
  }

  return (
    <>
      <main className={style.journeyMainSection}>
        <JourneyLeftSection getUserType={getUserType}/>
        <JourneyRightSection userType={userType}/>
        {/* {toggleModal.open && <LoanAgreementModal />} */}
      </main>
    </>
  );
}
