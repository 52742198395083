import axiosWithDomain from "../../axios";

import {
  CHECK_LEAD_ELIGIBILITY,
  CHECK_BRE_CONFIGURE_STATUS,
} from "../../../constants/urls";

// * API to analyze customer's Bank Statement (Arya)
export const checkLeadEligibility = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${CHECK_LEAD_ELIGIBILITY}`,
    data,
    { headers }
  );
  return res?.data;
};

// * API TO CHECK WEATHER BRE IS CONFIGURED ON CRM END OR NOT.
export const checkBREConfigureStatus = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${CHECK_BRE_CONFIGURE_STATUS}`,
    data,
    { headers }
  );
  return res?.data;
};
