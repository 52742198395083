import * as actions from "./types";

export const journeyFields = (param) => {
  return {
    type: actions.GET_JOURNEY_FIELDS,
    payload: param,
  };
};

export const journeyFieldsSuccess = () => ({
  type: actions.GET_JOURNEY_FIELDS_SUCCESS,
});
