import * as actions from "./types";

export const realTimeLeadPush = (param) => {
    return {
        type: actions.GET_REAL_TIME_LEAD_PUSH,
        payload: param,
    };
};

export const realTimeLeadPushSuccess = () => ({
    type: actions.GET_REAL_TIME_LEAD_PUSH_SUCCESS,
});

export const realTimeLeadPushError = () => {
    return {
        type: actions.GET_REAL_TIME_LEAD_PUSH_ERROR,
    };
};

export const checkForAutoPullBureau = () => {
    return {
        type: actions.GET_BUREAU_AUTO_PULL,
    }
}
