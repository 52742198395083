import axiosWithDomain from "../../axios";

import {
  CREATE_PLAN,
  CREAET_SUBSCRIPTION_WITH_PLAN_ID,
  CREATE_AUTHORISATION_REQUEST,
  CREATE_MANDATE_FORM,
  GET_MANDATE_DETAILS
} from "../../../constants/urls";

// * Cashfree E-Nach APIs
export const createPlan = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${CREATE_PLAN}`,
    data,
    { headers }
  );
  return res?.data;
};

export const createSubscriptionWithPlanId = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${CREAET_SUBSCRIPTION_WITH_PLAN_ID}`,
    data,
    { headers }
  );
  return res?.data;
};

export const createAuthorisationRequest = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${CREATE_AUTHORISATION_REQUEST}`,
    data,
    { headers }
  );
  return res?.data;
};

// * Digio E-Nach APIs
export const createMandateFrom = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${CREATE_MANDATE_FORM}`,
    data,
    { headers }
  );
  return res?.data;
}
export const getMandateDetails = async (Payload) => {
  const { mandateId, headers } = Payload;

  const res = await axiosWithDomain.get(
    `${process.env.REACT_APP_API_BASE_URL}${GET_MANDATE_DETAILS}/${mandateId}`,
    data,
    { headers }
  );
  return res?.data;
}