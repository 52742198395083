import { LEAD_PUSH_TO_PARTICIPANT } from "../../../urls";
import axiosWithDomain from "../../axios";


// * API to lead push for participant
export const leadPushForParticipant = async (Payload) => {
    const { data, headers } = Payload;
  
    const res = await axiosWithDomain.post(
      `${process.env.REACT_APP_API_BASE_URL}${LEAD_PUSH_TO_PARTICIPANT}`,
      data,
      { headers }
    );
    return res?.data;
  };