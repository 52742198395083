import axios from "axios";
import axiosWithDomain from "../../axios";

// * Helpers
import { multiPartData } from "../../../helpers";
import { getToken } from "../Journey";

// * API URLs
import {
  SAVE_CUSTOMER_DOCUMENT,
  VALIDATE_CUSTOMER_PHOTO_LIVENESS,
  VERIFY_CUSTOMER_DOCUMENT,
  UPLOAD_BANK_STATEMENT,
  UPDATE_CUSTOMER_DOCUMENT,
  UPLOAD_FORM_16,
  SEND_AADHAAR_OTP,
  VERIFY_AADHAAR_OTP,
  SAVE_CUSTOMER_DOCUMENT_KYC_RESPONSE,
  GET_CUSTOMER_DOCUMENTS,
  DELETE_CUSTOMER_DOCUMENT,
  CHECK_PHONE_CONNECTED_WITH_PAN_OR_NOT,
  FETCH_COMPANY_BASIC_DETAILS,
  FETCH_UDYAM_REGISTRATION_DETAILS,
  GET_COMPANY_BASIC_DETAILS,
  ADD_AUTHORIZED_SIGNATORY_PAN,
  FETCHED_PAN_DETAILS,
  FETCH_PROBE42_COMPANY_BASIC_DETAILS,
  MOBILE_TO_MSME,
  ALL_IN_ONE_PAN,
  VALIDATE_PAN,
  GET_UDYAM_DETAILS,
  GENERATE_OTP_FOR_CONSENT,
  SUBMIT_CONSENT_OTP,
  BULK_SAVE_CUSTOMER_DOCUMENT,
  ANALYZE_BANK_STATEMENT,
  ANALYZE_SALARY_SLIP,
  GET_LAST_SLIDE_DETAILS,
} from "../../../constants/urls";

// * API to analyze customer's Bank Statement (Arya)
const analyzeBankStatement = async (Payload) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${UPLOAD_BANK_STATEMENT}`,
    Payload
  );
  return res?.data;
};

// * API to analyze customer's FORM16 (Arya)
const analyzeForm16 = async (Paylaod) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${UPLOAD_FORM_16}`,
    Paylaod
  );
  return res?.data;
};

// * Tartan's Payslip Standalone API to validate customer's Income/Salary (Tartan)
const analyzeSalarySlip = async (Payload) => {
  // * Generate a random unique Application ID
  Payload.applicationId = Math.floor(Math.random() * Date.now()).toString();

  const formData = multiPartData(Payload);
  // * Get Auth token for Tartan's API from Tartan's Auth API
  const authToken = await getTartanAuthToken();

  if (authToken) {
    const URL =
      "https://api-ext-prod.tartanhq.com/aphrodite/external/v1/payslip";
    const HEADERS = {
      "Content-Type": "multipart/form-data",
      "x-api-key": "0ftuMibOsr9bhzh0mYLw05YDL5OAklZVaHh2OtcQ",
      Authorization: `Bearer ${authToken}`,
    };

    const res = await axios.post(URL, formData, { headers: HEADERS });
    return res?.data;
  } else {
    return false;
  }
};

// * API to get a Auth Token from Tartan's API for Tartan's Payslip API. (Tartan)
const getTartanAuthToken = async () => {
  const URL =
    "https://api-ext-prod.tartanhq.com/aphrodite/api/auth/v1/authenticate";
  const HEADERS = {
    "content-type": "application/json",
    "x-api-key": "0ftuMibOsr9bhzh0mYLw05YDL5OAklZVaHh2OtcQ",
  };
  const PAYLOAD = {
    username: "roopya",
    password: "Password@1234",
  };
  const res = await axios.post(URL, PAYLOAD, { headers: HEADERS });
  if (res.status === 200) {
    return res.data.token;
  } else {
    return false;
  }
};

// * API to verify customer's document (Single Document) (Decentro | OCR)
const getCustomerDocuments = async (Params) => {
  const { contactId } = Params;
  // * Get Auth token before executing API
  let authToken = null;
  authToken = localStorage.getItem("authToken");

  if (!authToken) {
    // * Auth token not availabe in local storage then fetch it.
    authToken = await getToken();
  }

  const headers = {
    ...Params.headers,
    Authorization: `Bearer ${authToken}`,
  };
  const res = await axiosWithDomain.get(
    `${process.env.REACT_APP_API_BASE_URL}${GET_CUSTOMER_DOCUMENTS}/${contactId}`,
    { headers }
  );
  return res?.data;
};

// * API to verify customer's document (Single Document) (Decentro | OCR)
const deleteCustomerDocument = async (Payload) => {
  const { data } = Payload;
  // * Get Auth token before executing API
  let authToken = null;
  authToken = localStorage.getItem("authToken");

  if (!authToken) {
    // * Auth token not availabe in local storage then fetch it.
    authToken = await getToken();
  }

  const headers = {
    ...Payload.headers,
    Authorization: `Bearer ${authToken}`,
  };
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${DELETE_CUSTOMER_DOCUMENT}`,
    data,
    { headers }
  );
  return res?.data;
};

// * API to verify customer's document (Single Document) (Decentro | OCR)
const verifyCustomerDocument = async (Params) => {
  const { ContactID, DocumentID } = Params;
  // * Get Auth token before executing API
  let authToken = null;
  authToken = localStorage.getItem("authToken");

  if (!authToken) {
    // * Auth token not availabe in local storage then fetch it.
    authToken = await getToken();
  }

  const headers = {
    ...Params.headers,
    Authorization: `Bearer ${authToken}`,
  };
  const res = await axiosWithDomain.get(
    `${process.env.REACT_APP_API_BASE_URL}${VERIFY_CUSTOMER_DOCUMENT}/${ContactID}/data/${DocumentID}`,
    { headers }
  );
  return res?.data;
};

// * API to upload the customer document in system to show in the list documents in CRM (To Save Customer Document).
const uploadCustomerDocument = async (Payload) => {
  // * Get Auth token before executing API
  let authToken = null;
  authToken = localStorage.getItem("authToken");

  if (!authToken) {
    // * Auth token not availabe in local storage then fetch it.
    authToken = await getToken();
  }
  const headers = {
    ...Payload.headers,
    Authorization: `Bearer ${authToken}`,
  };
  delete Payload.headers;
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${SAVE_CUSTOMER_DOCUMENT}`,
    Payload,
    { headers }
  );
  return res?.data;
};

// * API to upload the customer document in system to show in the list documents in CRM (To Save Customer Document).
const updateCustomerDocument = async (Payload) => {
  // * Get Auth token before executing API
  let authToken = null;
  authToken = localStorage.getItem("authToken");

  if (!authToken) {
    // * Auth token not availabe in local storage then fetch it.
    authToken = await getToken();
  }
  const headers = {
    ...Payload.headers,
    Authorization: `Bearer ${authToken}`,
  };
  const DocumentId = Payload.DocumentId;
  delete Payload.headers;
  delete Payload.DocumentId;
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${UPDATE_CUSTOMER_DOCUMENT}/${DocumentId}`,
    Payload,
    { headers }
  );
  return res?.data;
};

// * API to add Kyc Response for the customer document
const addCustomerDocumentKycResponse = async (Payload) => {
  // * Get Auth token before executing API
  let authToken = null;
  authToken = localStorage.getItem("authToken");

  if (!authToken) {
    // * Auth token not availabe in local storage then fetch it.
    authToken = await getToken();
  }
  const headers = {
    ...Payload.headers,
    Authorization: `Bearer ${authToken}`,
  };
  delete Payload.headers;
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${SAVE_CUSTOMER_DOCUMENT_KYC_RESPONSE}`,
    Payload,
    { headers }
  );

  return res?.data;
};

// * API to validate Customer's Photo, either it is Fake or Real
const validateCustomerPhotoLiveness = async (Payload) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${VALIDATE_CUSTOMER_PHOTO_LIVENESS}`,
    Payload
  );
  return res?.data;
};

// * API to Generate the OTP and sent to the Aadhaar linked mobile number.
const sendAadhaarOtp = async (Payload) => {
  const headers = {
    ...Payload.headers,
  };
  delete Payload.headers;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${SEND_AADHAAR_OTP}`,
    Payload,
    { headers }
  );
  return res?.data;
};

// * API to verify the Aadhaar OTP.
const verifyAadhaarOtp = async (Payload) => {
  const headers = {
    ...Payload.headers,
  };
  delete Payload.headers;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${VERIFY_AADHAAR_OTP}`,
    Payload,
    { headers }
  );
  return res?.data;
};

// * API to fetch company basic details
const fetchCompanyDetails = async (Payload) => {
  const headers = {
    ...Payload.headers,
  };
  delete Payload.headers;
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${FETCH_COMPANY_BASIC_DETAILS}`,
    Payload,
    { headers }
  );
  return res?.data;
};

const fetchProbe42CompanyDetails = async (Payload) => {
  let authToken = null;
  authToken = localStorage.getItem("authToken");

  if (!authToken) {
    // * Auth token not availabe in local storage then fetch it.
    authToken = await getToken();
  }
  const headers = {
    ...Payload.headers,
    Authorization: `Bearer ${authToken}`,
  };
  console.log("headers=>", headers);
  delete Payload.headers;
  const res = await axiosWithDomain.get(
    `${process.env.REACT_APP_API_BASE_URL}${FETCH_PROBE42_COMPANY_BASIC_DETAILS}/${Payload?.pan}/data/${Payload?.contact_id}`,
    { headers }
  );
  return res?.data;
};

// API to verfiy Udyam Registration number
const verifyUdyamRegNo = async (Payload) => {
  const headers = {
    ...Payload.headers,
  };
  delete Payload.headers;
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${FETCH_UDYAM_REGISTRATION_DETAILS}`,
    Payload,
    { headers }
  );
  return res?.data;
};

// API to generate GET OTP
const generateOTPForGST = async (Payload) => {
  const headers = {
    ...Payload.headers,
  };
  delete Payload.headers;
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${GENERATE_OTP_FOR_CONSENT}`,
    Payload,
    { headers }
  );
  return res?.data;
};

// API to submit GET OTP
const submitConsentOTP = async (Payload) => {
  const headers = {
    ...Payload.headers,
  };
  delete Payload.headers;
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${SUBMIT_CONSENT_OTP}`,
    Payload,
    { headers }
  );
  return res?.data;
};

// API to get company details
const getCompanyDetails = async (Payload) => {
  const headers = {
    ...Payload.headers,
  };
  delete Payload.headers;
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${GET_COMPANY_BASIC_DETAILS}`,
    Payload,
    { headers }
  );
  return res?.data;
};

// * API to fetch PAN data with Aadhaar Link status so that we can check customer mobile number is the same number used for registration.
const checkPhoneOnPan = async (Payload) => {
  const headers = {
    ...Payload.headers,
  };
  delete Payload.headers;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${CHECK_PHONE_CONNECTED_WITH_PAN_OR_NOT}`,
    Payload,
    { headers }
  );
  return res?.data;
};

// API to get last slide details
const getLastSlideDetails = async (payload) => {
  let authToken = null;
  authToken = localStorage.getItem("authToken");

  if (!authToken) {
    // * Auth token not availabe in local storage then fetch it.
    authToken = await getToken();
  }
  const headers = {
    ...payload.headers,
    Authorization: `Bearer ${authToken}`,
  };
  delete payload.headers;
  let url = ``;
  if (payload?.journeyVersion) {
    url = `${process.env.REACT_APP_API_BASE_URL}${GET_LAST_SLIDE_DETAILS}/${payload.pipelineId}/${payload.contactId}/${payload?.journeyVersion}`;
  } else {
    url = `${process.env.REACT_APP_API_BASE_URL}${GET_LAST_SLIDE_DETAILS}/${payload.pipelineId}/${payload.contactId}/0`;
  }
  const res = await axiosWithDomain.get(`${url}`, { headers });
  return res?.data;
};

// API to upload bulk customer documents
const bulkCustomerUploadDocument = async (Payload) => {
  let authToken = null;
  authToken = localStorage.getItem("authToken");
  if (!authToken) {
    authToken = await getToken();
  }
  const headers = {
    ...Payload.headers,
    Authorization: `Bearer ${authToken}`,
  };
  delete Payload.headers;
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${BULK_SAVE_CUSTOMER_DOCUMENT}`,
    Payload,
    { headers }
  );
  return res?.data;
};

// * API to analyze the bank statement (Authbridge)
const analyzeBankStatementAuthBridge = async (payload) => {
  console.log("inside auth bridge");
  const headers = payload.headers;
  const data = payload.data;
  console.log("payload=>", payload);
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${ANALYZE_BANK_STATEMENT}`,
    data,
    { headers }
  );
  return res?.data;
};
// * API to analyze the bank statement (Authbridge)
const analyzeSalarySlipAuthBridge = async (payload) => {
  const headers = payload.headers;
  const data = payload.data;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${ANALYZE_SALARY_SLIP}`,
    data,
    { headers }
  );
  return res?.data;
};

// API to add authorized signatory PAN
const addAuthorizedSignatoryPAN = async (Payload) => {
  const headers = {
    ...Payload.headers,
  };
  delete Payload.headers;
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${ADD_AUTHORIZED_SIGNATORY_PAN}`,
    Payload,
    { headers }
  );
  return res?.data;
};

// API to fetch PAN Details
const fetchPANDetails = async (Payload) => {
  const headers = {
    ...Payload.headers,
  };
  delete Payload.headers;
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${FETCHED_PAN_DETAILS}`,
    Payload,
    { headers }
  );
  return res?.data;
};

// API to fetch MSME details
const fetchMSMEDetails = async (Payload) => {
  const headers = {
    ...Payload.headers,
  };
  delete Payload.headers;
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${MOBILE_TO_MSME}`,
    Payload,
    { headers }
  );
  return res?.data;
};

// API to fetch ALL PAN details
const fetchAllInOnePANDetails = async (Payload) => {
  const headers = {
    ...Payload.headers,
  };
  delete Payload.headers;
  console.log("All pan dt=>", headers);
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${ALL_IN_ONE_PAN}`,
    Payload,
    { headers }
  );
  return res?.data;
};

// API to validate PAN
const validate_PAN = async (payload) => {
  const headers = {
    ...payload.headers,
  };
  delete payload.headers;
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${VALIDATE_PAN}`,
    payload,
    { headers }
  );
  return res?.data;
};

// API to get udyam aadhaar registration number
const getUdyamDetails = async (payload) => {
  const headers = {
    ...payload.headers,
  };
  delete payload.headers;
  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${GET_UDYAM_DETAILS}`,
    payload,
    { headers }
  );
  return res?.data;
};

export {
  analyzeSalarySlipAuthBridge,
  analyzeBankStatementAuthBridge,
  analyzeBankStatement,
  analyzeForm16,
  analyzeSalarySlip,
  getCustomerDocuments,
  uploadCustomerDocument,
  updateCustomerDocument,
  deleteCustomerDocument,
  validateCustomerPhotoLiveness,
  verifyCustomerDocument,
  sendAadhaarOtp,
  verifyAadhaarOtp,
  addCustomerDocumentKycResponse,
  checkPhoneOnPan,
  getLastSlideDetails,
  fetchCompanyDetails,
  fetchProbe42CompanyDetails,
  verifyUdyamRegNo,
  getCompanyDetails,
  addAuthorizedSignatoryPAN,
  fetchPANDetails,
  fetchMSMEDetails,
  fetchAllInOnePANDetails,
  validate_PAN,
  getUdyamDetails,
  generateOTPForGST,
  submitConsentOTP,
  bulkCustomerUploadDocument,
};
