export const BASE_URL = "/customer";
export const BASE_URL_NEW = "/landing";
export const BASE_URL2 = "/api/lead/roopya/create";
export const ADD_JOURNEY = "/api/v2/customer/lead/add/";
export const GET_OTP_URL = 
// BASE_URL + 
"/api/v2/customer/lead";
export const VERIFY_OTP_URL = BASE_URL + "/api/v2/customer/lead/otp/verifiy";
export const RESEND_OTP_URL = BASE_URL + "/api/v2/customer/lead/otp/resend";
export const JOURNEY_FILEDS = BASE_URL + "/api/v2/customer/fields";
export const BUREAU_AUTO_PULL = "/api/v2/bank/checkAutoBureauPull";
export const BASE_URL_FILES = "http://localhost:5000/";
export const REAL_TIME_LEAD_PUSH_URL =
  BASE_URL + "/api/v2/bank/realTimeLead/push";
  export const LEAD_PUSH_TO_PARTICIPANT = "/api/v2/customer/participant/leadPush";
export const GET_PROFILE_DETAILS = "/api/v2/users/getLenderDetails";
