import axiosWithDomain from "../../axios";
import {
  GET_AGREEMENT_TEMPLATE,
  GET_AGREEMENT_DOCUMENT,
  ADD_AGREEMENT_DOCUMENT,
  UPDATE_AGREEMENT_DOCUMENT,
  DELETE_AGREEMENT_DOCUMENT,
  SEND_AGREEMENT_MAIL
} from "../../../constants/urls";

export const getAgreementTemplate = async (Payload) => {
  const { pipelineId, headers } = Payload;

  const res = await axiosWithDomain.get(
    `${process.env.REACT_APP_API_BASE_URL}${GET_AGREEMENT_TEMPLATE}/${pipelineId}`,
    { headers }
  );
  return res?.data;
};

export const getAgreementDocument = async (Payload) => {
  const { leadId, headers } = Payload;

  const res = await axiosWithDomain.get(
    `${process.env.REACT_APP_API_BASE_URL}${GET_AGREEMENT_DOCUMENT}/${leadId}`,
    { headers }
  );
  return res?.data;
};

export const addAgreementDocument = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${ADD_AGREEMENT_DOCUMENT}`,
    data,
    { headers }
  );
  return res?.data;
};

export const updateAgreementDocument = async (Payload) => {
  const { data, leadId, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${UPDATE_AGREEMENT_DOCUMENT}/${leadId}`,
    data,
    { headers }
  );
  return res?.data;
};

export const deleteAgreementDocument = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${DELETE_AGREEMENT_DOCUMENT}`,
    data,
    { headers }
  );
  return res?.data;
};

export const sendAgreementMail = async (Payload) => {
  const { data, headers } = Payload;

  const res = await axiosWithDomain.post(
    `${process.env.REACT_APP_API_BASE_URL}${SEND_AGREEMENT_MAIL}`,
    data,
    { headers }
  );
  return res?.data;
};
