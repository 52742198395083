import * as actions from "../actions/types";
import * as urls from "../../urls";
import { all, call, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

function getOtpApi(params) {
  let domain = window.location.host.split(".")[0];
  if (window.location.host.includes("localhost")) {
    domain = "maxemocapital";
  }

  let headerObj = { apiKey: params.apiKey, apiSecret: params.apiSecret };
  let bodyObj = params.getOtpData;
  return axios.request({
    // withCredentials: true,
    method: "post",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.GET_OTP_URL}`,
    // url: `http://localhost:3000${urls.GET_OTP_URL}`,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Methods": "*",
      domain_name: domain,
      ...headerObj,
    },
    data: bodyObj,
  });
}

export function* getOtpActionEffect(getOtpAction) {
  const { payload, resolve, reject } = getOtpAction;

  try {
    const { data } = yield call(getOtpApi, payload);
    if (data) {
      if (resolve) resolve(data);
    } else {
      reject(data);
    }
  } catch (e) {
    if (reject) reject(e);
  }
}

export function* getOtpActionWatcher() {
  yield takeLatest(actions.GET_OTP, getOtpActionEffect);
}

export default function* rootSaga() {
  yield all([fork(getOtpActionWatcher)]);
}
