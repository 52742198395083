import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import style from "./style.module.css";
import ImageInputField3 from "../../../../DataInputFields/ImageInputField2/ImageInputField2";
import ContinueButton from "../../../../Buttons/SlideContinueButton/ContinueButton";
// * API Handler
import { useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
// * APIs
import {
  uploadCustomerDocument,
  verifyCustomerDocument,
} from "../../../../../api/services/documents";
// * Helpers
import {
  convertBase64,
  getCookiePathFromURL,
  getLocationPermission,
  getFileSize,
} from "../../../../../helpers";
// * Cookies
import { useCookies } from "react-cookie";
// * Notificaiton
import { toast } from "react-toastify";
// * Constants
import {
  AADHAAR_FRONT_DOC_ID,
  AADHAAR_BACK_DOC_ID,
  AADHAAR_ADDRESS_FRONT_DOC_ID,
  AADHAAR_ADDRESS_BACK_DOC_ID,
  LAST_ACTIVE_JOURNEY_SLIDE,
  TOTAL_SLIDES,
} from "../../../../../constants";
// * Contexts
import CustomerContext from "../../../../../context/CustomerContext";

const AadharVerificationSlide = ({
  activeSlide,
  switchToNextSlide,
  updateLastVisitSlide,
}) => {
  const { apiKey, apiSecret } = useParams();
  const { customerDetails, setCustomerDetails } = useContext(CustomerContext);
  const CookiePath = getCookiePathFromURL(window.location.href);
  const [_, setCookie] = useCookies(["user"]);

  const [showAadhaarBack, toggleAadhaarBack] = useState(false);
  const [documentStatus, setDocumentStatus] = useState({
    frontAadhar: {
      error: false,
      hasDocument: false,
      isVerified: false,
    },
    backAadhar: {
      error: false,
      hasDocument: false,
      isVerified: false,
    },
  });

  const [aadharFront, setAadharFront] = useState(false);
  const [aadharBack, setAadharBack] = useState(false);

  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  useEffect(() => {
    if (showAadhaarBack) {
      toggleAadhaarBack(false);
      if (documentStatus.backAadhar.error) {
        setDocumentStatus({
          ...documentStatus,
          backAadhar: { error: false, hasDocument: false, isVerified: false },
        });
      }
    }
  }, [aadharFront]);

  // useEffect(() => {
  //   if (
  //     documentStatus.frontAadhar.isVerified &&
  //     !documentStatus.backAadhar.isVerified
  //   ) {
  //     saveDocument(aadharBack, "aadhaarBack");
  //   }
  // }, [documentStatus]);

  // * API to verify the document
  const verifyCustomerDocumentAPI = useMutation({
    mutationKey: ["verifyCustomerDocument"],
    mutationFn: (Payload) => verifyCustomerDocument(Payload),
    onSuccess: (data) => handleVerifyCustomerDocumentSuccess(data),
    onError: (error) => handleVerifyCustomerDocumentError(error),
    retry: false,
  });
  const handleVerifyCustomerDocumentSuccess = (data) => {
    const Provider = data?.data?.provider;
    if (Provider === "Cashfree") {
      if (!data.data.address) {
        toggleAadhaarBack(true);
        setDocumentStatus({
          ...documentStatus,
          frontAadhar: { error: false, hasDocument: true, isVerified: true },
        });
      } else {
        console.log("customerDetails.userType : ", customerDetails.userType);
        if (customerDetails.userType === "Credit Institution") {
          if (activeSlide === 24) {
            setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 25, { path: CookiePath });
            switchToNextSlide(26);
          } else {
            setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 25, { path: CookiePath });
            switchToNextSlide();
          }
        } else {
          switchToNextSlide(29);
        }
      }
    }
  };
  const handleVerifyCustomerDocumentError = (error) => {
    toast.error(error.response.data.message);
  };

  const getDocumentNameByDocumentType = (documentType) => {
    switch (documentType) {
      case "aadhaar":
        return AADHAAR_FRONT_DOC_ID;
      case "aadhaarBack":
        return AADHAAR_BACK_DOC_ID;
      case "customerAddress":
        return AADHAAR_ADDRESS_FRONT_DOC_ID;
      case "customerAddressBack":
        return AADHAAR_ADDRESS_BACK_DOC_ID;
    }
  };
  const uploadCustomerDocumetAPI = useMutation({
    mutationKey: ["uploadCustomerDocument"],
    mutationFn: (Payload) => uploadCustomerDocument(Payload),
    onSuccess: (data) => handleDocumentUploadSuccess(data),
    onError: (error) => handleDocumentUploadError(error),
    retry: false,
  });
  const handleDocumentUploadSuccess = (data) => {
    const documentName = getDocumentNameByDocumentType(data.data.docType);

    setCustomerDetails((prev) => ({ ...prev, [documentName]: data.data.id }));
    setCookie(documentName, data.data.id, { path: CookiePath });

    if (
      data.data.docType === "aadhaar" ||
      data.data.docType === "customerAddress"
    ) {
      setDocumentStatus({
        ...documentStatus,
        frontAadhar: { error: false, hasDocument: true, isVerified: false },
      });
    } else if (
      data.data.docType === "aadhaarBack" ||
      data.data.docType === "customerAddressBack"
    ) {
      setDocumentStatus({
        ...documentStatus,
        backAadhar: { error: false, hasDocument: true, isVerified: false },
      });
    }

    const headers = {
      apiKey,
      apiSecret,
    };
    const slidePayload = {
      headers,
      leadId: customerDetails?.leadId,
      slideName: "Aadhaar Verification",
      slideIndex: 24,
      slideIcon: "AddressVerification",
      totalSlides: TOTAL_SLIDES,
      journeyVersion: JourneyVersion,
    };
    // * Update Journey last visit slide
    updateLastVisitSlide(slidePayload);

    // * Verify document
    const Payload = {
      ContactID: data.data.contact_id,
      DocumentID: data.data.id,
      headers,
    };
    verifyCustomerDocumentAPI.mutate(Payload);
  };
  const handleDocumentUploadError = (error) => {
    // if (error.response.status === 401) {
    //   localStorage.removeItem("authToken");
    //   saveDocument();
    // } else {
    //   toast.error("Something went wrong! please try again.");
    // }
    toast.error("Something went wrong! please try again.");
  };
  const saveDocument = async (document, docType) => {
    if (document && !uploadCustomerDocumetAPI.isPending) {
      let base64 = null;

      if (typeof document === "object") {
        base64 = await convertBase64(document);
      } else {
        base64 = document;
      }

      const headers = {
        apiKey,
        apiSecret,
      };
      let Payload = {
        contact_id: customerDetails.contactID,
        pipeline_id: customerDetails.pipelineID,
        lead_id: customerDetails.leadId,
        docUpload: base64,
        fileName: document.name,
        docType: docType,
        docFormat: "image",
        docNumber: "",
        source_name: "Journey",
        headers,
      };
      const location = await getLocationPermission();
      if (location) {
        Payload = { ...Payload, ...location };
      }
      uploadCustomerDocumetAPI.mutate(Payload);
    }
  };

  const validateDocs = async () => {
    const { frontAadhar, backAadhar } = documentStatus;

    if (frontAadhar.hasDocument && backAadhar.hasDocument) {
      if (customerDetails.userType === "Credit Institution") {
        if (activeSlide === 25) {
          switchToNextSlide(26);
        } else {
          switchToNextSlide();
        }
      } else {
        switchToNextSlide(28);
      }
    } else if (!aadharFront) {
      let docStatus = documentStatus;
      docStatus = {
        ...docStatus,
        frontAadhar: {
          ...docStatus.frontAadhar,
          error: true,
        },
      };
      setDocumentStatus(docStatus);
    } else if (!aadharBack && showAadhaarBack) {
      let docStatus = documentStatus;
      docStatus = {
        ...docStatus,
        backAadhar: {
          ...docStatus.backAadhar,
          error: true,
        },
      };
      setDocumentStatus(docStatus);
    } else {
      if (documentStatus.frontAadhar.isVerified) {
        saveDocument(aadharBack, "aadhaarBack");
        if (activeSlide === 25) {
          saveDocument(aadharBack, "customerAddressBack");
        }
      } else {
        saveDocument(aadharFront, "aadhaar");
        if (activeSlide === 25) {
          saveDocument(aadharFront, "customerAddress");
        }
      }
    }
  };

  const handleAadharFront = (file) => {
    const fileSize = getFileSize(file);
    if (fileSize > 700) {
      toast.error("File size cannot be more than 700kb.");
    } else {
      setAadharFront(file);
    }
  };
  const handleAadharBack = (file) => {
    const fileSize = getFileSize(file);
    if (fileSize > 700) {
      toast.error("File size cannot be more than 700kb.");
    } else {
      setAadharBack(file);
    }
  };
  const handleAadharFrontDelete = (file) => {
    setAadharFront(false);

    if (!documentStatus.frontAadhar.error) {
      setDocumentStatus({
        ...documentStatus,
        frontAadhar: {
          error: true,
          hasDocument: false,
          isVerified: false,
        },
      });
    }
  };
  const handleAadharBackDelete = (file) => {
    setAadharBack(false);

    if (!documentStatus.backAadhar.error) {
      setDocumentStatus({
        ...documentStatus,
        backAadhar: {
          error: true,
          hasDocument: false,
          isVerified: false,
        },
      });
    }
  };

  return (
    <>
      <div className="dataFieldsContainer imageInputContainer">
        <div className={style.documentInputBox}>
          <div className={style.documentInput}>
            <h5>Front Side</h5>
            <ImageInputField3
              label="Aadhaar Front"
              accept="image/jpeg, image/jpg, image/png"
              allowedFileTypes="jpeg, jpg, png"
              id="aadharFront"
              file={aadharFront}
              error={documentStatus.frontAadhar.error}
              handler={handleAadharFront}
              handleDelete={handleAadharFrontDelete}
              isLoading={
                uploadCustomerDocumetAPI.isPending ||
                verifyCustomerDocumentAPI.isPending
              }
            />
          </div>
          {showAadhaarBack ? (
            <div className={style.documentInput}>
              <h5>Back Side</h5>
              <ImageInputField3
                label="Aadhaar Back"
                accept="image/jpeg, image/jpg, image/png"
                allowedFileTypes="jpeg, jpg, png"
                id="aadharBack"
                file={aadharBack}
                error={documentStatus.backAadhar.error}
                handler={handleAadharBack}
                handleDelete={handleAadharBackDelete}
                isLoading={
                  uploadCustomerDocumetAPI.isPending ||
                  verifyCustomerDocumentAPI.isPending
                }
              />
            </div>
          ) : null}
        </div>
      </div>

      <div
        className="imageInputContinueBtn"
        style={{ paddingBottom: "1rem", marginTop: ".7rem" }}
      >
        <ContinueButton
          label="Next"
          loading={
            uploadCustomerDocumetAPI.isPending ||
            verifyCustomerDocumentAPI.isPending
          }
          handler={validateDocs}
          disabled={
            uploadCustomerDocumetAPI.isPending ||
            verifyCustomerDocumentAPI.isPending
          }
        />
      </div>
    </>
  );
};

export default AadharVerificationSlide;
