import style from "./SignedDocument.module.css";

import { useContext } from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";
// * Context
import CustomerContext from "../../../context/CustomerContext";

// * Components
import ContinueButton from "../../Buttons/SlideContinueButton/ContinueButton";

// * Constants
import { TOTAL_SLIDES } from "../../../constants";
import { useLocation } from "react-router-dom";

export default function SignedDocument({
  title,
  documentType,
  document,
  switchToNextSlide,
  updateLastVisitSlide,
  loading,
}) {
  const { apiKey, apiSecret } = useParams();
  const { customerDetails } = useContext(CustomerContext);

  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  const continueToNextSlide = () => {
    let slidePayload = {
      headers: { apiKey, apiSecret },
      leadId: customerDetails?.leadId,
      slideName: "",
      slideIndex: 0,
      slideIcon: "",
      totalSlides: TOTAL_SLIDES,
      journeyVersion:JourneyVersion
    };
    if (documentType === "sanctionLetter") {
      slidePayload.slideName = "Signed Sanction Letter";
      slidePayload.slideIcon = "SignedSanctionLetter";
      // slidePayload.slideIndex = 31;
    } else if (documentType === "agreement") {
      slidePayload.slideName = "Signed Agreement";
      slidePayload.slideIcon = "SignedAgreement";
      // slidePayload.slideIndex = 34;
    }
    updateLastVisitSlide(slidePayload);

    switchToNextSlide();
  };

  return (
    <div className={style.slide}>
      <div className={style.slideHeader}>
        <h1>{title && title}</h1>
      </div>

      <div className={style.slideBody}>
        <div className={style.documentPreview}>
          {document ? (
            <iframe src={document} className={style.previewDoc} />
          ) : null}
        </div>
      </div>

      <div className={style.slideFooter}>
        <ContinueButton
          loading={loading ? loading : false}
          handler={continueToNextSlide}
        />
      </div>
    </div>
  );
}
