import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
// * Icons
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
// * Components
import OtpInputField from "../../DataInputFields/OtpInputField/OtpInputField";
import ContinueButton from "../../Buttons/SlideContinueButton/ContinueButton";
// * Application Error Handling Context
import ErrorContext from "../../../context/ErrorProvider";
// * Utils
import * as Errors from "../../../utils/errors";
// * API Handler
import { useMutation } from "@tanstack/react-query";
// * APIs
import { resendOTP } from "../../../api/services/Journey";

export default function OTPSlide({
  domainName,
  mobileNumber,
  setActiveSlide,
  handleOtp,
  verifyOTP,
  verifyOTPLoading,
  otpRefKey1,
  otpRefKey2,
  otpRefKey3,
  otpRefKey4,
}) {
  // * API Key and API Secret
  const { apiKey, apiSecret } = useParams();

  // * State to handle journey error
  const { error } = useContext(ErrorContext);

  const [timer, setTimer] = useState(30);
  const [isResending, setIsResending] = useState(true);

  useEffect(() => {
    otpRefKey1?.current?.focus();
  }, [otpRefKey1.current]);

  useEffect(() => {
    let countdownInterval;

    if (isResending) {
      if (timer > 0) {
        countdownInterval = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      } else {
        setIsResending(false);
        setTimer(60);
        clearInterval(countdownInterval);
      }
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [timer, isResending]);

  const resendOTPAPI = useMutation({
    mutationKey: ["resendOTP"],
    mutationFn: (Payload) => resendOTP(Payload),
    onSuccess: (data) => handleResendOtpSuccess(data),
    onError: (error) => handleResendOtpError(error),
  });
  const handleResendOtpSuccess = () => {
    setIsResending(true);
  };
  const handleResendOtpError = () => {
    setIsResending(false);
  };
  const handleResendOTP = () => {
    const otpResendData = {
      phone: mobileNumber,
      countryCode: "+91",
      type: "text",
    };
    const headers = {
      apiKey: apiKey,
      apiSecret: apiSecret,
    };
    const Payload = {
      payload: otpResendData,
      headers: headers,
    };

    resendOTPAPI.mutate(Payload);
  };

  return (
    <div className="carouselSlideWrapper otpSlideWrapper">
      {/* OTP Instructions */}
      <div className="typographyContainer">
        <h1>Just a small check!</h1>
        <div className="otpReceiverDetails">
          <p>We have sent the OTP to +91 {mobileNumber}</p>
          {domainName === "maxemocapital" ? (
            ""
          ) : (
            <button
              className="mobileEditBtn"
              onClick={() => setActiveSlide((currentSlide) => currentSlide - 1)}
            >
              <EditRoundedIcon
                className="editBtnIcon"
                style={{ fontSize: "15px" }}
              />
              Edit
            </button>
          )}
        </div>
      </div>

      {/* OTP Input Fields */}
      <div className="otpInputFieldsContainer">
        <div className="otpInputFields">
          <OtpInputField
            reference={otpRefKey1}
            error={error}
            tabIndex={1}
            errorType={Errors.OTP_ERROR_TYPE}
            // onEnterHandler={verifyOTP}
            handler={(otpKey) => handleOtp(otpKey, otpRefKey1)}
          />
          <OtpInputField
            reference={otpRefKey2}
            error={error}
            tabIndex={2}
            errorType={Errors.OTP_ERROR_TYPE}
            // onEnterHandler={verifyOTP}
            handler={(otpKey) => handleOtp(otpKey, otpRefKey2)}
          />
          <OtpInputField
            reference={otpRefKey3}
            error={error}
            tabIndex={3}
            errorType={Errors.OTP_ERROR_TYPE}
            // onEnterHandler={verifyOTP}
            handler={(otpKey) => handleOtp(otpKey, otpRefKey3)}
          />
          <OtpInputField
            reference={otpRefKey4}
            error={error}
            tabIndex={4}
            errorType={Errors.OTP_ERROR_TYPE}
            // onEnterHandler={verifyOTP}
            handler={(otpKey) => handleOtp(otpKey, otpRefKey4)}
          />
        </div>

        {error.error && error.type === Errors.OTP_ERROR_TYPE ? (
          <div className="errorBox">
            <ErrorOutlineRoundedIcon style={{ fontSize: "14px" }} />
            <p>{error.message}</p>
          </div>
        ) : null}
        <div className="resendOtpBtnBox">
          {isResending ? (
            <div className="timerBox">
              <p>Resend OTP in {timer} seconds</p>
            </div>
          ) : (
            <button
              className="resendOtpBtn"
              onClick={handleResendOTP}
              disabled={resendOTPAPI.isPending}
            >
              {resendOTPAPI.isPending ? "Resending..." : "Resend"}
            </button>
          )}

          {error.error && error.type === "resendOtpError" ? (
            <div className="otpResendError">
              <ErrorOutlineRoundedIcon
                style={{ fontSize: "15px", color: "red" }}
              />
              <p>{error.message}</p>
            </div>
          ) : null}
        </div>
      </div>

      <div className="verifyOtpBtnContainer">
        <ContinueButton
          tabIndex={5}
          loading={resendOTPAPI.isPending || verifyOTPLoading}
          handler={verifyOTP}
          // handler={() => setActiveSlide((prev) => prev + 1)}
        />
      </div>
    </div>
  );
}
