import axiosWithDomain from "../../axios";
import {
  // GET_LEAD_CONFIGURATIONS,
  GET_PIPELINE_SETTINGS,
} from "../../../constants/urls";

// export const getLeadConfigurations = async () => {
//   const res = await axios.get(GET_LEAD_CONFIGURATIONS);
//   return res?.data;
// };

export const getPipelineSettings = async (Payload) => {
  const { pipelineId, headers } = Payload;

  const res = await axiosWithDomain.get(
    `${process.env.REACT_APP_API_BASE_URL}${GET_PIPELINE_SETTINGS}/${pipelineId}`,
    { headers }
  );
  return res?.data;
};
