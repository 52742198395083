import style from "./style.module.css";
import RoopyaLogo from "../../assets/images/logo-blue.png";

export default function PowerdByRoopya() {
  return (
    <div className={style.container}>
      <div className={style.logoBox}>
        <p className={style.text}>Powered by</p>
        <img className={style.logo} src={RoopyaLogo} alt="Roopya Logo" />
      </div>
      <p className={style.browserInstruction}>
        <span>Note</span>: This website is best viewed in chrome.
      </p>
    </div>
  );
}
