import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootReducers } from "./reducers/index";
import rootSaga from "./sagas/index";
import { createHashHistory } from "history";
import { routerMiddleware } from "react-router-redux";

let middlewares = [];

const history = createHashHistory();

middlewares.push(routerMiddleware(history));

const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

let middleware = applyMiddleware(...middlewares);

const store = createStore(rootReducers, middleware);

sagaMiddleware.run(rootSaga);

export { store, history };
