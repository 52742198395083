// * Styles
import style from "./style.module.css";
import { useState } from "react";
import { useRef, useCallback } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// * Icons
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import CancelIcon from "@mui/icons-material/Cancel";

// * Components
// import TakePhotoButton from "../../Buttons/SlideContinueButton/ContinueButton";
// * Packages
import Webcam from "react-webcam";

export default function ImageInputField2({
  accept,
  allowedFileTypes,
  isLoading,
  label,
  file,
  id,
  handler,
  handleDelete,
  error,
}) {
  const webcamRef = useRef();
  const [cameraMode, toggleCamera] = useState(false);
  const [documentType, setDocumentType] = useState("image");
  const [photoMode, togglePhotoMode] = useState(true);

  const initialVideConstraints = {
    facingMode: "user",
  };
  const [videoConstraints, setVideoConstraints] = useState(
    initialVideConstraints
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleCameraMode = () => {
    setVideoConstraints((prev) => ({
      ...prev,
      facingMode:
        prev.facingMode === "user" ? { exact: "environment" } : "user",
    }));
  };

  const capturePhoto = useCallback(() => {
    const capturedPhoto = webcamRef.current.getScreenshot();
    setDocumentType("image");
    handler(capturedPhoto);
    toggleCamera(false);
  }, [webcamRef]);

  const handleFileChange = (e) => {
    if (e.target.files.length) {
      if (e.target.files[0].type === "application/pdf") {
        setDocumentType("pdf");
      }
      if (e.target.files[0].type.includes("image")) {
        setDocumentType("image");
      }
      handler(e.target.files[0]);
      togglePhotoMode("upload");
    }
  };

  const handleTakePhotoMode = () => {
    toggleCamera(true);
    togglePhotoMode("camera");
  };

  return (
    <>
      {file ? (
        <div className={style.docPreviewContainer}>
          {documentType === "image" ? (
            <img
              src={photoMode === "camera" ? file : URL.createObjectURL(file)}
              alt="Preview"
              className={style.docPreview}
            />
          ) : null}

          {documentType === "pdf" ? (
            <iframe src={URL.createObjectURL(file)}></iframe>
          ) : null}
          <div className={style.deleteDocBox}>
            <button
              className={style.deleteDocBtn}
              onClick={handleDelete}
              disabled={isLoading ? true : false}
            >
              <ClearIcon className={style.deleteDocIcon} />
            </button>
          </div>
        </div>
      ) : cameraMode ? (
        <div className={style.cameraContainer}>
          <div className={style.webcamScreenBox}>
            <Webcam
              audio={false}
              ref={webcamRef}
              className={style.webCam}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
          </div>

          <div className={style.takePhotoBtn}>
            {/* <TakePhotoButton
              label="Take Photo"
              loading={false}
              handler={capturePhoto}
            /> */}
            <button
              className={`${style.cameraActionBtns} ${style.takePhotoButton}`}
              onClick={capturePhoto}
              title="Click Photo"
            >
              <CameraAltIcon />
            </button>
            {isMobile ? (
              <button
                className={style.cameraActionBtns}
                onClick={toggleCameraMode}
                title="Switch Camera"
              >
                <CameraswitchIcon />
              </button>
            ) : null}
            <button
              className={style.cameraActionBtns}
              onClick={() => toggleCamera(!cameraMode)}
              title="Cancel"
            >
              <CancelIcon />
            </button>
          </div>
        </div>
      ) : (
        <div
          className={`${style.imageUploadBox} ${
            error ? style.activeError : null
          }`}
        >
          <div className={`${style.docUploadOptions}`}>
            <div className={style.uploadOption} onClick={handleTakePhotoMode}>
              <PhotoCameraOutlinedIcon
                className={`${style.docUploadIcon} ${
                  error ? style.activeErrorText : null
                }`}
              />
              <span>Take a photo</span>
            </div>

            <div className={style.optionSeperator}></div>

            <label className={style.uploadOption} htmlFor={`file-input_${id}`}>
              <CloudUploadOutlinedIcon
                className={`${style.docUploadIcon} ${
                  error ? style.activeErrorText : null
                }`}
              />
              <span>Upload a photo</span>
              <span className={style.docTypes}>
                {allowedFileTypes && allowedFileTypes}
              </span>
              <input
                type="file"
                id={`file-input_${id}`}
                accept={accept && accept}
                onChange={handleFileChange}
                hidden
              />
            </label>
          </div>
        </div>
      )}
    </>
  );
}
