import { useRef, useContext } from "react";
import { useParams } from "react-router-dom";

// * Packages
import { useCookies } from "react-cookie";
import queryString from "query-string";
// * Styles
import SlideStyle from "../../../../styles/Slide/style.module.css";
// * Micro Components
import TextInputField from "../../../DataInputFields/TextInputField/TextInputField";
import ContinueButton from "../../../Buttons/SlideContinueButton/ContinueButton";
// ** Error messagres aand error types
import * as Errors from "../../../../utils/errors";
import Loader from "../../../Loader/Loader";
// * Helpers
import { getCookiePathFromURL } from "../../../../helpers";
// * Utilities
import { validatePAN } from "../../../../utils";
// * Contexts
import CustomerContext from "../../../../context/CustomerContext";
import ErrorContext from "../../../../context/ErrorProvider";
// * Constants
import { TOTAL_SLIDES } from "../../../../constants";
// * APIs and API Handler
import { useMutation } from "@tanstack/react-query";
import {
  checkPhoneOnPan,
  fetchAllInOnePANDetails,
  fetchCompanyDetails,
  fetchMSMEDetails,
  fetchProbe42CompanyDetails,
  getUdyamDetails,
  validate_PAN,
} from "../../../../api/services/documents";
import { toast } from "react-toastify";
import { getLocationPermission } from "../../../../helpers";

const PANSlide = ({
  ageRef,
  loading,
  setLoading,
  activeSlide,
  switchToNextSlide,
  setCustomerDetailsCookies,
  updateJourneyData,
  getUpdatedCustomerDetailsAPI,
}) => {
  const CookiePath = getCookiePathFromURL(window.location.href);
  let queryValues = queryString.parse(window.location.search);
  const JourneyVersion = queryValues?.jrnyTyp;
  const { apiKey, apiSecret } = useParams();

  // * Cookies
  const [_, setCookie] = useCookies(["user"]);

  // * Refs and States
  //   const fullnameRef = useRef("");
  const panRef = useRef("");
  //   const dobRef = useRef("");
  //   const [dob, setDob] = useState(new Date());

  const { customerDetails, setCustomerDetails } = useContext(CustomerContext);
  // * State to handle Journey error
  const { error, setError } = useContext(ErrorContext);

  const checkPhoneOnPanAPI = useMutation({
    mutationFn: (payload) => checkPhoneOnPan(payload),
    // onSuccess: (data) => handleSuccess(data),
    // onError: (error) => handleError(error),
    retry: false,
  });

  const onSuccessHandler = (data) => {
    if (data?.data?.status) {
      setError({
        error: false,
        type: "",
        message: "",
      });
    }
    // switchToNextSlide(38);
  };

  const getUdyamDetailsAPI = useMutation({
    mutationFn: (payload) => getUdyamDetails(payload),
    onSuccess: (data) => onSuccessGetUdyamHandler(data),
    retry: false,
  });

  const fetchCompanyDetailsAPI = useMutation({
    mutationFn: (payload) => fetchCompanyDetails(payload),
    onSuccess: (data) => onSuccessHandler(data),
    retry: false,
  });

  const onSuccessFetchMSMEHandler = (response) => {
    const data = {
      contact_id: customerDetails.contactID,
    };
    console.log("CustomerDetails=>", customerDetails);
    const headers = { apiKey, apiSecret };
    data.headers = headers;
    if (response?.data?.isPhoneRegisteredWithUdyam) {
      getUdyamDetailsAPI.mutate(data);
    } else {
      // console.log("contactId=>", customerDetails.contactID);
      // let jsonData = {
      //   contact_id: customerDetails.contactID,
      // };

      // const newPayload = {
      //   headers,
      //   jsonData,
      // };
      // getUpdatedCustomerDetailsAPI.mutate(newPayload);
      // console.log("after calling customer Details API");
      switchToNextSlide(39);
    }
  };

  const fetchMSMEDetailsAPI = useMutation({
    mutationFn: (payload) => fetchMSMEDetails(payload),
    onSuccess: (data) => onSuccessFetchMSMEHandler(data),
    retry: false,
  });

  const onSuccessGetUdyamHandler = (data) => {
    console.log("Response from getUdyam API=>", data);
  };

  const fetchAllInOnePANDetailsAPI = useMutation({
    mutationFn: (payload) => fetchAllInOnePANDetails(payload),
    onSuccess: (data) => onSuccessFetchAllInOne(data),
  });

  const onSuccessValidatePAN = (response) => {
    if (response?.data?.isValidPAN) {
      setError({
        error: false,
        type: "",
        message: "",
      });
    } else {
      setError({
        error: true,
        type: errorType,
        message: Errors.PAN_ERROR,
      });
    }
  };

  const validatePANAPI = useMutation({
    mutationFn: (payload) => validate_PAN(payload),
    onSuccess: (data) => onSuccessValidatePAN(data),
  });

  const onSuccessFetchAllInOne = (response) => {
    let { category, is_sole_proprietor, full_name, dob, email, address } =
      response?.data?.data;
    const headers = { apiKey, apiSecret };
    console.log("contactId=>", customerDetails.contactID);
    let jsonData = {
      contact_id: customerDetails.contactID,
    };

    const newPayload = {
      headers,
      jsonData,
    };
    getUpdatedCustomerDetailsAPI.mutate(newPayload);
    console.log("after calling customer Details API");
    let gstins = [];
    if (is_sole_proprietor?.info && is_sole_proprietor?.info?.length > 0) {
      is_sole_proprietor?.info &&
        is_sole_proprietor?.info?.map((data) => {
          if (data?.status === "Active") {
            gstins.push(data?.gst);
          }
        });
    }
    const PAN = panRef.current.value;
    const data = {
      pan: PAN.toUpperCase(),
      contact_id: customerDetails.contactID,
    };
    data.headers = headers;
    setCustomerDetails({
      ...customerDetails,
      gstins: gstins,
      compane_name: full_name.trim(),
      communication_address: address,
      email: email,
      dob: dob,
      company_type:
        category === "person"
          ? is_sole_proprietor?.found === "Yes"
            ? "Proprietorship"
            : "Individual"
          : category === "company"
          ? "Private Limited"
          : "Partnership",
      employment_type:
        category === "person" && is_sole_proprietor?.found === "Yes"
          ? "Self-Employed"
          : "",
    });
    if (category === "person") {
      // fetchMSMEDetailsAPI.mutate(data);
      let payload = {
        pan: PAN.toUpperCase(),
        contact_id: customerDetails.contactID,
      };
      const headers = { apiKey, apiSecret };
      payload.headers = headers;
      // } else {

      switchToNextSlide(activeSlide + 1);
    } else {
      let payload = {
        pan: PAN.toUpperCase(),
        contact_id: customerDetails.contactID,
      };
      const headers = { apiKey, apiSecret };
      payload.headers = headers;

      if (category !== "firm") {
        fetch42companyDetailsAPI.mutate(payload);
      } else {
        fetchMSMEDetailsAPI.mutate(data);
      }
      // switchToNextSlide(39);
    }
  };

  const fetchProbe42CompanyDetailsSuccess = (data) => {
    switchToNextSlide(39);
  };

  const fetch42companyDetailsAPI = useMutation({
    mutationFn: (payload) => fetchProbe42CompanyDetails(payload),
    mutationKey: ["fetch42companyDetails"],
    onSuccess: (data) => fetchProbe42CompanyDetailsSuccess(data),
  });

  const handleSuccess = (data) => {
    const panData = data.data;
    if (panData.status === "1") {
      const mobileInPan = panData.result.mobile;
      const isAadhaarLinked = panData.result.aadharlink;
      if (JourneyVersion === "J3") {
        switchToNextSlide(10);
      } else {
        switchToNextSlide(activeSlide + 1);
      }
      if (mobileInPan == customerDetails.mobileNumber && isAadhaarLinked) {
      } else {
        setError({
          error: true,
          type: Errors.PAN_ERROR_TYPE,
          message: Errors.INVALID_PHONE_ERROR,
        });
      }
    } else if (
      panData.status == 2 ||
      panData.status == 3 ||
      panData.status == 4
    ) {
      setError({
        error: true,
        type: Errors.PAN_ERROR_TYPE,
        message: panData.message,
      });
    }
  };
  const handleError = (error) => {
    console.log("ERROR : ", error);
    toast.error("Something went wrong please try again.");
  };
  const handleCheckPhoneOnPan = async () => {
    const location = await getLocationPermission();
    const PAN = panRef.current.value;

    const headers = { apiKey, apiSecret };
    let payload = {
      headers,
      pan: PAN.toUpperCase(),
      contactId: customerDetails.contactID,
      mobileNumber: customerDetails.mobileNumber,
    };

    if (location) {
      payload = { ...payload, ...location };
    }
    checkPhoneOnPanAPI.mutate(payload);
  };

  // * Main Function
  async function addPAN() {
    const PAN = panRef.current.value;
    if (!handlePAN(PAN, Errors.PAN_ERROR_TYPE)) {
      return false;
    }
    if (!error.error) {
      setError({
        error: false,
        type: "",
        message: "",
      });
      setLoading(true);
      const data = {
        pan: PAN.toUpperCase(),
        contact_id: customerDetails.contactID,
      };
      const details = {
        // pan: PAN,
      };
      setCustomerDetails({
        ...customerDetails,
        ...details,
      });
      const slidePayload = {
        slideName: "PAN",
        slideIndex: 2,
        slideIcon: "Pan",
        totalSlides: TOTAL_SLIDES,
      };
      const response = await updateJourneyData(data, slidePayload);

      // if (response) {

      // console.log('fetch data=>',response)
      setCustomerDetailsCookies(details);

      const headers = { apiKey, apiSecret };
      data.headers = headers;
      fetchAllInOnePANDetailsAPI.mutate(data);

      setLoading(false);
    }
  }

  // * Handler

  function handlePAN(PAN, errorType) {
    if (PAN === "") {
      setError({
        error: true,
        type: errorType,
        message: Errors.PAN_ERROR,
      });
      return false;
    }
    if (!validatePAN(PAN)) {
      setError({
        error: true,
        type: errorType,
        message: Errors.INVALID_PAN_ERROR,
      });
      return false;
    }
    if (error.error && error.type === errorType) {
      const PAN = panRef.current.value;
      const data = {
        pan: PAN.toUpperCase(),
      };
      const headers = { apiKey, apiSecret };
      data.headers = headers;
      data.contactId = customerDetails.contactID;

      // fetchCompanyDetailsAPI.mutate(data);
      validatePANAPI.mutate(data);

      return true;
    }
    return true;
  }
  return (
    <div className={SlideStyle.carouselSlideWrapper}>
      <div className={SlideStyle.typographyContainer}>
        <h1>Please Enter PAN of the borrower</h1>
        {/* <p>Please fill all the details carefully.</p> */}
      </div>
      {/* Input Fields */}
      <div className={SlideStyle.dataFieldsContainer}>
        {/* PAN */}
        <TextInputField
          type="text"
          label="Borrower's PAN"
          maxLength="10"
          minLength="10"
          placeholder="Enter PAN here..."
          name="pan"
          id="pan"
          reference={panRef}
          required={true}
          uppercase={true}
          errorType={validatePANAPI.isPending ? "" : Errors.PAN_ERROR_TYPE}
          handler={(pan) => handlePAN(pan, Errors.PAN_ERROR_TYPE)}
          autoFocus={true}
        />
        {/* {validatePANAPI.isPending ? <Loader /> : ""} */}
      </div>

      {/* Continue Slide Button */}
      <div className={SlideStyle.continueBtn}>
        <ContinueButton
          loading={
            loading ||
            validatePANAPI.isPending ||
            checkPhoneOnPanAPI.isPending ||
            fetchMSMEDetailsAPI.isPending ||
            fetch42companyDetailsAPI.isPending ||
            fetchAllInOnePANDetailsAPI.isPending
          }
          // disabled={panRef.current.value?.length > 0 ? false : true}
          handler={addPAN}
        />
      </div>
    </div>
  );
};

export default PANSlide;
