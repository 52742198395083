import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { updateTimeStamp } from "../../../../api/services/Journey";
import style from "./PreApprovedOffer.module.css";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EastIcon from "@mui/icons-material/East";
import { getBankIcon } from "../../../../utils";

export default function PreApprovedOffer({ content, updateJourneyLastVisit }) {
  const { apiKey, apiSecret } = useParams();
  const updateTimeStampAPI = useMutation({
    mutationKey: ["updateTimeStamp"],
    mutationFn: (Payload) => updateTimeStamp(Payload),
    retry: false,
  });
  const applyLinkHandler = () => {
    updateJourneyLastVisit();
    let currentDate = new Date();
    let payload = {
      headers: { apiKey, apiSecret },
      timestamp: currentDate,
      log_status: content?.status,
      lead_id: content?.leadID,
      bank_name: content?.bankName,
    };
    updateTimeStampAPI.mutate(payload);
  };

  const bankIcon = getBankIcon(content.bankImageName);

  return (
    <div className={style.offer}>
      <div className={style.wrapper}>
        <div className={style.cardRow}>
          <div className={style.loanName}>
            {bankIcon ? (
              <div className={style.bankImage}>
                <img
                  src={bankIcon}
                  alt="Bank Icon"
                  className={style.bankImageIcon}
                />
              </div>
            ) : (
              <div className={style.bank}>
                <AccountBalanceIcon className={style.bankIcon} />
              </div>
            )}
            <p>{content.bankName}</p>
          </div>

          {content.status === "Approved" ? (
            <div className={style.loanStage}>
              <span>Approved</span>
            </div>
          ) : null}
        </div>

        <div className={style.loanDetails}>
          {content.approvedAmount ? (
            <div className={style.loanDetail}>
              <p className={style.featureLabel}>Amount</p>
              <p className={style.featureValue}>{content.approvedAmount}</p>
            </div>
          ) : null}
          {content.interest ? (
            <div className={style.loanDetail}>
              <p className={style.featureLabel}>Interest Rate</p>
              <p className={style.featureValue}>{content.interest}%</p>
            </div>
          ) : null}
          {content.tenor ? (
            <div className={style.loanDetail}>
              <p className={style.featureLabel}>Tenure</p>
              <p className={style.featureValue}>{content.tenor} Months</p>
            </div>
          ) : null}
        </div>

        <div className={style.cardFooter}>
          <a
            href={content.applyLink}
            target="_blank"
            alt="Loan Apply Btn"
            className={style.applyBtn}
            onClick={applyLinkHandler}
          >
            Apply Here
            <EastIcon className={style.applyBtnIcon} />
          </a>
        </div>
      </div>
    </div>
  );
}
