import { useState, useEffect, createContext } from "react";

const CustomerContext = createContext({
  presetData: {}
});

export function CustomerContextProvider({ children }) {
  const [customerDetails, setCustomerDetails] = useState({});
  useEffect(
    () => console.log("customerDetails : ", customerDetails),
    [customerDetails]
  );

  return (
    <CustomerContext.Provider value={{ customerDetails, setCustomerDetails }}>
      {children}
    </CustomerContext.Provider>
  );
}

export default CustomerContext;
