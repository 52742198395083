import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// * Styles
import style from "./ImageInputField.module.css";
import { useState, useEffect } from "react";
// * Components
// import UplaodButton from "../../Buttons/UploadButton/UploadButton";
import ContinueButton from "../../Buttons/SlideContinueButton/ContinueButton";
// * Icons
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AddIcon from "@mui/icons-material/Add";
// * Notificaiton
// import { toast } from "react-toastify";
// * Helpers
// import { isPDFPasswordProtected } from "../../../helpers";

const DocumentUploadWithPassword = ({
  accept,
  allowedFileTypes,
  file,
  setFile,
  multiple,
  max = false,
  isDocAvailable,
  isLoading,
  hasDocument = false,
  password,
  setPassword,
}) => {
  const [toggleUploadModal, setToggleUploadModal] = useState(false);
  const [currentFiles, setCurrentFiles] = useState([]);

  useEffect(() => {
    if (hasDocument) {
      setToggleUploadModal(false);
    }
  }, [hasDocument]);

  // handle drag event
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // handle drop event
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (multiple) {
      if (max && e.dataTransfer.files.length > max) {
        let files = [...e.dataTransfer.files];
        files.length = max;
        setCurrentFiles(files);
      } else {
        setCurrentFiles([...e.dataTransfer.files]);
      }
    } else {
      setCurrentFiles([e.dataTransfer.files[0]]);
    }
  };

  // handle file input change event
  const handleFileChange = (e) => {
    if (multiple) {
      let files = [...e.target.files];
      if (max && files.length > max) {
        files.length = max;
        setCurrentFiles(files);
      } else {
        setCurrentFiles((prev) => [...prev, ...e.target.files]);
      }
    } else {
      setCurrentFiles([e.target.files[0]]);
    }
  };

  const closeImageUploadModal = () => {
    setToggleUploadModal(false);
    setCurrentFiles([]);
  };

  const removeFiles = (fileName) => {
    if (password) {
      setPassword("");
    }
    setCurrentFiles(() =>
      currentFiles.filter((file) => file.name !== fileName)
    );
  };

  const addDocument = async () => {
    // * Check if the file is password protected
    // const doc = currentFiles[0];
    // const pdfBytes = await doc.arrayBuffer();
    // const isProtected = await isPDFPasswordProtected(pdfBytes);
    // if (isProtected && !password) {
    //   toast.error("File is password protected, please enter the password.");
    //   return;
    // }

    setFile([...file, ...currentFiles]);
    setCurrentFiles([]);
    setToggleUploadModal(false);
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <button
        className={`${style.imageBtn}  ${
          isDocAvailable ? style.activeErrorButton : null
        }`}
        onClick={() =>
          file.length !== max ? setToggleUploadModal(true) : null
        }
      >
        <AddIcon
          style={{ fontSize: "40px" }}
          className={
            file.length === max
              ? style.inActiveAddDocumentIcon
              : isDocAvailable
              ? style.errorAddDocumentIcon
              : style.activeAddDocumentIcon
          }
        />
      </button>

      {toggleUploadModal ? (
        <div className={style.imageUploadModalWrapper}>
          <div className={style.imageUploadModal}>
            <div className={style.modalHeader}>
              <button
                className={style.closeModalBtn}
                onClick={closeImageUploadModal}
                disabled={isLoading ? true : false}
              >
                <ClearIcon
                  className={`${isLoading ? style.closeModalBtnIcon : null}${
                    style.closeBtnIcon
                  }`}
                />
              </button>
            </div>

            <div className={style.dragAndDropInputContainer}>
              <div
                className={style.dragAndDropBox}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              >
                {currentFiles.length ? (
                  <div className={style.filePreview}>
                    {currentFiles.map((doc) =>
                      doc.type.includes("image") ? (
                        <div key={doc.name}>
                          <img src={URL.createObjectURL(doc)} alt="Preview" />
                          <ClearIcon
                            className={style.removeImageBtnIcon}
                            onClick={() => removeFiles(doc.name)}
                          />
                        </div>
                      ) : (
                        <div className={style.fileNamePreview} key={doc.name}>
                          <p className={style.fileName}>{doc.name}</p>
                          <ClearIcon
                            className={style.removeFileBtn}
                            onClick={() => removeFiles(doc.name)}
                          />
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div className={style.fileUpload}>
                    <CloudUploadIcon className={style.imageUploadIcon} />
                    <label htmlFor="file-input">
                      Drag and drop an image <br />
                      or click here to select a {multiple ? "files" : "file"}
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept={accept && accept}
                      onChange={handleFileChange}
                      multiple={multiple && multiple}
                    />
                  </div>
                )}
              </div>

              <div className={style.allowedFileTypes}>
                <p>
                  Allowed file types : {allowedFileTypes && allowedFileTypes}
                </p>
              </div>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center", // Added this line to center items vertically
                }}
              >
                <div>
                  <FormControl sx={{ m: 1 }} variant="outlined">
                    <InputLabel
                      size="small"
                      htmlFor="outlined-adornment-password"
                    >
                      Password (if any)
                    </InputLabel>
                    <OutlinedInput
                      size="small"
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password (if any)"
                    />
                  </FormControl>
                </div>
              </Box>
            </div>

            <div className={style.uploadBtnContainer}>
              <ContinueButton
                label="Add Document"
                loading={isLoading}
                handler={addDocument}
                disabled={currentFiles.length ? false : true}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DocumentUploadWithPassword;
