import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Router from "./router/Router";
import ReactGA from "react-ga";
import { useEffect } from "react";

// * For Notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DIGIO_SDK_SOURCE } from "./constants";

const HOST = window.location.host;

let TRACKING_ID = "";

if (HOST.includes("yoindi")) {
  TRACKING_ID = "G-NC1QK42XSJ";
}

ReactGA.initialize(TRACKING_ID);

const App = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = DIGIO_SDK_SOURCE;
    script.async = true;
    document.body.appendChild(script);

    ReactGA.pageview(window.location.pathname + window.location.search);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="App">
      <Router />
      <ToastContainer />
    </div>
  );
};

export default App;
