import style from "./Agreement.module.css";

import { useState, useRef } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";
// * Contexts
import CustomerContext from "../../../context/CustomerContext";

// * Components
import ContinueButton from "../../Buttons/SlideContinueButton/ContinueButton";
import DocumentContent from "../../DocumentContent/DocumentContent";

// * APIs and API handler
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import {
  addCustomerDocumentKycResponse,
  uploadCustomerDocument,
  getCustomerDocuments,
} from "../../../api/services/documents";
import { createTimeline } from "../../../api/services/Journey";
import {
  addAgreementDocument,
  sendAgreementMail,
  updateAgreementDocument,
  getAgreementDocument,
} from "../../../api/services/Agreement";

// * Constants
import { TOTAL_SLIDES } from "../../../constants";

// * Helpers
import { getCookiePathFromURL, getLocationPermission } from "../../../helpers";

// * Utilities
import {
  formatVariableOutput,
  pdfGenerator,
} from "../../../utils/SanctionLetter/SanctionLetter.utils";
import { removeAllCookies } from "../../../helpers";

// * Other
import { toast } from "react-toastify";

export default function Agreement({
  agreement,
  updateLastVisitSlide,
  switchToNextSlide,
}) {
  const queryClient = useQueryClient();
  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;
  const CookiePath = getCookiePathFromURL(window.location.href);

  const { customerDetails } = useContext(CustomerContext);
  const { apiKey, apiSecret } = useParams();

  const contentDivRef = useRef();

  const initialAgreementDocumentState = {
    hasDocument: false,
    document: null,
  }; // * Agreement customer document.
  const [agreementDocument, setDocument] = useState(
    initialAgreementDocumentState
  );

  const [continueButtonState, setContinueButtonState] = useState(true);
  const [existingAgreement, setExistingAgreement] = useState(null);
  const [conversionLoading, setConversionLoading] = useState(false);

  // * API to create a Timeline
  const createTimelineAPI = useMutation({
    mutationFn: (Payload) => createTimeline(Payload),
    retry: false,
  });
  const handleCreateTimeline = (data) => {
    data.leadId = customerDetails.leadId;
    const headers = {
      apiKey,
      apiSecret,
    };
    const Payload = {
      data,
      headers,
    };
    createTimelineAPI.mutate(Payload);
  };

  // * API to get agreement
  const getCustomerDocumentsAPI = useQuery({
    queryKey: ["customerDocuments"],
    queryFn: () =>
      getCustomerDocuments({
        contactId: customerDetails.contactID,
        headers: { apiKey, apiSecret },
      })
        .then(handleGetCustomerDocumentsSuccess)
        .catch(handleGetCustomerDocumentsError),
    enabled: customerDetails.contactID ? true : false,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const handleGetCustomerDocumentsSuccess = (data) => {
    const document = data.data.filter((doc) => doc.docType === "agreement");
    if (document.length) {
      setDocument({
        hasDocument: true,
        document: document[0],
      });
    }
    return data;
  };
  const handleGetCustomerDocumentsError = (error) => {
    return error;
  };

  // * API to get agreement
  const getAgreementDocumentAPI = useQuery({
    queryKey: ["agreementDocument"],
    queryFn: () =>
      getAgreementDocument({
        leadId: customerDetails.leadId,
        headers: { apiKey, apiSecret },
      })
        .then(handleGetAgreementDocumentSuccess)
        .catch(handleGetAgreementDocumentError),
    enabled: customerDetails.leadId ? true : false,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const handleGetAgreementDocumentSuccess = (data) => {
    setExistingAgreement(data.data);
    return data;
  };
  const handleGetAgreementDocumentError = (error) => {
    return error;
  };

  // * API to add Agreement
  const addAgreementDocumentAPI = useMutation({
    mutationKey: ["addAgreement"],
    mutationFn: (payload) =>
      addAgreementDocument(payload)
        .then(handleAddAgreementDocumentSuccess)
        .catch(handleAddAgreementDocumentError),
    enabled: customerDetails.leadId ? true : false,
    retry: false,
  });
  const handleAddAgreementDocumentSuccess = (data) => {
    const document = data.data.CustomerDocument;
    // * Refetch generated sanction letter
    queryClient.invalidateQueries({ queryKey: ["agreementDocument"] });

    // * Send agreement email to the customer for E-Sign
    // handleSendAgreementMail(document);

    // * Create timeline for Agreement generation
    const timelineData = {
      type: "Agreement",
      message: "Agreement generated.",
    };
    handleCreateTimeline(timelineData);

    return data;
  };
  const handleAddAgreementDocumentError = (error) => {
    console.log("error : ", error);
    if (error.response.status === 400) {
      console.log("Agreement already exists.");
    } else {
      toast.error("Something went wrong, please try again.");
    }
    return error;
  };
  const handleAddAgreementDocument = (document_id) => {
    const { leadId, contactID, pipelineID } = customerDetails;

    const headers = {
      apiKey,
      apiSecret,
    };
    const data = {
      contact_id: contactID,
      lead_id: leadId,
      pipeline_id: pipelineID,
      document_id,
      document: agreement.document,
      offerGeneratedAt: new Date(),
      status: "Generated",
      remark: "Agreement generated successfully.",
    };
    const Payload = {
      headers,
      data,
    };
    addAgreementDocumentAPI.mutate(Payload);
  };

  // * API to update agreement document.
  const updateSanctionLetterDocumentAPI = useMutation({
    mutationKey: ["updateAgreementDocument"],
    mutationFn: (payload) =>
      updateAgreementDocument(payload).then(
        handleUpdateAgreementDocumentSuccess
      ),
    enabled: customerDetails.leadId ? true : false,
    retry: false,
  });
  const handleUpdateAgreementDocumentSuccess = (data) => {
    const document = data.data.CustomerDocument;
    // * Send agreement email to the customer for E-Sign
    // handleSendAgreementMail(document);

    // * Create timeline for agreement cancellation
    const timelineData = {
      type: "Agreement",
      message: "agreement cancelled.",
    };
    handleCreateTimeline(timelineData);

    return data;
  };
  const handleUpdateAgreementDocument = (data) => {
    const headers = {
      apiKey,
      apiSecret,
    };
    const Payload = {
      leadId: customerDetails.leadId,
      data,
      headers,
    };
    updateSanctionLetterDocumentAPI.mutate(Payload);
  };

  // * API to send agreement via mail to the customer.
  const sendAgreementMailAPI = useMutation({
    mutationKey: ["sendAgreement"],
    mutationFn: (payload) =>
      sendAgreementMail(payload)
        .then(handleAgreementMailSuccess)
        .catch(handleAgreementMailError),
    retry: false,
  });
  const handleAgreementMailSuccess = (data) => {
    toast.success(
      "We have sent you an email on your registered personal mail, please check and sign the document."
    );

    // * Create time
    const timelineData = {
      type: "Agreement",
      message: "Agreement sent.",
    };
    handleCreateTimeline(timelineData);

    const slidePayload = {
      headers: { apiKey, apiSecret },
      leadId: customerDetails?.leadId,
      slideName: "Loan Agreement",
      // slideIndex: 30,
      slideIcon: "Agreement",
      totalSlides: TOTAL_SLIDES,
      journeyVersion: journeyVersion,
    };
    // * Update Journey last visit slide
    updateLastVisitSlide(slidePayload);
    switchToNextSlide();

    return data;
  };
  const handleAgreementMailError = (error) => {
    console.log("Mail Error : ", error);
    finishJourney();
    return error;
  };
  const handleSendAgreementMail = (document) => {
    const contact = customerDetails?.leadDetails?.Contact;
    if (contact && contact.email) {
      const headers = {
        apiKey,
        apiSecret,
      };
      const data = {
        customerEmail: contact.email,
        documentURL: `${process.env.REACT_APP_DOCUMENTS_BASE_URL}${document.docUpload}`,
        amount: 1,
        leadId: customerDetails.leadId,
      };
      const Payload = {
        headers,
        data,
      };
      sendAgreementMailAPI.mutate(Payload);
    }
  };

  // * API to add KYC Response for customer document
  const addKycResponseAPI = useMutation({
    mutationFn: (Payload) => addCustomerDocumentKycResponse(Payload),
    retry: false,
  });
  const handleAddKycResponse = (documentId) => {
    let kycData = {};
    const variables = agreement.documentVariables;

    variables.forEach((variable) => {
      kycData[variable.label] = formatVariableOutput(
        variable.value,
        variable.variable
      );
    });
    kycData["Agreement Stage Status"] = "Generated";

    const headers = {
      apiKey,
      apiSecret,
    };
    const Payload = {
      contactId: customerDetails.contactID,
      documentId,
      kycData,
      headers,
    };
    addKycResponseAPI.mutate(Payload);
  };

  // * API to upload customer document
  const uploadCustomerDocumentAPI = useMutation({
    mutationKey: ["uploadCustomerDocument"],
    mutationFn: (Payload) =>
      uploadCustomerDocument(Payload)
        .then(handleDocumentUploadSuccess)
        .catch(handleDocumentUploadError),
    retry: false,
  });
  const handleDocumentUploadSuccess = (data) => {
    // * Save KYC Reponse
    handleAddKycResponse(data.data.id);
    // * Set Document
    setDocument({ hasDocument: true, document: data.data });

    if (existingAgreement) {
      // * Update the status of the generated Sanction Letter.
      const agreementUpdatedData = {
        contact_id: customerDetails.contactID,
        document_id: data.data.id,
        document: agreement.document,
        offerCancelledAt: null,
        offerSentAt: null,
        offerGeneratedAt: new Date(),
        status: "Generated",
        remark: "Agreement generated successfully.",
      };
      handleUpdateAgreementDocument(agreementUpdatedData);
    } else {
      // * Create an Agreement
      handleAddAgreementDocument(data.data.id);
    }

    return data;
  };
  const handleDocumentUploadError = (error) => {
    toast.error("Something went wrong, please try again.");
    return error;
  };

  const uploadGeneratedAgreement = async (pdfBase64, documentName) => {
    const location = await getLocationPermission();
    // 720000
    const headers = {
      apiKey,
      apiSecret,
    };
    let Payload = {
      headers,
      contact_id: customerDetails.contactID,
      pipeline_id: customerDetails.pipelineID,
      lead_id: customerDetails.leadId,
      docUpload: pdfBase64,
      fileName: documentName,
      docNumber: "",
      docName: "Agreement",
      docFormat: "pdf",
      docType: "loanOfferAgreement",
      source_name: "custom",
    };
    if (location) {
      Payload = { ...Payload, ...location };
    }
    uploadCustomerDocumentAPI.mutate(Payload);
  };

  const finishJourney = () => {
    // * NEW
    const slidePayload = {
      headers: { apiKey, apiSecret },
      leadId: customerDetails?.leadId,
      slideName: "Success : Journey Completed",
      slideIndex: 38,
      slideIcon: "Success",
      totalSlides: TOTAL_SLIDES,
    };
    // * Update Journey last visit slide
    updateLastVisitSlide(slidePayload);
    removeAllCookies(CookiePath);
    switchToNextSlide(34);
  };

  const handleSubmitAgreement = async () => {
    const contact = customerDetails?.leadDetails?.Contact;
    const pdfName = `${contact?.full_name}_agreement`;
    setConversionLoading(true);
    const pdfBase64 = await pdfGenerator("template", false, pdfName, 1);
    setConversionLoading(false);
    uploadGeneratedAgreement(pdfBase64, pdfName);
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = contentDivRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 1 && continueButtonState) {
      setContinueButtonState(false);
    }
  };

  const handleSubmitAgreement2 = () => {
    if (agreement.document) {
      const slidePayload = {
        headers: { apiKey, apiSecret },
        leadId: customerDetails?.leadId,
        slideName: "Loan Agreement",
        // slideIndex: 32,
        slideIcon: "Agreement",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      // * Update Journey last visit slide
      updateLastVisitSlide(slidePayload);

      switchToNextSlide();
    } else {
      finishJourney();
    }
  };

  return (
    <div className={style.sanctionLetterSlide}>
      <div className={style.slideBody}>
        <div className={style.slideHeader}>
          <h1>Loan Agreement</h1>
          <p>Here is your loan agreement please sign it to proceed.</p>
        </div>

        <div className={style.documentContainer}>
          {agreement.document ? (
            <DocumentContent
              content={agreement.document}
              reference={contentDivRef}
              handleScroll={handleScroll}
            />
          ) : null}
        </div>
      </div>

      <div className={style.actionBox}>
        <ContinueButton
          label="Proceed To Sign"
          loading={
            conversionLoading ||
            uploadCustomerDocumentAPI.isPending ||
            addKycResponseAPI.isPending ||
            addAgreementDocumentAPI.isPending ||
            sendAgreementMailAPI.isPending
          }
          // handler={handleSubmitAgreement}
          handler={handleSubmitAgreement2}
          disabled={continueButtonState}
        />
      </div>
    </div>
  );
}
