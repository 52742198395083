// * Styles
import "../App.css";
import "../styles/JourneyRightSection/responsive.css";
// * React Hooks
import { useEffect, useRef, useState, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
// * APIs
import { getOtp, resendOtp } from "../store/actions/getOtp";
import {
  getAutoPullBureauDetails,
  getAutoPullBureauDetailsSuccess,
} from "../store/actions/autoPullBureauDetails";
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { GrAccessibility } from "react-icons/gr";
import { PiCoinsThin } from "react-icons/pi";
import { LiaRingSolid } from "react-icons/lia";
import { addJourney } from "../store/actions/addJourney";
import { journeyFields } from "../store/actions/journeyFields";
// import { verifyOtp } from "../store/actions/verifyOtp";
// import { SiMicrosoftoffice } from "react-icons/si";
import { SiMicrosoft } from "react-icons/si";
import {
  realTimeLeadPush,
  realTimeLeadPushSuccess,
  realTimeLeadPushError,
} from "../store/actions/realTimeLeadPush";
import {
  getProfileDetails,
  getProfileDetailsSuccess,
} from "../store/actions/profile";
import {
  getPreApprovedOffers,
  pushRealTimeLead,
  updateJourneyLastVisitedSlide,
  sendLeadDetailsToAdminViaMail,
  getOfferSettings,
  verifyLeadOtp,
  updateLeadData,
  updateLeadStageStatus,
  updatedCustomerDetails,
  createTimeline,
} from "../api/services/Journey";
import { createMandateFrom } from "../api/services/ENACH";
// * LMS APIs
import {
  getAuthToken,
  createClient,
  createClientIdentifier,
  createAddressDetails,
  createBankAccount,
  createIdentifierDocuments,
  createClientDocument,
  createLoan,
  approveLoanAmount,
  disburseLoanAmount,
} from "../api/services/LMS";

// * NPM Packages
import { Carousel } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";
import PinCodeDirectory from "india-pincode-lookup";
import useReactIpLocation from "react-ip-details";
import isEmail from "validator/lib/isEmail";
import { useCookies } from "react-cookie";

// * Contexts
import ErrorContext from "../context/ErrorProvider";
import CustomerContext from "../context/CustomerContext";

// ** Helper Functions
import { calculateAge } from "../utils/common";
import {
  removeAllCookies,
  isCookieExpired,
  getCookiePathFromURL,
  getLocationPermission,
  trimString,
  formatNumber,
} from "../helpers";
// ** Micro Components
import TextInputField from "../components/DataInputFields/TextInputField/TextInputField";
import ContinueButton from "../components/Buttons/SlideContinueButton/ContinueButton";
import SelectorButton from "../components/Buttons/SelectorButton/SelectorButton";
// import RangeInputField from "../components/DataInputFields/RangeInputField/RangeInputField";
// import DateInputField from "../components/DataInputFields/DateInputField/DateInputField";
import DropDownField from "../components/DataInputFields/DropDownField/DropDownField";
import PersonalLoanDetails from "../components/PersonalLoanDetails/PersonalLoanDetails";
import PowerdByRoopya from "../components/PowerdByRoopya/PowerdByRoopya";
// * Lender Specific Loan Details
import RecapitaLoanDetails from "../components/LenderLoanDetails/Recapita";
import MyfindocLoanDetails from "../components/LenderLoanDetails/Myfindoc";

// ** Material Icons
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import MaleIcon from "@mui/icons-material/Male";
import { TbHomePlus } from "react-icons/tb";
import { TbHomeStar } from "react-icons/tb";
import { IoBookOutline } from "react-icons/io5";
import { GiCommercialAirplane } from "react-icons/gi";
import FemaleIcon from "@mui/icons-material/Female";
import { MdOutlineEventAvailable } from "react-icons/md";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { BiPurchaseTagAlt } from "react-icons/bi";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";

// * Journey Slides
import OTPSlide from "../components/Slides/OTPSlide/OTPSlide";
import FullNameDobPANSlide from "../components/Slides/Other/FullNameDOBPAN/Slide";
// import TakeSelfie from "../components/Slides/TakeSelfie/TakeSelfie";
import TakeSelfie from "../components/Slides/TakeSelfie/TakeSelfie";
import PanVerificationSlide from "../components/Slides/AddressVerification/PANVerificationSlide/PANVerificationSlide";
import CustomerAddressVerification from "../components/Slides/AddressVerification/CustomerAddressVerification/CustomerAddressVerification";
import AadharVerificationSlide from "../components/Slides/AddressVerification/AadhaarVerification/AadhaarVerificationSlide/AadhaarVerificationSlide";
// import AadharVerificationSlideOLD from "../components/Slides/AddressVerification/AadharVerificationSlide/AadharVerificationSlide copy";
import AadhaarNumberSlide from "../components/Slides/AddressVerification/AadhaarVerification/AadhaarNumberSlide/AadhaarNumberSlide";
import AadhaarOTPSlide from "../components/Slides/Other/AadhaarOTP/Slide";
// import ConfirmPassportSlide from "../components/Slides/PassportSlide/PassportConfirmationSlide/PassportConfirmationSlide";
// import PassportSlide from "../components/Slides/PassportSlide/PassportSlide";
import LoanOffer from "../components/Slides/LoanOffer/LoanOffer";
import JourneySuccessSlide from "../components/Slides/JourneySuccessSlide/JourneySuccessSlide";
import SalarySlipVerificationSlide from "../components/Slides/IncomeVerificationSlide/SalarySlipVerification/SalarySlipVerificationSlide";
import BankStatementVerification from "../components/Slides/IncomeVerificationSlide/BankStatementVerificationSlide/BankStatementVerification";
// import Form16Slide from "../components/Slides/IncomeVerificationSlide/Form16Slide/Form16Slide";
import PennyDrop from "../components/Slides/PennyDrop/PennyDrop";
import SanctionLetterSlide from "../components/Slides/SanctionLetter/SanctionLetterSlide";
import SanctionLetterSign from "../components/Slides/SanctionLetterSign/SanctionLetterSign";
import SignedDocument from "../components/Slides/SignedDocument/SignedDocument";
import AgreementSlide from "../components/Slides/Agreement/AgreementSlide";
import PreApprovedOffers from "../components/Slides/PreApprovedOffers/PreApprovedOffers";
import LoanAmount from "../components/Slides/LoanAmount/LoanAmount";
import BusinessDetails from "../components/Slides/BusinessDetails/BusinessDetails";

// ** Error messages and error types
import * as Errors from "../utils/errors";
// * Constants
import {
  APP_ID,
  LEAD_ID,
  CONTACT_ID,
  PIPELINE_ID,
  JOURNEY_AUTH_TOKEN,
  LAST_ACTIVE_JOURNEY_SLIDE,
  EMPLOYEMENT_TYPE,
  HAS_CO_APPLICANT,
  CUSTOMER_DETAILS,
  COOKIES_MAX_AGE,
  residenceNature,
  TOTAL_SLIDES,
  DIGIO_ENVIRONMENT,
  SLIDE_HISTORY,
} from "../constants";
// * Selcector Input Options
import {
  // genderOptions,
  qualificationExamples,
  coApplicantRelations,
  companyTypes,
  salaryCreditTypes,
  natureOfBusinessOptions,
} from "../constants";
import { useMutation } from "@tanstack/react-query";
import AgreementSign from "../components/Slides/AgreementSign/AgreementSign";
import { toast } from "react-toastify";
import { leadPushForParticipant } from "../api/services/Lead login to participant";
import { getLeadDetailsMaxemo } from "../api/services/Maxemo";

// * Utilities
import { getItem, setItem } from "../utils/LocalStorage";
import { getPipelineSettings } from "../api/services/Pipeline";
import { getLastSlideDetails } from "../api/services/documents";
import UdyamRegistrationSlide from "../components/Slides/commercialJourneySlides/UdyamRegistrationNumberSlide/UdyamRegNo";
import CompanyDetailsSlide from "../components/Slides/commercialJourneySlides/CompanyDetails/CompanyDetails";
import CompanyAddressDetailsSlide from "../components/Slides/commercialJourneySlides/CompanyAddressDetails/CompanyAddressDetails";
import GSTConsentSlides from "../components/Slides/commercialJourneySlides/GSTConsentSlides/GSTConsentSlides";
import AuthorizedSignatoryPANSlide from "../components/Slides/commercialJourneySlides/AuthorizedSignatoryPAN/AuthorizedSignatoryPAN";
import CommercialBankStatement from "../components/Slides/commercialJourneySlides/CommercialBankStatement/CommercialBankStatement";
import LoanAmountSlide from "../components/Slides/commercialJourneySlides/LoanAmountSlide/LoanAmountSlide";
import SuccessSlide from "../components/Slides/commercialJourneySlides/SuccessSlide/SuccessSlide";
import PANSlide from "../components/Slides/commercialJourneySlides/PAN/PAN";
import {
  getSmsTemplateStageWise,
  sendIndividualStageWiseSMS,
} from "../api/services/SMS";
import EditButton from "../components/Buttons/SlideEditButton/EditButton";

const JourneyForm = (props) => {
  const CookiePath = getCookiePathFromURL(window.location.href);
  let domainName = window.location.host.split(".")[0];
  // let domainName = "maxemocapital";
  // let domainName = "myfindoc";
  const [isEditProfEmail, setIsEditProfEmail] = useState(false);

  const [isEditCommAddress, setIsEditCommAddress] = useState(false);

  let [loanAmt, setLoanAmt] = useState("");
  let [tenure, setTenure] = useState(0);

  const { ipResponse } = useReactIpLocation({ numberToConvert: 100 });
  const { apiKey, apiSecret } = useParams();

  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  // const [salariesInBankStatement, setSalariesInBankStatement] = useState(null);
  // const [salary, setSalary] = useState([]);

  // * Cookies
  const [cookies, setCookie] = useCookies(["user"]);

  const [scrollPosition, setScrollPosition] = useState(0);
  // * Slide trackers States
  const [activeSlide, setActiveSlide] = useState(0);
  const [history, setHistory] = useState([]);

  // * State to handle API loading
  const [loading, setLoading] = useState(false);

  // * Context to store customer details
  const { customerDetails, setCustomerDetails } = useContext(CustomerContext);

  const initialLoanSettings = {
    minimumLoanAmount: null,
    maximumLoanAmount: null,
  };
  const [loanSettings, setLoanSettings] = useState(initialLoanSettings);
  const [pipelineSettings, setPipelineSettings] = useState({});

  // * Other States
  const [previousCustomerDetails, setPreviousCustomerDetails] = useState(null);
  // * State to handle Journey error
  const { error, setError } = useContext(ErrorContext);

  // * State to store all selectors element values
  // const [selectorValues] = useState({
  //   gender: "",
  //   maritalStatus: "",
  //   hasCoApplicant: null,
  //   employementType: "",
  // });
  const selectorValues = {
    gender: "",
    maritalStatus: "",
    hasCoApplicant: null,
    employementType: "",
    purposeOfLoan: "",
  };
  // * Mobile Number Slide States and Refs
  const mobileNumberRef = useRef();
  // * OTP Slide States and Refs
  const otpRefKey1 = useRef();
  const otpRefKey2 = useRef();
  const otpRefKey3 = useRef();
  const otpRefKey4 = useRef();

  const ageRef = useRef();
  
  // * Email Address
  const personalEmailRef = useRef();
  const professionalEmailRef = useRef();

  // const educationalQualificationRef = useRef();
  // * Monthly Income States and Refs
  const monthlyIncomeRef = useRef();
  // * Salary Type Ref
  const salaryTypeRef = useRef();
  // * Residential Address States and Refs
  const shopNameRef = useRef();
  const residentialAddress1Ref = useRef();
  const residentialAddress2Ref = useRef();
  const residentialCityRef = useRef();
  const residentialStateRef = useRef();
  // const residenceAddressTypeRef = useRef();
  const residenceNatureOfAddressRef = useRef();
  const residenceYearsAtCurrentAddressRef = useRef();
  const residentialPinRef = useRef();
  const useResidentialAddressAsPermanentRef = useRef(null);
  // const addressVerificationDocumentRef = useRef(null);
  // const [addressVerificationDocumentType, setAddressVerificationDocumentType] =
  //   useState(null);

  // * Permanent Address States and Refs
  const permanentAddress1Ref = useRef();
  const permanentAddress2Ref = useRef();
  const permanentCityRef = useRef();
  const permanentStateRef = useRef();
  const permanentPinRef = useRef();

  // * Office Address States and Refs
  const officeAddress1Ref = useRef();
  const officeAddress2Ref = useRef();
  const officeCityRef = useRef();
  const officeStateRef = useRef();
  const officePinRef = useRef();
  // * Employement Type State
  const [employmentType, setEmploymentType] = useState("");
  // * Business Details
  const natureOfBusinessRef = useRef();
  // const incorporationDateRef = useRef();
  // * Add Co-Applicant Details
  const coApplicantNameRef = useRef();
  const coApplicantDOBRef = useRef();
  const coApplicantPANRef = useRef();
  const coApplicantMobileRef = useRef();
  const coApplicantEmailRef = useRef();
  const coApplicantRelationRef = useRef();
  // * Company Details ref
  const companyNameRef = useRef();
  const companyTypeRef = useRef();
  const designationRef = useRef();
  const totalWorkExperienceRef = useRef();
  const [loanOffers, setLoanOffers] = useState([]);

  const fullnameRef = useRef("");
  // * Loan Amount
  const [loanAmount, setLoanAmount] = useState(10000);
  // const loanAmountRef = useRef();

  // * APIs Defination

  // * API to send SMS to customer
  // const sendSMSToCustomerAPI = useMutation({
  //   mutationKey: ["sendSMSToCustomer"],
  //   mutationFn: (Payload) => sendSMSToCustomer(Payload),
  //   retry: false,
  // });
  const documentInitialState = {
    document: null,
    documentWithSign: null,
    documentVariables: [],
    content: null,
  };
  // * Sanction Letter
  const [sanctionLetter, setSanctionLetter] = useState(documentInitialState);
  // * Agreement
  const [agreement, setAgreement] = useState(documentInitialState);
  const [signedDocument, setSignedDocument] = useState({
    agreement: null,
    sanctionLetter: null,
  });

  // * SMS Templates Stage Wise
  const [smsTemplates, setSmsTemplates] = useState([]);

  const [isEditEmail, setIsEditEmail] = useState(
    customerDetails?.email?.length > 0 ? true : false
  );

  const [isEditAddress, setIsEditAddress] = useState(
    customerDetails?.communication_address?.line_1?.length > 0 ? true : false
  );

  useEffect(() => {
    if (domainName === "maxemocapital") {
      if (queryValues?.lead_id) {
        handleGetLeadDetailsForMeximo();
      }
    }
  }, [mobileNumberRef.current]);

  // Update customer useContext with updated details
  const getUpdatedCustomerDetailsAPI = useMutation({
    mutationFn: (payload) => updatedCustomerDetails(payload),
    onSuccess: (data) => getUpdatedCustomerDetailsSuccess(data),
    onError: (data) => errorInGetCustomerDetails(data),
    retry: false,
  });

  const getUpdatedCustomerDetailsSuccess = (data) => {
    console.log("CustomerDetails Success=>", data?.data);
    setCustomerDetails({ ...customerDetails, customerDetails: data?.data });
  };
  const errorInGetCustomerDetails = (data) => {
    console.log("Error Data=>", data);
  };
  // useEffect(() => {

  // }, []);

  useEffect(() => {
    if (cookies.token) {
      const {
        appID,
        token,
        aadhaarDocumentID,
        // employmentType,
        // hasCoApplicant,
        contactID,
        leadID,
        pipelineID,
        // customerAge,
      } = cookies;

      const isTokenExpired = isCookieExpired("token");
      if (!isTokenExpired) {
        if (cookies.customerDetails) {
          const details = cookies.customerDetails;

          if (
            cookies.customerDetails?.loanOffers &&
            cookies.customerDetails?.loanOffers?.length
          ) {
            setLoanOffers(cookies.customerDetails.loanOffers);
          }

          setPreviousCustomerDetails(details);
          setCustomerDetails({
            ...details,
            contactID: parseInt(contactID),
            leadId: parseInt(leadID),
            pipelineID: parseInt(pipelineID),
            appID: appID,
            authToken: token,
            aadhaarDocumentID: parseInt(aadhaarDocumentID),
          });
        } else {
          setCustomerDetails({
            contactID: parseInt(contactID),
            appID: appID,
            authToken: token,
            aadhaarDocumentID: parseInt(aadhaarDocumentID),
          });
        }

        if (cookies[SLIDE_HISTORY]) {
          const slideHistory = cookies[SLIDE_HISTORY];
          setHistory(slideHistory);
        }
      } else {
        removeAllCookies(CookiePath);
      }
    } else {
      removeAllCookies(CookiePath);
    }
  }, []);

  useEffect(
    () =>
      window.addEventListener("scroll", () =>
        setScrollPosition(window.pageYOffset)
      ),
    []
  );

  const [email, setEmail] = useState();

  useEffect(() => {

    if (domainName === "maxemocapital") {
      mobileNumberRef.current.value =
        customerDetails?.presetData?.Contact?.phone;
      // fullnameRef.current.value = customerDetails?.presetData?.Contact?.fullName;
      // personalEmailRef.current.value =
      //   customerDetails?.presetData?.Contact?.email;
    }
  }, [customerDetails.presetData]);

  useEffect(() => {
    if(residentialAddress1Ref.current){
      residentialAddress1Ref.current.value = customerDetails?.customerDetails?.address === ", " ? "" :customerDetails?.customerDetails?.address;
    }
    if(residentialAddress2Ref.current){ 
      residentialAddress2Ref.current.value = customerDetails?.customerDetails?.address2;
    }
    if(residentialPinRef.current){
      residentialPinRef.current.value = customerDetails?.customerDetails?.pincode;
    }
    if(residentialCityRef.current){
      residentialCityRef.current.value = customerDetails?.customerDetails?.city
    }
    if(residentialStateRef.current){
      residentialStateRef.current.value = customerDetails?.customerDetails?.state;
    }
    
  },[isEditCommAddress])

  useEffect(() => {
    if(!customerDetails?.customerDetails?.address || customerDetails?.customerDetails?.address === ", "){
      if(residentialAddress1Ref.current){
        residentialAddress1Ref.current.value = ""
      }
      setIsEditCommAddress(true);
    }else if(customerDetails?.customerDetails?.address && customerDetails?.customerDetails?.address !== ", "){
      setIsEditCommAddress(false);
    }
    if(!customerDetails?.customerDetails?.address2){
      if(residentialAddress2Ref.current){
        residentialAddress2Ref.current.value = ""
      }
      setIsEditCommAddress(true);
    }else{
      setIsEditCommAddress(false);
    }
    if(!customerDetails?.customerDetails?.pincode){
      if(residentialPinRef.current){
        residentialPinRef.current.value = "";
      }
      setIsEditCommAddress(true);
    }else{
      setIsEditCommAddress(false);
    }
    if(!customerDetails?.customerDetails?.city){
      if(residentialCityRef.current){
        residentialCityRef.current.value = "";
      }
      setIsEditCommAddress(true);
    }else{
      setIsEditCommAddress(false);
    }
    if(!customerDetails?.customerDetails?.state){
      if(residentialStateRef.current){
      residentialStateRef.current.value = "";
      }
      setIsEditCommAddress(true); 
    }else{
      setIsEditCommAddress(false);
    }
  },[customerDetails?.customerDetails?.pan])



  // useEffect(() => {
  //   // if (customerDetails?.contact?.email) {
  //   //   setEmail(customerDetails?.customerDetails?.email);
  //   //   personalEmailRef.current.value = customerDetails?.customerDetails?.email;
  //   // }
  //   if (customerDetails?.communication_address) {
  //     residentialAddress1Ref.current.value = customerDetails
  //       ?.communication_address?.line_1
  //       ? customerDetails?.communication_address?.line_1 +
  //         " " +
  //         customerDetails?.communication_address?.street_name
  //       : "";
  //     residentialAddress2Ref.current.value = customerDetails
  //       ?.communication_address?.line_2
  //       ? customerDetails?.communication_address?.line_2
  //       : "";
  //     residentialPinRef.current.value = customerDetails?.communication_address
  //       ?.zip
  //       ? customerDetails?.communication_address?.zip
  //       : "";
  //     residentialCityRef.current.value = customerDetails?.communication_address
  //       ?.city
  //       ? customerDetails?.communication_address?.city
  //       : "";
  //     residentialStateRef.current.value = customerDetails?.communication_address
  //       ?.state
  //       ? customerDetails?.communication_address?.state
  //       : "";
  //   }
  //   console.log("CustomerDet New=>", customerDetails);
  // }, [customerDetails]);

  function switchToNextSlide(slide) {
    if (activeSlide > 0 && activeSlide !== 23) {
      const updatedHistory = [...history, slide ? slide : activeSlide + 1];
      setCookie(SLIDE_HISTORY, updatedHistory, {
        path: CookiePath,
        maxAge: COOKIES_MAX_AGE,
      });
      setHistory(updatedHistory);
    }

    if (slide) {
      setActiveSlide(slide);
    } else {
      setActiveSlide(activeSlide + 1);
    }
  }
  const goBack = () => {
    setLoading(false);
    // setIsEditProfEmail(false);
    // setIsEdit
    setError({
      error: false,
      type: "",
      message: "",
    });
    const slideHistory = [...history];

    slideHistory.pop();
    setHistory(slideHistory);

    const previousSlide = slideHistory[slideHistory.length - 1];
    setActiveSlide(previousSlide);
  };

  // * API to create a Timeline
  const createTimelineAPI = useMutation({
    mutationFn: (Payload) => createTimeline(Payload),
  });
  const handleCreateTimeline = (data) => {
    data.leadId = customerDetails.leadId;
    const headers = {
      apiKey,
      apiSecret,
    };
    const Payload = {
      data,
      headers,
    };
    createTimelineAPI.mutate(Payload);
  };

  // * Get SMS templates stage wise
  const getSmsTemplateStageWiseAPI = useMutation({
    mutationFn: (payload) => getSmsTemplateStageWise(payload),
    onSuccess: (data) => handleGetSmsTemplateSuccess(data),
  });
  const handleGetSmsTemplateSuccess = (data) => {
    if (data.data && Array.isArray(data.data) && data.data.length > 0) {
      setSmsTemplates(data.data);
    }
  };
  const handleGetSmsTemplateStageWise = () => {
    const headers = { apiKey, apiSecret };
    const params = { pipelineId: customerDetails.pipelineID };
    const payload = { headers, params };
    getSmsTemplateStageWiseAPI.mutate(payload);
  };

  // * Send stage wise individual SMS
  const sendStageWiseIndividualSmsAPI = useMutation({
    mutationFn: (payload) => sendIndividualStageWiseSMS(payload),
  });
  useEffect;
  const handleSendStageWiseIndividualSms = (templateId) => {
    const headers = { apiKey, apiSecret };
    const data = {
      customer_id: customerDetails.contactID,
      template_id: templateId,
      leadId: customerDetails.leadId,
    };
    const payload = { headers, data };
    sendStageWiseIndividualSmsAPI.mutate(payload);
  };

  const getPipelineSettingsAPI = useMutation({
    mutationFn: (payload) => getPipelineSettings(payload),
    onSuccess: (data) => handleGetPipelineSettingsSuccess(data),
    onError: () => finishJourney(),
    retry: false,
  });
  const handleGetPipelineSettingsSuccess = (data) => {
    const settings = data.data;
    setPipelineSettings(settings);
  };
  const handleGetPipelineSettings = (pipelineId) => {
    const payload = {
      pipelineId: pipelineId,
      headers: { apiKey, apiSecret },
    };
    getPipelineSettingsAPI.mutate(payload);
  };

  // *********** LMS ************

  // *** GET AUTH TOKEN
  const getAuthTokenAPI = useMutation({
    mutationFn: (payload) => getAuthToken(payload),
    onSuccess: (data) => handleGetAuthTokenSuccess(data),
    onError: () => finishJourney(),
  });

  const handleGetAuthTokenSuccess = (data) => {
    setItem("lmsAuthToken", data.data.access_token);

    handleCreateClient();
  };
  const handleGetAuthToken = () =>
    getAuthTokenAPI.mutate({ contact_id: customerDetails.contactID });

  // *** API TO CREATE THE CLIENT
  const createClientAPI = useMutation({
    mutationFn: (payload) => createClient(payload),
    onSuccess: (data) => handleCreateClientSuccess(data),
    onError: (data) => finishJourney(data),
  });
  const handleCreateClientSuccess = () => {
    handleCreateClientIdentifier();
    handleCreateAddressDetails();
    handleCreateBankAccount();
    handleCreateIdentifierDocuments();
    handleClientDocument();
  };
  const handleCreateClient = () => {
    const token = getItem("lmsAuthToken");
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const data = {
        contact_id: customerDetails.contactID,
      };
      const payload = { headers, data };
      createClientAPI.mutate(payload);
    } else {
      finishJourney();
    }
  };

  // *** API TO CREATE THE CLIENT IDENTIFIER
  const createClientIdentifierAPI = useMutation({
    mutationFn: (payload) => createClientIdentifier(payload),
    onError: () => finishJourney(),
  });
  const handleCreateClientIdentifier = () => {
    const token = getItem("lmsAuthToken");

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const data = {
        contact_id: customerDetails.contactID,
        document_type: "aadhaar",
      };
      const payload = { headers, data };
      createClientIdentifierAPI.mutate(payload);
    } else {
      finishJourney();
    }
  };

  // *** API TO CREATE ADDRESS DETAILS
  const createAddressDetailsAPI = useMutation({
    mutationFn: (payload) => createAddressDetails(payload),
    onError: () => finishJourney(),
  });
  const handleCreateAddressDetails = () => {
    const token = getItem("lmsAuthToken");

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const data = {
        contact_id: customerDetails.contactID,
      };
      const payload = { headers, data };
      createAddressDetailsAPI.mutate(payload);
    } else {
      finishJourney();
    }
  };

  // *** API TO CREATE BANK ACCOUNT
  const createBankAccountAPI = useMutation({
    mutationFn: (payload) => createBankAccount(payload),
    onError: () => finishJourney(),
  });
  const handleCreateBankAccount = () => {
    const token = getItem("lmsAuthToken");

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const data = {
        contact_id: customerDetails.contactID,
      };
      const payload = { headers, data };
      createBankAccountAPI.mutate(payload);
    } else {
      finishJourney();
    }
  };

  // *** API TO CREATE IDENTIFIER DOCUMENT
  const createIdentifierDocumentsAPI = useMutation({
    mutationFn: (payload) => createIdentifierDocuments(payload),
    onError: () => finishJourney(),
  });
  const handleCreateIdentifierDocuments = () => {
    const token = getItem("lmsAuthToken");

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const data = {
        contact_id: customerDetails.contactID,
        docType: "aadhaar",
      };
      const payload = { headers, data };
      createIdentifierDocumentsAPI.mutate(payload);
    } else {
      finishJourney();
    }
  };

  // *** API TO CREATE CLIENT DOCUMENT
  const createClientDocumentAPI = useMutation({
    mutationFn: (payload) => createClientDocument(payload),
    onSuccess: (data) => handleCreateClientDocumentSuccess(data),
    onError: () => finishJourney(),
  });
  const handleCreateClientDocumentSuccess = () => {
    handleCreateLoan();
  };
  const handleClientDocument = () => {
    const token = getItem("lmsAuthToken");

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const data = {
        contact_id: customerDetails.contactID,
        docType: "photograph",
      };
      const payload = { headers, data };
      createClientDocumentAPI.mutate(payload);
    } else {
      finishJourney();
    }
  };

  // ** API TO DISBURSE LOAN AMOUNT
  const disburseLoanAmountAPI = useMutation({
    mutationFn: (payload) => disburseLoanAmount(payload),
    onError: () => finishJourney(),
  });
  const handleDisburseLoanAmount = () => {
    const token = getItem("lmsAuthToken");
    const loanId = getItem("loanId");
    const maximumAmount = getItem("maximumAmount");

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const data = {
        loan_id: loanId,
        maximum_amount: maximumAmount,
        contact_id: customerDetails.contactID,
        lead_id: customerDetails.leadId,
      };
      const payload = { headers, data };
      disburseLoanAmountAPI.mutate(payload);
    } else {
      finishJourney();
    }
  };

  // ** API TO APPROVE LOAN AMOUNT
  const approveLoanAmountAPI = useMutation({
    mutationFn: (payload) => approveLoanAmount(payload),
    onSuccess: (data) => handleApproveLoanAmountSuccess(data),
    onError: () => finishJourney(),
  });
  // const handleApproveLoanAmountSuccess = () => {
  //   handleDisburseLoanAmount();
  // };
  const handleApproveLoanAmount = () => {
    const token = getItem("lmsAuthToken");
    const loanId = getItem("loanId");
    const maximumAmount = getItem("maximumAmount");

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const data = {
        loan_id: loanId,
        maximum_amount: maximumAmount,
        contact_id: customerDetails.contactID,
        lead_id: customerDetails.leadId,
      };
      const payload = { headers, data };
      approveLoanAmountAPI.mutate(payload);
    } else {
      finishJourney();
    }
  };

  // *** API TO CREATE LOAN API
  const createLoanAPI = useMutation({
    mutationFn: (payload) => createLoan(payload),
    onSuccess: (data) => handleCreateLoanSuccess(data),
    onError: () => finishJourney(),
  });
  const handleCreateLoan = () => {
    const token = getItem("lmsAuthToken");

    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const data = {
        contact_id: customerDetails.contactID,
        lead_id: customerDetails.leadId,
      };
      const payload = { headers, data };
      createLoanAPI.mutate(payload);
    } else {
      finishJourney();
    }
  };
  const handleCreateLoanSuccess = (data) => {
    const loanId = data.data.data.loanId;
    const maximumAmount = data.data.loanDetails.maximum_amount;

    setItem("loanId", loanId);
    setItem("maximumAmount", maximumAmount);

    handleApproveLoanAmount();
  };

  // * API to update lead stage (FOR CREDIT INSTITUTION ONLY)
  const updateLeadStageStatusAPI = useMutation({
    mutationFn: (payload) => updateLeadStageStatus(payload),
  });
  const handleUpdateLeadStageStatus = (stage, loanAmount) => {
    const data = {
      contact_id: customerDetails.contactID,
      pipeline_id: customerDetails.pipelineID,
      lead_id: `${customerDetails.leadId}`,
      bank_name: domainName,
      bank_image_name: domainName,
      lead_loggin_id: "",
      loan_amount: loanAmount,
      status: stage,
      message: `Lead status changed to ${stage}.`,
    };
    const headers = { apiKey, apiSecret };
    const payload = {
      headers,
      data,
    };
    updateLeadStageStatusAPI.mutate(payload);
  };

  const rejectLead = () => {
    // * Update lead stage to Reject
    let loanAmount =
      domainName === "maxemocapital" ? loanAmt : customerDetails.loanAmount;
    handleUpdateLeadStageStatus("Reject", loanAmount);

    // * Get Reject stage template ID
    const rejectSmsTemplate = smsTemplates.filter(
      (template) => template.stage_name === "Reject"
    );

    if (
      rejectSmsTemplate &&
      rejectSmsTemplate.length &&
      rejectSmsTemplate[0].sms_template_id
    ) {
      // * Send stage wise individual SMS
      handleSendStageWiseIndividualSms(rejectSmsTemplate[0].sms_template_id);

      // * Create a timeline
      const timelineData = {
        type: "SMS",
        message: "SMS trigged as lead status updated to Reject.",
      };
      handleCreateTimeline(timelineData);
    }
  };

  // * Get Lead Details API (Only for Meximo)
  const getLeadDetailsAPI = useMutation({
    mutationFn: (payload) => getLeadDetailsMaxemo(payload),
    onSuccess: (data) => handleGetLeadDetailsSuccess(data),
  });
  const [fullName, setFullName] = useState();
  const [eqaroEmail, setEqaroEmail] = useState();
  const handleGetLeadDetailsSuccess = (data) => {
    console.log("eqaro success api", data, domainName);
    if (domainName === "maxemocapital") {
      mobileNumberRef.current.value = data?.data?.Contact?.phone;
      setFullName(data?.data?.Contact?.full_name);
      // personalEmailRef.current.value = data?.data?.Contact?.email;
      console.log("from eqaro Email=>", data?.data?.Contact?.email);
      setEqaroEmail(data?.data?.Contact?.email);
      // fullnameRef.current.value = data?.data?.Contact?.full_name;
      setLoanAmt(data?.data?.your_desired_loan_amount);
      setLoanAmount(data?.data?.your_desired_loan_amount);
      setTenure(data?.data?.tenure_in_months);
      setCustomerDetails({
        ...customerDetails,
        userType: props?.userType,
        phone: data?.data?.Contact?.phone,
      });
      // fullnameRef.current.value = data?.data?.Contact?.full_name;
    }
  };
  useEffect(() => {
    if(personalEmailRef.current){
    personalEmailRef.current.value = (domainName === "maxemocapital"
    ? eqaroEmail
    : customerDetails?.customerDetails?.email)
    }
  },[isEditProfEmail])
  useEffect(() => {
    if(customerDetails?.customerDetails?.email && domainName !== "maxemocapital"){
      console.log('before cus det=>',customerDetails?.customerDetails?.email);
      setIsEditProfEmail(false);
    }else if(!customerDetails?.customerDetails?.email && domainName !== "maxemocapital"){
      console.log('before cus det else if=>',customerDetails?.customerDetails?.email);
      setIsEditProfEmail(true);
      if(personalEmailRef.current){
        personalEmailRef.current.value = "";
        }
    }
  },[customerDetails?.customerDetails?.pan]);
  const handleGetLeadDetailsForMeximo = () => {
    const headers = { apiKey, apiSecret };
    const payload = {
      headers,
      leadId: queryValues?.lead_id,
    };
    console.log("before call eqaro api");
    getLeadDetailsAPI.mutate(payload);
  };

  // * API to perform e-mandate
  const createMandateFormAPI = useMutation({
    mutationFn: (payload) => createMandateFrom(payload),
    onSuccess: (data) => handleCreateMandateFormSuccess(data),
    onError: (error) => handleCreateMandateFormError(error),
    retry: false,
  });
  const handleCreateMandateFormSuccess = (data) => {
    const response = data.data;

    var options = {
      environment: DIGIO_ENVIRONMENT,
      is_iframe: true,
      callback: eNachCallBack,
    };
    var digioInstance = new window.Digio(options);
    digioInstance.init();
    digioInstance.submit(
      response.mandate_id,
      response.customer_identifier,
      response.access_token.id
    );

    handleSendLeadDetailViaEmail(customerDetails?.loanDetails?.loanAmount);
  };
  const handleCreateMandateFormError = (error) => {
    console.log("Error : ", error);
    toast.error("Something went wrong, please try again.");
  };
  const handleCreateMandateForm = async () => {
    const { contactID, leadId, pipelineID } = customerDetails;
    let data = {
      leadId,
      contactId: contactID,
      pipelineId: pipelineID,
      maxLoanAmount: customerDetails?.loanDetails?.loanAmount,
    };

    // * To capture Latitude and Longitude
    const location = await getLocationPermission();
    if (location) {
      data = { ...data, ...location };
    }

    const payload = {
      data,
      headers: { apiKey, apiSecret },
    };
    createMandateFormAPI.mutate(payload);
  };
  const eNachCallBack = (response) => {
    console.log("Call Back response : ", response);

    // * If the mandate is cancelled.
    if (response.error_code && response.error_code === "TERMINATED") {
      switchToNextSlide(38);
      removeAllCookies(CookiePath);
    } else {
      // * Update lead stage to Disbursed
      let stage = "Disbursed";
      let loanAmnt = customerDetails?.loanDetails?.loanAmount;
      handleUpdateLeadStageStatus(stage, loanAmnt);

      // * Send stage wise individual SMS
      const disbursedSmsTemplate = smsTemplates.filter(
        (template) => template.stage_name === "Disbursed"
      );

      if (
        disbursedSmsTemplate &&
        disbursedSmsTemplate.length &&
        disbursedSmsTemplate[0].sms_template_id
      ) {
        handleSendStageWiseIndividualSms(
          disbursedSmsTemplate[0].sms_template_id
        );

        // * Create a timeline
        const timelineData = {
          type: "SMS",
          message: "SMS trigged as lead status updated to Disbursed.",
        };
        handleCreateTimeline(timelineData);
      }

      if (pipelineSettings.isLeadPushToLMS) {
        // * If LMS is turned on at CRM end then execute the LMS APIs.
        handleGetAuthToken();
      } else {
        // * If LMS is not checked then finish the Journey.
        switchToNextSlide(38);
        removeAllCookies(CookiePath);
      }
    }
  };

  // * API to get offer settings
  const getOfferSettingsAPI = useMutation({
    mutationFn: (payload) => getOfferSettings(payload),
    onSuccess: (data) => handleGetOfferSettingsSuccess(data),
    retry: false,
  });
  const handleGetOfferSettingsSuccess = (data) => {
    const loanAmountSettings = data.data.filter(
      (setting) => setting.setting_type === "loanAmount"
    );
    if (loanAmountSettings.length) {
      let minimumLoanAmount =
        loanAmountSettings[0].JourneyOfferSetingsDetail.min_value;
      let maximumLoanAmount =
        loanAmountSettings[0].JourneyOfferSetingsDetail.max_value;

      setLoanSettings((prev) => ({
        ...prev,
        minimumLoanAmount,
        maximumLoanAmount,
      }));
    }
  };
  const handleGetOfferSettings = (pipelineId) => {
    const payload = {
      pipeline_id: pipelineId,
      headers: {
        apiKey,
        apiSecret,
      },
    };
    getOfferSettingsAPI.mutate(payload);
  };

  // let url = useLocation().search;
  // let queryValues = queryString.parse(url);
  // const JourneyVersion = queryValues?.jrnyTyp;

  // * API to send lead details to domain owner via email
  const sendLeadDetailsViaEmailAPI = useMutation({
    mutationFn: (payload) => sendLeadDetailsToAdminViaMail(payload),
    onSuccess: () => sendLeadDetailsViaEmailSuccess(),
    onError: () =>
      activeSlide === 45 ? switchToNextSlide(46) : switchToNextSlide(38),
    retry: false,
  });
  const sendLeadDetailsViaEmailSuccess = () => {
    const slidePayload = {
      headers: { apiKey, apiSecret },
      leadId: customerDetails?.leadId,
      slideName: "Success : Journey Completed",
      slideIndex: activeSlide === 45 ? 46 : 38,
      slideIcon: "Success",
      totalSlides: TOTAL_SLIDES,
      journeyVersion: JourneyVersion,
    };
    handleUpdateLastVisitedSlide(slidePayload);
  };

  const handleSendLeadDetailViaEmail = (loanAmount) => {
    const payload = {
      data: {
        leadId: customerDetails.leadId,
        pipelineId: customerDetails.pipelineID,
        loanAmount: loanAmount * 2,
      },
      headers: { apiKey, apiSecret },
    };
    sendLeadDetailsViaEmailAPI.mutate(payload);
  };

  // * API to get Pre-Approved loan offers
  const getPreApprovedLoanOffers = useMutation({
    mutationKey: ["preApprovedOffers"],
    mutationFn: (Payload) => getPreApprovedOffers(Payload),
    onSuccess: (data) => handlePreApprovedOfferSuccess(data),
    enabled: customerDetails?.leadId ? true : false,
    refetchOnWindowFocus: false,
  });
  const handlePreApprovedOfferSuccess = (data) => {
    let approvedLoans = [],
      loggedLoans = [];
    data?.data.forEach((offer) => {
      if (offer.log_status === "Approved") {
        approvedLoans.push(offer);
      }
      if (offer.log_status === "Logged") {
        loggedLoans.push(offer);
      }
    });

    if (approvedLoans.length) {
      setLoanOffers(approvedLoans);
      setCustomerDetailsCookies({ loanOffers: approvedLoans });
      setCustomerDetails({ ...customerDetails, loanOffers: approvedLoans });
    } else {
      setLoanOffers(loggedLoans);
      setCustomerDetailsCookies({ loanOffers: loggedLoans });
      setCustomerDetails({ ...customerDetails, loanOffers: loggedLoans });
    }
  };

  // * API to push realtime leads
  const pushRealTimeLeadAPI = useMutation({
    mutationKey: ["pushRealTimeLead"],
    mutationFn: (Payload) => pushRealTimeLead(Payload),
    onSuccess: (data) => handlPushRealTimeLeadSuccess(data),
    retry: false,
  });
  const handlPushRealTimeLeadSuccess = () => {
    //  * Check Pre-Approved loan offers for customer
    if (customerDetails.userType === "LSP") {
      const PreApprovedAPIPayload = {
        headers: { apiKey, apiSecret },
        lead_id: customerDetails.leadId,
      };
      getPreApprovedLoanOffers.mutate(PreApprovedAPIPayload);
    }
  };

  // * API to update last visited Journey slide
  const updateJourneyLastVisitedSlideAPI = useMutation({
    mutationKey: ["journeyLastVisit"],
    mutationFn: (Payload) => updateJourneyLastVisitedSlide(Payload),
    retry: false,
  });

  const handleUpdateLastVisitedSlide = (payload) =>
    updateJourneyLastVisitedSlideAPI.mutate(payload);

  // * Function to handle set cookie
  function setCustomerDetailsCookies(cookieValuesObject) {
    setCookie(
      CUSTOMER_DETAILS,
      JSON.stringify({ ...customerDetails, ...cookieValuesObject }),
      { path: CookiePath, maxAge: COOKIES_MAX_AGE }
    );
  }
  function validateMobileNumber(mobileNumber, errorType, mobileType) {
    if (mobileNumber === "") {
      setError({
        error: true,
        type: errorType,
        message: Errors.NULL_PHONE_ERROR,
      });
      return false;
    }
    if (
      mobileType === "coApplicant" &&
      mobileNumber === customerDetails.mobileNumber
    ) {
      setError({
        error: true,
        type: errorType,
        message: Errors.DUPLICATE_PHONE_ERROR,
      });
      return false;
    }
    // if (isNaN(mobileNumber)) {
    // if (/^[1-9]\d{9}$/.test(mobileNumber)) {
    if (isNaN(mobileNumber) || mobileNumber.includes(" ")) {
      setError({
        error: true,
        type: errorType,
        message: Errors.INVALID_DIGITS_PHONE_ERROR,
      });
      mobileNumberRef.current.value = "";
      return false;
    }

    if (mobileNumber.charAt(0) !== "6") {
      if (mobileNumber.charAt(0) !== "7") {
        if (mobileNumber.charAt(0) !== "8") {
          if (mobileNumber.charAt(0) !== "9") {
            setError({
              error: true,
              type: errorType,
              message: Errors.INVALID_PHONE_FORMAT_ERROR,
            });
            mobileNumberRef.current.value = "";
            return false;
          }
        }
      }
    }
    if (mobileNumber.length > 10 || mobileNumber.length < 10) {
      setError({
        error: true,
        type: errorType,
        message: Errors.INVALID_LENGTH_PHONE_ERROR,
      });
      return false;
    }
    if (mobileNumber.length === 10) {
      setError({
        error: false,
        type: "",
        message: "",
      });
    }
    return true;
  }
  function validatePAN(PAN) {
    if (PAN !== "") {
      if (PAN.length < 10 || PAN.length > 10) {
        return false;
      }
      if (!/[0-9]/.test(PAN) || !/[a-zA-Z]/.test(PAN)) {
        return false;
      }
      if (PAN.includes(" ")) {
        return false;
      }
      let PANregex = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
      // if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(PAN)) {
      if (PANregex.test(PAN)) {
        return false;
      }
      if (PAN.charAt(3) === "P" || PAN.charAt(3) === "p") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  // * Function to handle Full Name
  function handleFullName(fullName, errorType) {
    if (fullName === "") {
      setError({
        error: true,
        type: errorType,
        message: Errors.FULLNAME_ERROR,
      });
      return false;
    }
    if (/[0-9]/.test(fullName[0])) {
      setError({
        error: true,
        type: errorType,
        message: Errors.FULLNAME_DIGIT_ERROR,
      });
      return false;
    }
    if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(fullName)) {
      setError({
        error: true,
        type: errorType,
        message: Errors.FULLNAME_SPECIAL_CHARACTER_ERROR,
      });
      return false;
    }
    if (error.error && error.type === errorType) {
      setError({
        error: false,
        type: "",
        message: "",
      });
      return true;
    }
    return true;
  }
  // * Funciton to handle DOB
  function handleDOB(DOB, errorType) {
    if (DOB === "") {
      setError({
        error: true,
        type: errorType,
        message: Errors.DOB_ERROR,
      });
      return false;
    }
    const SeperateDOB = DOB.split("-");
    const currentYear = new Date().getFullYear();
    if (
      currentYear === parseInt(SeperateDOB[0]) ||
      currentYear < parseInt(SeperateDOB[0])
    ) {
      setError({
        error: true,
        type: errorType,
        message: Errors.SAME_DATE_ERROR,
      });
      return false;
    }
    const Age = calculateAge(
      new Date(SeperateDOB[0], SeperateDOB[1], SeperateDOB[2])
    );
    if (Age < 18 && errorType !== Errors.CO_APPLICANT_DOB_ERROR_TYPE) {
      setError({
        error: true,
        type: errorType,
        message: Errors.DOB_NOT_ELIGIBLE_ERROR,
      });
      return false;
    }
    if (error.error && error.type === errorType) {
      setError({
        error: false,
        type: "",
        message: "",
      });
      return true;
    }
    return true;
  }
  // * Funciton to handle PAN
  function handlePAN(PAN, errorType) {
    PAN = trimString(PAN);
    if (PAN === "") {
      setError({
        error: true,
        type: errorType,
        message: Errors.PAN_ERROR,
      });
      return false;
    }
    if (PAN.toLowerCase() === customerDetails?.pan?.toLowerCase()) {
      setError({
        error: true,
        type: errorType,
        message: Errors.CO_APPLICANT_DUPLICATE_PAN_ERROR,
      });
      return false;
    }
    if (!validatePAN(PAN)) {
      setError({
        error: true,
        type: errorType,
        message: Errors.INVALID_PAN_ERROR,
      });
      return false;
    }
    if (error.error && error.type === errorType) {
      setError({
        error: false,
        type: "",
        message: "",
      });
      return true;
    }
    return true;
  }
  // * Function to handle individual OTP input field
  function handleOtp(otpKey, reference) {
    if (otpKey === "") {
      setError({
        error: true,
        type: Errors.OTP_ERROR_TYPE,
        message: Errors.NULL_OTP_ERROR,
      });
      reference.current.value = "";
      return false;
    }
    if (isNaN(otpKey)) {
      setError({
        error: true,
        type: Errors.OTP_ERROR_TYPE,
        message: Errors.INVALID_OTP_DIGIT_ERROR,
      });
      reference.current.value = "";
      return false;
    }
    if (error.error && error.type === Errors.OTP_ERROR_TYPE) {
      setError({
        error: false,
        type: "",
        message: "",
      });
    }
    if (reference.current.nextSibling) reference.current.nextSibling.focus();
    return true;
  }
  // * Function to get OTP
  async function sendOTP() {
    let mobileNumber = mobileNumberRef.current.value;

    // window.webengage.track("Enter Mobile Number", {
    //   "Value Entered": mobileNumber,
    // });
    window.webengage.user.setAttribute("we_phone", mobileNumber);
    window.webengage.user.login(mobileNumber);

    if (validateMobileNumber(mobileNumber, Errors.MOBILE_NUMBER_ERROR_TYPE)) {
      setLoading(true);

      const getOtpData = {
        phone: mobileNumber,
        countryCode: "+91",
        ip: ipResponse?.IPv4,
      };
      const Payload = {
        getOtpData: getOtpData,
        apiKey: apiKey,
        apiSecret: apiSecret,
      };
      return new Promise((resolve, reject) => {
        props.getOtp(Payload, resolve, reject);
      })
        .then((response) => {
          // * Saving details to access throughout the Journey.
          setCustomerDetails({
            ...customerDetails,
            mobileNumber,
            contact: response.data.contact,
            employment_type: response.data.contact.employment_type,
            appID: response.data.appID,
            contactID: response.data.contact.id,
            pipelineID: response.data.leadData.pipeline_id,
            applicationId: response.data.leadData.application_id,
            loanAmount: response.data.leadData.your_desired_loan_amount,
          });

          console.log("contactId=>", response.data.contact?.id);
          let jsonData = {
            contact_id: response.data.contact?.id,
          };
          const headers = { apiKey, apiSecret };
          const payload = {
            headers,
            jsonData,
          };
          getUpdatedCustomerDetailsAPI.mutate(payload);

          // * Setting Cookies
          const cookieValues = {
            mobileNumber,
            appID: response.data?.appID,
            contactID: response.data?.contact.id,
          };
          setCustomerDetailsCookies(cookieValues);
          setCookie(APP_ID, response.data?.appID, {
            path: CookiePath,
            maxAge: COOKIES_MAX_AGE,
          });
          setCookie(CONTACT_ID, response.data?.contact.id, {
            path: CookiePath,
            maxAge: COOKIES_MAX_AGE,
          });
          setCookie(PIPELINE_ID, response.data.leadData.pipeline_id, {
            path: CookiePath,
            maxAge: COOKIES_MAX_AGE,
          });

          // * Fetch offer settings
          handleGetOfferSettings(response.data.leadData.pipeline_id);
          // * Fetch pipeline settings
          handleGetPipelineSettings(response.data.leadData.pipeline_id);

          // * Changing the slide
          console.log('before active slide=>',activeSlide)
          switchToNextSlide(activeSlide + 1);

          setLoading(false);
        })
        .catch((error) => {
          console.log("EROR : ", error);
          otpRefKey1?.current?.focus();
          setLoading(false);
        });
    }
  }

  // * API to verify the OTP
  const verifyLeadOtpAPI = useMutation({
    mutationFn: (payload) => verifyLeadOtp(payload),
    onSuccess: (data) => handleVerifyLeadOTPSuccess(data),
    onError: (error) => handleVerifyLeadOTPError(error),
  });
  const handleVerifyLeadOTPSuccess = (data) => {
    if (data.status) {
      const { token, leadId } = data.data;
      const headers = {
        apiKey,
        apiSecret,
      };
      let payload = {
        pipelineId: customerDetails?.pipelineID,
        contactId: customerDetails?.contactID,

        headers: headers,
      };
      if (JourneyVersion) {
        payload.journeyVersion = JourneyVersion;
      }
      getLastSlideDetailsAPI.mutate(payload);

      // * Fetch SMS templates stage wise
      handleGetSmsTemplateStageWise();

      setCustomerDetails({
        ...customerDetails,
        authToken: token,
        leadId: leadId,
      });
      setCookie(JOURNEY_AUTH_TOKEN, token, {
        path: CookiePath,
        maxAge: COOKIES_MAX_AGE,
      });
      setCookie(LEAD_ID, leadId, {
        path: CookiePath,
        maxAge: COOKIES_MAX_AGE,
      });

      setLoading(false);
    }
  };
  const handleVerifyLeadOTPError = (error) => {
    if (error?.response?.status === 403) {
      setError({
        error: true,
        type: Errors.OTP_ERROR_TYPE,
        message: Errors.INVALID_OTP_ERROR,
      });
    }
    otpRefKey1.current.value = "";
    otpRefKey2.current.value = "";
    otpRefKey3.current.value = "";
    otpRefKey4.current.value = "";
  };

  // * Function to handle verifyOTP functionality
  async function verifyOTP() {
    const otpKey1 = otpRefKey1.current.value;
    const otpKey2 = otpRefKey2.current.value;
    const otpKey3 = otpRefKey3.current.value;
    const otpKey4 = otpRefKey4.current.value;

    let otpErrorObj = error;

    if (otpKey1 === "") {
      otpErrorObj = {
        type: Errors.OTP_ERROR_TYPE,
        error: true,
        message: Errors.OTP_ERROR,
      };
    }
    if (otpKey2 === "") {
      otpErrorObj = {
        type: Errors.OTP_ERROR_TYPE,
        error: true,
        message: Errors.OTP_ERROR,
      };
    }
    if (otpKey3 === "") {
      otpErrorObj = {
        type: Errors.OTP_ERROR_TYPE,
        error: true,
        message: Errors.OTP_ERROR,
      };
    }
    if (otpKey4 === "") {
      otpErrorObj = {
        type: Errors.OTP_ERROR_TYPE,
        error: true,
        message: Errors.OTP_ERROR,
      };
    }

    // * If all the OTP fields are filled, not blank
    if (!otpErrorObj.error && otpErrorObj.message !== "otpError") {
      setLoading(true);
      const otp = otpKey1 + otpKey2 + otpKey3 + otpKey4;
      const mobileNumber = mobileNumberRef.current.value;

      let verifyOtpData = {
        phone: mobileNumber,
        // otp_code: otp,
        otp: otp,
        // created_for: "16",
      };

      // * To capture Latitude and Longitude
      const location = await getLocationPermission();
      if (location) {
        verifyOtpData = { ...verifyOtpData, ...location };
      }
      const headerData = {
        apiKey: apiKey,
        apiSecret: apiSecret,
      };
      const payload = {
        data: verifyOtpData,
        headers: headerData,
      };

      verifyLeadOtpAPI.mutate(payload);
    } else {
      setError(otpErrorObj);
    }
  }
  // * Function to handle the Email
  function handleEmail(email, errorType) {
    const personalEmail = isEditProfEmail
      ? personalEmailRef?.current?.value
      : customerDetails?.customerDetails?.email;
    let professionalEmail = "";
    if (domainName !== "maxemocapital") {
      professionalEmail = professionalEmailRef.current.value;
    }

    if (!email && errorType !== Errors.PROFESSIONAL_EMAIL_ERROR_TYPE) {
      setError({
        error: true,
        type: errorType,
        message: Errors.NULL_EMAIL_ERROR,
      });
      return false;
    }

    if (
      errorType === Errors.CO_APPLICANT_EMAIL_ERROR_TYPE &&
      email === customerDetails.personalEmail
    ) {
      setError({
        error: true,
        type: errorType,
        message: Errors.CO_APPLICANT_DUPICATE_EMAIL_ERROR,
      });
      return false;
    }

    if (email.length && !isEmail(email)) {
      setError({
        error: true,
        type: errorType,
        message: Errors.INVALID_EMAIL_ERROR,
      });
      return false;
    }

    if (error.error && error.type === errorType) {
      setError({
        error: false,
        type: "",
        message: "",
      });
    }

    if (
      personalEmail &&
      professionalEmail &&
      personalEmail === professionalEmail
    ) {
      setError({
        error: true,
        type: Errors.PROFESSIONAL_EMAIL_ERROR_TYPE,
        message: Errors.SAME_EMAIL_ERROR,
      });
      return false;
    } else if (
      error.error &&
      error.errorType === Errors.PROFESSIONAL_EMAIL_ERROR_TYPE
    ) {
      setError({
        error: false,
        type: "",
        message: "",
      });
    }
    return true;
  }
  // * Function to handle monthly income
  function handleMonthlyIncome() {
    let monthlyIncome = monthlyIncomeRef.current.value;
    if (monthlyIncome === "") {
      setError({
        error: true,
        type: Errors.MONTHLY_INCOME_ERROR_TYPE,
        message: Errors.NULL_MONTHLY_INCOME_ERROR,
      });
      return false;
    }
    if (monthlyIncome.charAt(0) === "0") {
      setError({
        error: true,
        type: Errors.MONTHLY_INCOME_ERROR_TYPE,
        message: Errors.NUMBER_STARTING_FROM_ZERO,
      });
      return false;
    }
    if (isNaN(monthlyIncome)) {
      setError({
        error: true,
        type: Errors.MONTHLY_INCOME_ERROR_TYPE,
        message: Errors.INVALID_FORMAT_MONTHLY_INCOME_ERROR,
      });
      monthlyIncomeRef.current.value = "";
      return false;
    }
    if (monthlyIncome <= 0) {
      setError({
        error: true,
        type: Errors.MONTHLY_INCOME_ERROR_TYPE,
        message: Errors.ZERO_MONTHLY_INCOME_ERROR,
      });
      return false;
    }
    if (monthlyIncome > 900000) {
      setError({
        error: true,
        type: Errors.MONTHLY_INCOME_ERROR_TYPE,
        message: Errors.HIGH_MONTHLY_INCOME_ERROR,
      });
      return false;
    }
    if (error.error) {
      setError({
        error: false,
        type: "",
        message: "",
      });
    }
    return true;
  }

  // * API to update lead data
  const updateLeadDataAPI = useMutation({
    mutationFn: (payload) => updateLeadData(payload),
    onSuccess: (data) => handleUpdateLeadDataSuccess(data),
    onError: (error) => handleUpdateLeadDataError(error),
  });
  const handleUpdateLeadDataSuccess = (data) => {
    console.log("bfore update lead status");
    setLoading(false);
    if (userData.company_name && employmentType === "Salaried") {
      if (JourneyVersion === "J2") {
        setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 17, {
          path: CookiePath,
          maxAge: COOKIES_MAX_AGE,
        });
      } else if (!JourneyVersion) {
        if (loanOffers.length) {
          setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 11, {
            path: CookiePath,
            maxAge: COOKIES_MAX_AGE,
          });
        } else {
          setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 17, {
            path: CookiePath,
            maxAge: COOKIES_MAX_AGE,
          });
        }
      }
    } else {
      if (activeSlide !== 13) {
        setCookie(LAST_ACTIVE_JOURNEY_SLIDE, activeSlide + 1, {
          path: CookiePath,
          maxAge: COOKIES_MAX_AGE,
        });
      }
    }

    // * Journey Last Visited slide
    const leadId = customerDetails.leadId;
    if (leadId && slidePayload) {
      slidePayload.leadId = leadId;
      slidePayload.headers = headers;
      handleUpdateLastVisitedSlide(slidePayload);
    }
  };
  const handleUpdateLeadDataError = (error) => {
    setLoading(false);
    console.log("Error : ", error);
    // toast.error("Something went wrong, while updating the data.");
  };

  // * Function to update data in journey
  function updateJourneyData(userData, slidePayload) {
    console.log("inside update journey data");
    setLoading(true);
    const headers = {
      apiKey: apiKey,
      apiSecret: apiSecret,
      Authorization: `Bearer ${customerDetails.authToken}`,
    };
    // const payload = {
    //   headers,
    //   data:userData,
    //   params:{appId: customerDetails.appID},
    // };

    const Payload = {
      headers,
      body: {
        app_id: customerDetails.appID,
        ...userData,
      },
    };
    const payload = {
      headers,
      data: userData,
      params: { appId: customerDetails.appID },
    };
    console.log("Payload=>", payload);
    updateLeadDataAPI.mutate(payload);
    console.log("before set loading =>");
    return new Promise((resolve, reject) => {
      props.addJourney(Payload, resolve, reject);
    })
      .then((data) => {
        console.log("before set loading =>");
        if (userData.company_name && employmentType === "Salaried") {
          if (JourneyVersion === "J2") {
            setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 17, {
              path: CookiePath,
              maxAge: COOKIES_MAX_AGE,
            });
          } else if (!JourneyVersion) {
            if (loanOffers.length) {
              setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 11, {
                path: CookiePath,
                maxAge: COOKIES_MAX_AGE,
              });
            } else {
              setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 17, {
                path: CookiePath,
                maxAge: COOKIES_MAX_AGE,
              });
            }
          }
        } else {
          if (activeSlide !== 13) {
            setCookie(LAST_ACTIVE_JOURNEY_SLIDE, activeSlide + 1, {
              path: CookiePath,
              maxAge: COOKIES_MAX_AGE,
            });
          }
        }

        // setLoading(false);
        // * Journey Last Visited slide
        const leadId = customerDetails.leadId;
        if (leadId && slidePayload) {
          slidePayload.leadId = leadId;
          slidePayload.headers = headers;
          handleUpdateLastVisitedSlide(slidePayload);
        }
        return data;
      })
      .catch((err) => {
        return false;
      });
  }
  // * Function to handle Date of Incorporation
  function handleDateOfIncorporation(dateOfIncorporation) {
    if (dateOfIncorporation === "") {
      setError({
        error: true,
        type: Errors.INCORPORATION_DATE_ERROR_TYPE,
        message: Errors.INCORPORATION_DATE_ERROR,
      });
      return false;
    }

    const currentFullDate = new Date();
    dateOfIncorporation = new Date(dateOfIncorporation);

    if (currentFullDate < dateOfIncorporation) {
      setError({
        error: true,
        type: Errors.INCORPORATION_DATE_ERROR_TYPE,
        message: Errors.SAME_INCORPORATION_ERROR,
      });
      return false;
    }
    if (currentFullDate.getFullYear() === dateOfIncorporation.getFullYear()) {
      if (
        dateOfIncorporation.getMonth() + 1 >= currentFullDate.getMonth() + 1 &&
        dateOfIncorporation.getDate() >= currentFullDate.getDate()
      ) {
        setError({
          error: true,
          type: Errors.INCORPORATION_DATE_ERROR_TYPE,
          message: Errors.SAME_INCORPORATION_ERROR,
        });
        return false;
      }
    }

    if (error.error) {
      setError({
        error: false,
        type: "",
        message: "",
      });
    }
    return true;
  }
  // * Function to add Email
  async function addEmail() {
    const personalEmail =
      domainName === "maxemocapital"
        ? eqaroEmail
        : isEditProfEmail
        ? personalEmailRef?.current?.value
        : customerDetails?.customerDetails?.email;
    let professionalEmail = "";
    if (domainName !== "maxemocapital") {
      professionalEmail = professionalEmailRef.current.value;
    }
    console.log('personal email=>'.personalEmail)
    const isPersonalEmailValid = handleEmail(
      personalEmail,
      Errors.PERSONAL_EMAIL_ERROR_TYPE
    );
    let isProfessionalEmailValid = true;

    if (isProfessionalEmailValid) {
      isProfessionalEmailValid = handleEmail(
        professionalEmail,
        Errors.PROFESSIONAL_EMAIL_ERROR_TYPE
      );
    }

    if (isProfessionalEmailValid && isPersonalEmailValid) {
      setCustomerDetails({
        ...customerDetails,
        personalEmail,
        professionalEmail,
      });
      let customerDetailsObj = {
        personalEmail,
      };
      if (professionalEmail) {
        customerDetailsObj.professionalEmail = professionalEmail;
      }
      setCustomerDetailsCookies(customerDetailsObj);
      setLoading(true);

      let payload = {
        email: personalEmail,
      };
      if (professionalEmail) {
        payload.office_email = professionalEmail;
      }
      const slidePayload = {
        slideName: "Email",
        slideIndex: 7,
        slideIcon: "Email",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      // * CALL API HERE
      const response = await updateJourneyData(payload, slidePayload);
      if (response) {
        if (JourneyVersion === "J3") {
          switchToNextSlide(11);
        } else if (domainName === "maxemocapital") {
          switchToNextSlide(11);
        } else {
          switchToNextSlide(activeSlide + 1);
        }
      }
      setLoading(false);
    }
  }
  // * Function to validate Dropdown input values
  function validateDropdownInputs(value, errorType, errorMessage) {
    if (!value) {
      setError({
        error: true,
        type: errorType,
        message: errorMessage,
      });
      return false;
    } else {
      if (error.error) {
        setError({
          error: false,
          type: "",
          message: "",
        });
      }
      return true;
    }
  }
  // * Function to add Educational quslification
  // async function addEducationalQualification() {
  //   const eduQualification = educationalQualificationRef.current.value;

  //   if (
  //     validateDropdownInputs(
  //       eduQualification,
  //       Errors.EDUCATIONAL_QUALIFICATION_ERROR_TYPE,
  //       Errors.NULL_EDUCATIONAL_QUALIFICATION_ERROR
  //     )
  //   ) {
  //     setLoading(true);
  //     setCustomerDetails({
  //       ...customerDetails,
  //       educationalQualification: eduQualification,
  //     });
  //     setCustomerDetailsCookies({ educationalQualification: eduQualification });

  //     const data = {
  //       educational_qualification: eduQualification,
  //     };
  //     const slidePayload = {
  //       slideName: "Educational Qualification",
  //       slideIndex: 8,
  //       slideIcon: "EducationalQualification",
  //       totalSlides: TOTAL_SLIDES,
  //     };
  //     // * CALL API HERE
  //     const response = await updateJourneyData(data, slidePayload);
  //     if (response) {
  //       switchToNextSlide(activeSlide + 1);
  //     }
  //     setLoading(false);
  //   }
  // }
  // * Function to add all Selector fields data (Gender, Marital Status, Employement Status)
  function addSelectorsData(
    fieldName,
    fieldValue,
    fieldErrorType,
    slidePayload
  ) {
    setIsEditEmail(true);
    if (fieldValue === "" || fieldValue === null) {
      setError({
        error: true,
        type: fieldErrorType,
        message: "",
      });
    } else {
      setCustomerDetails({ ...customerDetails, [fieldName]: fieldValue });
      setCustomerDetailsCookies({ [fieldName]: fieldValue });

      // * Call API here
      const data = {
        [fieldName]:
          fieldName !== "has_coApplicant"
            ? fieldValue
            : fieldValue === "Yes Add Co-Applicant"
            ? true
            : false,
      };
      const response = updateJourneyData(data, slidePayload);
      if (response) {
        switch (fieldName) {
          case "gender":
            window.webengage.track("Gender", {
              "Value Entered": fieldValue.toLowerCase(),
            });
            window.webengage.user.setAttribute("we_gender", "male");
            break;
          case "purposeOfLoan":
            window.webengage.track("PurposeOfLoan", {
              "Value Entered": fieldValue.toLowerCase(),
            });
          case "marital_status":
            window.webengage.track("Marital Status", {
              "Value Entered": fieldValue,
            });

            break;

          case "employment_type":
            setEmploymentType(fieldValue);
            setCookie(EMPLOYEMENT_TYPE, fieldValue, {
              path: CookiePath,
              maxAge: COOKIES_MAX_AGE,
            });
            window.webengage.track("Employment Type", {
              "Type Selected": fieldValue,
            });
            break;
          case "has_coApplicant":
            setCookie(HAS_CO_APPLICANT, fieldValue, {
              path: CookiePath,
              maxAge: COOKIES_MAX_AGE,
            });
            break;
          default:
            break;
        }
        if (fieldName === "has_coApplicant") {
          if (fieldValue === "Yes Add Co-Applicant") {
            switchToNextSlide(activeSlide + 1);
            setCookie(LAST_ACTIVE_JOURNEY_SLIDE, activeSlide + 1, {
              path: CookiePath,
              maxAge: COOKIES_MAX_AGE,
            });
          } else {
            if (customerDetails.userType === "LSP" && loanOffers.length) {
              switchToNextSlide(37);
              // * Do not save Pre-Approved Loan Offer screen slide as cookie.
            } else {
              setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 18, {
                path: CookiePath,
                maxAge: COOKIES_MAX_AGE,
              });
              switchToNextSlide(19);
            }
          }
        } else if (fieldName === "gender" && domainName === "maxemocapital") {
          switchToNextSlide(6); // * Redirect to Marital Status slide.
        } else if (
          fieldName === "educational_qualification" &&
          domainName === "maxemocapital"
        ) {
          switchToNextSlide(10);
        } else if (
          fieldName === "employment_type" &&
          domainName === "maxemocapital"
        ) {
          switchToNextSlide(11); // * Redirect to Communication Address slide.
        } else if (
          fieldName === "educational_qualification" &&
          customerDetails?.customerDetails?.customer_type === "Proprietorship"
        ) {
          console.log("if type is educ qual and Proprietorship");
          // let gstins =
          //   customerDetails?.customerDetails &&
          //   customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
          //     ?.gstins &&
          //   customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
          //     ?.gstins?.length > 0
          //     ? customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
          //         ?.gstins
          //     : [];
          let gstinsArr = customerDetails?.customerDetails
            ?.UdyamRegistrationDetails[0]?.gstins
            ? JSON.parse(
                customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
                  ?.gstins
              )
            : [];
          let gstins = gstinsArr?.length > 0 ? gstinsArr : [];
          if (gstinsArr.length === 0) {
            switchToNextSlide(activeSlide + 2);
          } else {
            switchToNextSlide(activeSlide + 1);
          }
        } else {
          switchToNextSlide(activeSlide + 1);
        }
      }
    }
  }
  // * Function to add monthly income
  async function addMonthlyIncome() {
    setIsEditAddress(
      customerDetails?.communication_address?.line_1 ? true : false
    );
    const salaryType = salaryTypeRef.current?.value;
    if (!handleMonthlyIncome()) {
      return;
    }

    if (customerDetails.employment_type !== "Self-Employed") {
      if (
        !validateDropdownInputs(
          salaryType,
          Errors.SALARY_CREDIT_TYPE_ERROR_TYPE,
          Errors.NULL_SALARY_CREDIT_TYPE_ERROR
        )
      ) {
        return;
      }
    }

    setLoading(true);
    const monthlyIncome = parseInt(monthlyIncomeRef.current.value);
    window.webengage.track("Net Monthly Income", {
      "Value Entered": monthlyIncome,
    });
    let data = {
      your_net_monthly_income: monthlyIncome,
    };

    if (customerDetails.employment_type !== "Self-Employed") {
      data.salary_type = salaryType;
    }

    let details = {
      monthlyIncome: data.your_net_monthly_income,
    };
    if (customerDetails.employment_type !== "Self-Employed") {
      details.salaryType = salaryType;
    }
    setCustomerDetails({
      ...customerDetails,
      ...details,
    });
    setCustomerDetailsCookies(details);

    const slidePayload = {
      slideName: "Monthly Income",
      slideIndex: 11,
      slideIcon: "MonthlyIncome",
      totalSlides: TOTAL_SLIDES,
      journeyVersion: JourneyVersion,
    };
    const response = await updateJourneyData(data, slidePayload);
    if (response) {
      switchToNextSlide(activeSlide + 1);
      // residentialAddress1Ref.current.focus();
    }
    setLoading(false);
  }
  // * Function to validate Shop Name
  function validateShopName(shopName) {
    if (!shopName) {
      setError({
        error: true,
        type: Errors.SHOP_NAME_ERROR_TYPE,
        message: Errors.NULL_SHOP_NAME_ERROR,
      });
      return false;
    }
    if (error.error) {
      setError({
        error: false,
        type: "",
        message: "",
      });
      return true;
    }
  }
  // * Function to validate Address details
  function validateAddress(fieldValue, ERROR_TYPE, ERROR_MESSAGE) {
    if (fieldValue === "") {
      setError({
        error: true,
        type: ERROR_TYPE,
        message: ERROR_MESSAGE,
      });
      return false;
    }
    // if (/[`!@#$%^&*()_+\=\[\]{};':"\\|<>\/?~]/.test(fieldValue)) {
    //   setError({
    //     error: true,
    //     type: ERROR_TYPE,
    //     message: Errors.INVALID_SPECIAL_ADDRESS_ERROR,
    //   });
    //   return false;
    // }
    if (!/[a-zA-Z]/.test(fieldValue)) {
      setError({
        error: true,
        type: ERROR_TYPE,
        message: Errors.INVALID_NUMBER_ADDRESS_ERROR,
      });
      return false;
    }
    if (error.error) {
      setError({
        error: false,
        type: "",
        message: "",
      });
      return true;
    }
    return true;
  }
  // * Function to handle City and State
  function validateCityAndState(fieldValue, ERROR_TYPE) {
    if (fieldValue === "") {
      setError({
        error: true,
        type: ERROR_TYPE,
        message: Errors.NULL_CITY_STATE_ERROR,
      });
      return false;
    }
    if (/[0-9]/.test(fieldValue && fieldValue?.length>0 ? fieldValue[0] : "")) {
      setError({
        error: true,
        type: ERROR_TYPE,
        message: Errors.INVALID_CITY_STATE_DIGIT_ERROR,
      });
      return false;
    }
    if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(fieldValue)) {
      setError({
        error: true,
        type: ERROR_TYPE,
        message: Errors.INVALID_CITY_STATE_DIGIT_ERROR,
      });
      return false;
    }
    if (error.error) {
      setError({
        error: false,
        type: "",
        message: "",
      });
      return true;
    }
    return true;
  }
  // * Function to handle PinCode
  function validatePinCode(PIN, ERROR_TYPE, REFERENCE, CITY_REF, STATE_REF) {
    if (PIN === "") {
      setError({
        error: true,
        type: ERROR_TYPE,
        message: Errors.RESIDENTIAL_PIN_ERROR,
      });
      return false;
    }
    if (PIN.charAt(0) === "0") {
      setError({
        error: true,
        type: ERROR_TYPE,
        message: Errors.NUMBER_STARTING_FROM_ZERO,
      });
      return false;
    }
    if (isNaN(PIN)) {
      setError({
        error: true,
        type: ERROR_TYPE,
        message: Errors.INVALID_DIGITS_PINCODE,
      });
      REFERENCE.current.value = "";
      return false;
    }
    if (PIN.length > 6 || PIN.length < 6) {
      setError({
        error: true,
        type: ERROR_TYPE,
        message: Errors.PINCODE_LENGTH_ERROR,
      });
      return false;
    }
    const isPinCodeExist = PinCodeDirectory.lookup(PIN);
    if (isPinCodeExist.length && CITY_REF && STATE_REF) {
      let Pincode = isPinCodeExist[0];
      if(STATE_REF.current){
      STATE_REF.current.value = Pincode.stateName;
      }
      if(CITY_REF.current){
      CITY_REF.current.value = Pincode.districtName;
      }
    }
    // else {
    //   STATE_REF.current.value = "";
    //   CITY_REF.current.value = "";
    // }
    // const isPinCodeExist = PinCodeDirectory.lookup(PIN);
    // if (!isPinCodeExist.length) {
    //   setError({
    //     error: true,
    //     type: ERROR_TYPE,
    //     message: Errors.INVALID_RESIDENTIAL_PINCODE_ERROR,
    //   });
    //   return false;
    // }
    if (error.error) {
      setError({
        error: false,
        type: "",
        message: "",
      });
      return true;
    }
    return true;
  }
  // * Function to validate  Years at current address.
  const validateMonthsAtCurrentAddress = (monthsAtCurrentAddress) => {
    if (!monthsAtCurrentAddress) {
      setError({
        error: true,
        type: Errors.RESIDENTIAL_YEARS_AT_CURRENT_ADDRESS_ERROR_TYPE,
        message: Errors.RESIDENTIAL_NULL_YEARS_AT_CURRENT_ADDRESS_ERROR,
      });
      return false;
    }
    if (monthsAtCurrentAddress.charAt(0) === "0") {
      setError({
        error: true,
        type: Errors.RESIDENTIAL_YEARS_AT_CURRENT_ADDRESS_ERROR_TYPE,
        message: Errors.NUMBER_STARTING_FROM_ZERO,
      });
      return false;
    }
    if (isNaN(monthsAtCurrentAddress)) {
      setError({
        error: true,
        type: Errors.RESIDENTIAL_YEARS_AT_CURRENT_ADDRESS_ERROR_TYPE,
        message: Errors.INVALID_RESIDENTIAL_YEARS_AT_CURRENT_ADDRESS_ERROR,
      });
      return false;
    }
    if (ageRef.current < parseInt(monthsAtCurrentAddress) / 12) {
      setError({
        error: true,
        type: Errors.RESIDENTIAL_YEARS_AT_CURRENT_ADDRESS_ERROR_TYPE,
        message:
          Errors.INVALID_RESIDENTIAL_YEARS_AT_CURRENT_ADDRESS_WITH_AGE_ERROR,
      });
      return false;
    }

    if (
      error.error &&
      error.type === Errors.RESIDENTIAL_YEARS_AT_CURRENT_ADDRESS_ERROR_TYPE
    ) {
      setError({
        error: false,
        type: "",
        message: "",
      });
    }
    return true;
  };

  const handleParticipantLeadSuccess = () => {
    console.log("after push leads to participants");
  };

  const handleParticipantLeadError = () => {
    console.log("error in lead push");
  };

  const handleGetLastSlideSuccess = (data) => {
    if (data?.data?.lastSlideIndex) {
      if (cookies.customerAge) {
        ageRef.current = cookies.customerAge;
      }
      if (cookies.employmentType) {
        setEmploymentType(cookies.employmentType);
      }
      if (data?.data?.lastSlideIndex === 38) {
        setActiveSlide(2); //2
      } else if (data?.data?.lastSlideIndex === 46) {
        setActiveSlide(2);
      } else {
        setActiveSlide(data?.data?.lastSlideIndex);
      }
    } else {
      setCookie(LAST_ACTIVE_JOURNEY_SLIDE, activeSlide + 1, {
        path: CookiePath,
        maxAge: COOKIES_MAX_AGE,
      });
      switchToNextSlide(activeSlide + 1);
    }
  };

  // Mutation function for journey
  const leadPushForParticipantAPI = useMutation({
    mutationKey: ["leadPushForParticipant"],
    mutationFn: (Payload) => leadPushForParticipant(Payload),
    onSuccess: () => handleParticipantLeadSuccess(),
    onError: (error) => handleParticipantLeadError(error),
    retry: false,
  });

  // To fetch Last Slide
  const getLastSlideDetailsAPI = useMutation({
    mutationKey: ["getLastSlideDetails"],
    mutationFn: (payload) => getLastSlideDetails(payload),
    onSuccess: (data) => handleGetLastSlideSuccess(data),
    retry: false,
  });

  // * Function to add Residential Address details
  async function addResidentialAddress() {
    const shopName = shopNameRef.current?.value;
    const residentialAddress = isEditCommAddress ? residentialAddress1Ref?.current?.value : customerDetails.customerDetails.address;
    const residentialAddress2 = isEditCommAddress ? residentialAddress2Ref?.current?.value : customerDetails.customerDetails.address2;
    const residentialCity = isEditCommAddress ? residentialCityRef?.current?.value : customerDetails.customerDetails.city;
    const residentialState = isEditCommAddress ? residentialStateRef?.current?.value : customerDetails.customerDetails.state;
    const pinCode = isEditCommAddress ? residentialPinRef?.current?.value : customerDetails.customerDetails.pincode;
    // const addressType = residenceAddressTypeRef.current.value;
    const natureOfAddress = residenceNatureOfAddressRef.current?.value;
    const monthsAtCurrentAddress =
      residenceYearsAtCurrentAddressRef.current?.value;
    const useCommunciationAddressAsPermanent =
      useResidentialAddressAsPermanentRef.current?.checked;

    if (
      !validateAddress(
        residentialAddress,
        Errors.RESIDENTIAL_ADDRESS1_ERROR_TYPE,
        Errors.RESIDENTIAL_ADDRESS1_ERROR
      )
    ) {
      return false;
    }
    if (
      !validateAddress(
        residentialAddress2,
        Errors.RESIDENTIAL_ADDRESS2_ERROR_TYPE,
        Errors.RESIDENTIAL_ADDRESS2_ERROR
      )
    ) {
      return false;
    }
    if (
      !validatePinCode(
        pinCode,
        Errors.RESIDENTIAL_PIN_ERROR_TYPE,
        residentialPinRef,
        residentialCityRef,
        residentialStateRef
      )
    ) {
      return false;
    }
    if (
      !validateCityAndState(residentialCity, Errors.RESIDENTIAL_CITY_ERROR_TYPE)
    ) {
      return false;
    }
    if (
      !validateCityAndState(
        residentialState,
        Errors.RESIDENTIAL_STATE_ERROR_TYPE
      )
    ) {
      return false;
    }
    // if (!addressType) {
    //   setError({
    //     error: true,
    //     type: Errors.RESIDENTIAL_ADDRESS_TYPE_ERROR_TYPE,
    //     message: Errors.ADDRESS_TYPE,
    //   });
    //   return false;
    // }
    if (!natureOfAddress && JourneyVersion !== "J3") {
      setError({
        error: true,
        type: Errors.RESIDENTIAL_NATURE_OF_ADDRESS_ERROR_TYPE,
        message: Errors.NATURE_OF_ADDRESS,
      });
      return false;
    }
    if (JourneyVersion !== "J3") {
      validateMonthsAtCurrentAddress(monthsAtCurrentAddress);
    }
    if (!shopName && JourneyVersion === "J3") {
      setError({
        error: true,
        type: Errors.SHOP_NAME_ERROR_TYPE,
        message: Errors.NULL_SHOP_NAME_ERROR,
      });
      return false;
    }

    if (!error.error) {
      window.webengage.track("Current Address", {
        "address line 1": residentialAddress,
      });
      window.webengage.track("Current Address", {
        "address line 2": residentialAddress2,
      });
      window.webengage.track("Current Address", {
        pincode: pinCode,
      });
      // * Call API here
      // const isPinCodeExist = PinCodeDirectory.lookup(pinCode);
      // if (isPinCodeExist.length !== 0) {
      let data = {
        address: trimString(residentialAddress),
        address2: trimString(residentialAddress2),
        city: trimString(residentialCity),
        state: trimString(residentialState),
        pincode: pinCode,
        // address_type: trimString(addressType),
        address_type: "present",
      };
      if (shopName) data.company_name = shopName;
      if (natureOfAddress) data.nature_of_address = natureOfAddress;
      if (monthsAtCurrentAddress)
        data.months_at_current_address = monthsAtCurrentAddress;

      if (useCommunciationAddressAsPermanent) {
        const { address, address2, city, state, pincode } = data;
        data.permanent_address = `${address}; ${address2}; ${city}; ${pincode}; ${state}`;
      }

      setCustomerDetails({ ...customerDetails, ...data });
      setCustomerDetailsCookies(data);

      setLoading(true);
      const slidePayload = {
        slideName: "Communication Address",
        slideIndex: 11,
        slideIcon: "CommunicationAddress",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      const response = await updateJourneyData(data, slidePayload);
      if (response) {
        if (JourneyVersion === "J3") {
          setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 35, { path: CookiePath });
          switchToNextSlide(36);
          setLoading(false);
          pullBureauDetails();
          // const { appID = {} } = appId;
          const participantDet = {
            headers: {
              apiKey,
              apiSecret,
              Authorization: `Bearer ${customerDetails?.authToken}`,
            },
            data: { id: customerDetails?.appID },
          };
          leadPushForParticipantAPI.mutate(participantDet);
          return;
        }
        if (useCommunciationAddressAsPermanent) {
          if (domainName === "maxemocapital") {
            if (
              customerDetails.employment_type === "Salaried" ||
              customerDetails.contact.employment_type === "Salaried"
            ) {
              setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 18, {
                path: CookiePath,
              });
              switchToNextSlide(19); // * Rediret to Loan Amount slide
            } else {
              switchToNextSlide(19); // * Rediret to Loan Amount slide
              setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 14, {
                path: CookiePath,
              });
              // switchToNextSlide(14); // * Rediret to Business Details slide
            }
          } else {
            setCookie(LAST_ACTIVE_JOURNEY_SLIDE, activeSlide + 2, {
              path: CookiePath,
            });
            // * Redirect to Office slide.
            switchToNextSlide(activeSlide + 2);
          }
        } else {
          setCookie(LAST_ACTIVE_JOURNEY_SLIDE, activeSlide + 1, {
            path: CookiePath,
          });
          switchToNextSlide(activeSlide + 1);
          officeAddress1Ref.current.focus();
        }
        pullBureauDetails();
      }
      setLoading(false);
    }
  }
  // * Function to add Office Address details
  async function addPermanentAddress() {
    const permanentAddress = permanentAddress1Ref.current.value;
    const permanentAddress2 = permanentAddress2Ref.current.value;
    const permanentCity = permanentCityRef.current.value;
    const permanentState = permanentStateRef.current.value;
    const pinCode = permanentPinRef.current.value;

    if (
      !validateAddress(
        permanentAddress,
        Errors.PERMANENT_ADDRESS1_ERROR_TYPE,
        Errors.PERMANENT_ADDRESS1_ERROR
      )
    ) {
      return false;
    }
    if (
      !validateAddress(
        permanentAddress2,
        Errors.PERMANENT_ADDRESS2_ERROR_TYPE,
        Errors.PERMANENT_ADDRESS2_ERROR
      )
    ) {
      return false;
    }
    if (
      !validateCityAndState(permanentCity, Errors.PERMANENT_CITY_ERROR_TYPE)
    ) {
      return false;
    }
    if (
      !validateCityAndState(permanentState, Errors.PERMANENT_STATE_ERROR_TYPE)
    ) {
      return false;
    }
    if (
      !validatePinCode(
        pinCode,
        Errors.PERMANENT_PIN_ERROR_TYPE,
        permanentPinRef,
        permanentCityRef,
        permanentStateRef
      )
    ) {
      return false;
    }

    if (!error.error) {
      const permanentAddressObject = {
        permanent_address: `${permanentAddress}; ${permanentAddress2}; ${permanentCity}; ${pinCode}; ${permanentState}`,
      };
      setCustomerDetails({ ...customerDetails, ...permanentAddressObject });
      setCustomerDetailsCookies(permanentAddressObject);

      setLoading(true);
      // * Call API here
      const slidePayload = {
        slideName: "Permanent Address",
        slideIndex: 12,
        slideIcon: "PermanentAddress",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      const response = await updateJourneyData(
        permanentAddressObject,
        slidePayload
      );
      if (response) {
        // * Login Lead
        const headers = {
          apiKey: apiKey,
          apiSecret: apiSecret,
          Authorization: `Bearer ${customerDetails?.authToken}`,
        };
        const payload = {
          headers,
          id: customerDetails?.appID,
        };
        pushRealTimeLeadAPI.mutate(payload);
        realTimeAutoLogin();

        if (domainName === "maxemocapital") {
          // switchToNextSlide(13); // * Redirect to Company Details slide
          switchToNextSlide(19); // * Redirect to Loan Amount slide
        } else {
          switchToNextSlide(activeSlide + 1);
        }
        pullBureauDetails();
      }
      // } else {
      //   setError({
      //     error: true,
      //     type: Errors.OFFICE_PIN_ERROR_TYPE,
      //     message: Errors.INVALID_OFFICE_PINCODE_ERROR,
      //   });
      // }

      setLoading(false);
    }
  }
  // * Function to add Office Address details
  async function addOfficeAddress() {
    const officeAddress = officeAddress1Ref.current.value;
    const officeAddress2 = officeAddress2Ref.current.value;
    const officeCity = officeCityRef.current.value;
    const officeState = officeStateRef.current.value;
    const pinCode = officePinRef.current.value;

    if (
      !validateAddress(
        officeAddress,
        Errors.OFFICE_ADDRESS1_ERROR_TYPE,
        Errors.OFFICE_ADDRESS1_ERROR
      )
    ) {
      return false;
    }
    if (
      !validateAddress(
        officeAddress2,
        Errors.OFFICE_ADDRESS2_ERROR_TYPE,
        Errors.OFFICE_ADDRESS2_ERROR
      )
    ) {
      return false;
    }
    if (!validateCityAndState(officeCity, Errors.OFFICE_CITY_ERROR_TYPE)) {
      return false;
    }
    if (!validateCityAndState(officeState, Errors.OFFICE_STATE_ERROR_TYPE)) {
      return false;
    }
    if (
      !validatePinCode(
        pinCode,
        Errors.OFFICE_PIN_ERROR_TYPE,
        officePinRef,
        officeCityRef,
        officeStateRef
      )
    ) {
      return false;
    }

    if (!error.error) {
      // const isPinCodeExist = PinCodeDirectory.lookup(pinCode);
      // if (isPinCodeExist.length !== 0) {
      const officeAddressObject = {
        officeaddress1: trimString(officeAddress),
        officeaddress2: trimString(officeAddress2),
        office_city: trimString(officeCity),
        office_state: trimString(officeState),
        officepincode: pinCode,
      };
      setCustomerDetails({ ...customerDetails, ...officeAddressObject });
      setCustomerDetailsCookies(officeAddressObject);

      setLoading(true);
      // * Call API here
      const slidePayload = {
        slideName: "Office Address",
        slideIndex: 13,
        slideIcon: "OfficeAddress",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      const response = await updateJourneyData(
        officeAddressObject,
        slidePayload
      );
      if (response) {
        // * Login Lead
        const headers = {
          apiKey: apiKey,
          apiSecret: apiSecret,
          Authorization: `Bearer ${customerDetails?.authToken}`,
        };
        const payload = {
          headers,
          id: customerDetails?.appID,
        };
        pushRealTimeLeadAPI.mutate(payload);
        realTimeAutoLogin();

        if (customerDetails.employment_type === "Self-Employed") {
          switchToNextSlide(activeSlide + 2);
        } else {
          switchToNextSlide(activeSlide + 1);
        }
      }

      setLoading(false);
    }
  }
  // * Function to validate Company Name
  function validateCompanyName(companyName) {
    if (companyName === "") {
      setError({
        error: true,
        type: Errors.COMPANY_NAME_ERROR_TYPE,
        message: Errors.NULL_COMPANY_NAME_ERROR,
      });
      return false;
    }
    if (!/[A-Z[a-z]/.test(companyName)) {
      setError({
        error: true,
        type: Errors.COMPANY_NAME_ERROR_TYPE,
        message: Errors.INVALID_COMPANY_NAME_ERROR,
      });
      return false;
    }
    // if (/[0-9]/.test(companyName)) {
    //   setError({
    //     error: true,
    //     type: Errors.COMPANY_NAME_ERROR_TYPE,
    //     message: Errors.INVALID_COMPANY_NAME_ERROR,
    //   });
    //   return false;
    // }
    if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(companyName)) {
      setError({
        error: true,
        type: Errors.COMPANY_NAME_ERROR_TYPE,
        message: Errors.INVALID_COMPANY_NAME_ERROR,
      });
      return false;
    }
    if (error.error && error.type === Errors.COMPANY_NAME_ERROR_TYPE) {
      setError({
        error: false,
        type: "",
        message: "",
      });
    }
    return true;
  }
  // * Function to validate Designation
  function validateDesignation(designation) {
    if (designation === "") {
      setError({
        error: true,
        type: Errors.DESIGNATION_ERROR_TYPE,
        message: Errors.NULL_DESIGNATION_ERROR,
      });
      return false;
    }
    if (/[0-9]/.test(designation)) {
      setError({
        error: true,
        type: Errors.DESIGNATION_ERROR_TYPE,
        message: Errors.INVALID_DESIGNATION_ERROR,
      });
      return false;
    }
    if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(designation)) {
      setError({
        error: true,
        type: Errors.DESIGNATION_ERROR_TYPE,
        message: Errors.INVALID_DESIGNATION_ERROR,
      });
      return false;
    }
    if (error.error && error.type === Errors.DESIGNATION_ERROR_TYPE) {
      setError({
        error: false,
        type: "",
        message: "",
      });
    }
    return true;
  }
  // * Functio to validate work experience
  function validateWorkExperience(workExperience) {
    if (workExperience === "") {
      setError({
        error: true,
        type: Errors.WORK_EXPERIENCE_ERROR_TYPE,
        message: Errors.NULL_WORK_EXPERIENCE_ERROR_TYPE,
      });
      return false;
    }
    if (isNaN(workExperience)) {
      setError({
        error: true,
        type: Errors.WORK_EXPERIENCE_ERROR_TYPE,
        message: Errors.INVALID_WORK_EXPERIENCE_ERROR_TYPE,
      });
      return false;
    }
    if (parseInt(workExperience) < 0) {
      setError({
        error: true,
        type: Errors.WORK_EXPERIENCE_ERROR_TYPE,
        message: Errors.NEGATIVE_EXPERIENCE,
      });
      return false;
    }
    if (workExperience.charAt(0) === "0") {
      setError({
        error: true,
        type: Errors.WORK_EXPERIENCE_ERROR_TYPE,
        message: Errors.NUMBER_STARTING_FROM_ZERO,
      });
      return false;
    }
    if (parseInt(workExperience) > parseInt(ageRef.current)) {
      setError({
        error: true,
        type: Errors.WORK_EXPERIENCE_ERROR_TYPE,
        message: Errors.AGE_CONFLICT_WORK_EXPERIENCE_ERROR,
      });
      return false;
    }
    if (error.error) {
      setError({
        error: false,
        type: "",
        message: "",
      });
    }
    return true;
  }
  // * Function to add company name of salaried person
  async function addCompanyDetails() {
    const companyName = trimString(
      customerDetails?.customerDetails?.customer_type === "Proprietorship"
        ? customerDetails?.customerDetails?.ProprietorBusinessDetails[0]
            ?.company_name
        : companyNameRef.current.value
    );
    const companyType = trimString(
      customerDetails?.customerDetails?.customer_type === "Proprietorship"
        ? customerDetails?.customerDetails?.customer_type
        : companyTypeRef.current.value
    );
    const designation = designationRef.current?.value
      ? trimString(designationRef.current?.value)
      : null;
    const workExperience = totalWorkExperienceRef.current.value;

    if (!validateCompanyName(companyName)) return false;

    if (!validateDesignation(designation)) return false;

    if (!validateWorkExperience(workExperience)) return false;

    if (
      !validateDropdownInputs(
        companyType,
        Errors.COMPANY_TYPE_ERROR_TYPE,
        Errors.NULL_COMPANY_TYPE_ERROR
      )
    )
      return false;

    if (!error.error && error.type !== Errors.COMPANY_NAME_ERROR_TYPE) {
      window.webengage.track("Name Of Company", {
        "Name Entered": companyName,
      });
      window.webengage.user.setAttribute("we_company", companyName);

      const details = {
        companyName,
        companyType,
        designation,
        workExperience,
      };
      setCustomerDetails({
        ...customerDetails,
        ...details,
      });

      setCustomerDetailsCookies(details);

      // * Call API here
      setLoading(true);
      const Payload = {
        company_name: companyName,
        company_type: companyType,
        designation_name: designation,
        work_experience: workExperience,
      };

      const slidePayload = {
        slideName: "Company Details",
        slideIndex: 14,
        slideIcon: "CompanyDetails",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      const response = await updateJourneyData(Payload, slidePayload);
      if (response) {
        if (employmentType === "Salaried") {
          if (customerDetails.userType === "LSP" && loanOffers.length) {
            switchToNextSlide(38);
            // * Do not save Pre-Approved Loan Offer screen slide as cookie.
          } else {
            switchToNextSlide(19);
            setCookie(LAST_ACTIVE_JOURNEY_SLIDE, 17, {
              path: CookiePath,
              maxAge: COOKIES_MAX_AGE,
            });
          }
        } else {
          switchToNextSlide(activeSlide + 1);
          setCookie(LAST_ACTIVE_JOURNEY_SLIDE, activeSlide + 1, {
            path: CookiePath,
            maxAge: COOKIES_MAX_AGE,
          });
        }
      }
      setLoading(false);
    }
  }
  // * Function to add Business details
  async function addBusinessDetails() {
    const natureOfBusiness = natureOfBusinessRef.current.value;
    // const inCorporationDate = incorporationDateRef.current.value;

    // if (natureOfBusiness === "") {
    if (
      !validateDropdownInputs(
        natureOfBusiness,
        Errors.BUSSINES_NATURE_ERROR_TYPE,
        Errors.BUSINESS_NATURE_ERROR
      )
    ) {
      setError({
        error: true,
        type: Errors.BUSSINES_NATURE_ERROR_TYPE,
        message: Errors.BUSINESS_NATURE_ERROR,
      });
      return false;
    }
    // if (inCorporationDate === "") {
    //   setError({
    //     error: true,
    //     type: Errors.INCORPORATION_DATE_ERROR_TYPE,
    //     message: Errors.INCORPORATION_DATE_ERROR,
    //   });
    //   return false;
    // }
    if (!error.error) {
      const details = {
        natureOfBusiness,
        // inCorporationDate,
      };
      setCustomerDetails({
        ...customerDetails,
        ...details,
      });
      setCustomerDetailsCookies(details);
      // * Call API here
      const businessDetails = {
        nature_of_business: natureOfBusiness,
        // date_of_incorporation: inCorporationDate,
      };
      setLoading(true);

      const slidePayload = {
        slideName: "Business Details",
        slideIndex: 15,
        slideIcon: "BusinessDetails",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      const response = await updateJourneyData(businessDetails, slidePayload);
      if (response) {
        switchToNextSlide(activeSlide + 1);
      }
      setLoading(false);
    }
  }
  // * Function to add Co-Applicant details
  async function addCoApplicantData() {
    const fullname = coApplicantNameRef.current.value;
    const DOB = coApplicantDOBRef.current.value;
    const PAN = coApplicantPANRef.current.value;

    if (!handleFullName(fullname, Errors.CO_APPLICANT_FULLNAME_ERROR_TYPE)) {
      return false;
    }
    if (!handleDOB(DOB, Errors.CO_APPLICANT_DOB_ERROR_TYPE)) {
      setError({
        error: true,
        type: Errors.CO_APPLICANT_DOB_ERROR_TYPE,
        message: Errors.CO_APPLICANT_DOB_ERROR,
      });
      return false;
    }
    if (!handlePAN(PAN, Errors.CO_APPLICANT_PAN_ERROR_TYPE)) {
      return false;
    }

    if (!error.error) {
      const coApplicantData = {
        coApplicantName: trimString(fullname),
        coAppplicantDOB: DOB.trim(),
        coApplicantPAN: PAN.toUpperCase(),
      };
      setCustomerDetails({
        ...customerDetails,
        ...coApplicantData,
      });
      setCustomerDetailsCookies(coApplicantData);

      // * Call API here
      setLoading(true);
      const slidePayload = {
        slideName: "Add Co-Applicant",
        slideIndex: 17,
        slideIcon: "AddCoApplicant",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      const response = await updateJourneyData(coApplicantData, slidePayload);
      if (response) {
        switchToNextSlide(activeSlide + 1);
      }
      setLoading(false);
    }
  }
  // * Function to add more Co-Applicant details
  async function addMoreCoApplicantDetails() {
    const mobile = coApplicantMobileRef.current.value;
    const email = coApplicantEmailRef.current.value;
    const relation = coApplicantRelationRef.current.value;
    // const gender = coApplicantGenderRef.current.value;

    if (
      validateMobileNumber(
        mobile,
        Errors.CO_APPLICANT_MOBILE_ERROR_TYPE,
        "coApplicant"
      ) === false
    ) {
      return false;
    }

    const isEmailValid = handleEmail(
      email,
      Errors.CO_APPLICANT_EMAIL_ERROR_TYPE
    );
    if (!isEmailValid) {
      return false;
    }
    if (
      !validateDropdownInputs(
        relation,
        Errors.CO_APPLICANT_RELATION_ERROR_TYPE,
        Errors.NULL_CO_APPLICANT_RELATION_ERROR
      )
    ) {
      return false;
    }
    // if (gender === "" || gender === undefined) {
    //   setError({
    //     error: true,
    //     type: Errors.CO_APPLICANT_GENDER_TYPE_ERROR,
    //     message: "",
    //   });
    //   return false;
    // }
    if (!error.error) {
      const coApplicantData = {
        coApplicantMobile: mobile,
        coApplicantEmail: email,
        coApplicantRelation: relation,
        // coApplicantGender: gender,
      };
      setCustomerDetails({ ...customerDetails, ...coApplicantData });
      setCustomerDetailsCookies(coApplicantData);

      setLoading((prev) => !prev);
      // * Call API here
      const slidePayload = {
        slideName: "Add More Co-Applicant Details",
        slideIndex: 18,
        slideIcon: "AddMoreCoApplicantDetails",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      const response = await updateJourneyData(coApplicantData, slidePayload);
      if (response) {
        if (customerDetails.userType === "LSP" && loanOffers.length) {
          switchToNextSlide(37);
          // * Do not save Pre-Approved Loan Offer screen slide as cookie.
        } else {
          switchToNextSlide(activeSlide + 1);
        }
      }
      setLoading((prev) => !prev);
    }
  }
  // * Function to handle Loan Amount
  function validateLoanAmount(loanAmount) {
    if (isNaN(loanAmount)) loanAmount = loanAmount.split(",").join("");

    if (loanAmount === "" || isNaN(loanAmount)) {
      setLoanAmount(0);
      return false;
    } else {
      const { minimumLoanAmount, maximumLoanAmount } = loanSettings;

      if (minimumLoanAmount && maximumLoanAmount) {
        if (loanAmount > maximumLoanAmount) {
          setError({
            error: true,
            type: Errors.LOAN_AMOUNT_ERROR_TYPE,
            // message: Errors.LOAN_AMOUNT_HIGH_LIMIT_ERROR,
            message: `Loan amount cannot be more than ${formatNumber(
              maximumLoanAmount
            )}`,
          });
          setLoanAmount(parseInt(loanAmount));
          return false;
        } else if (loanAmount < minimumLoanAmount) {
          setError({
            error: true,
            type: Errors.LOAN_AMOUNT_ERROR_TYPE,
            // message: Errors.LOAN_AMOUNT_LOW_LIMIT_ERROR,
            message: `Loan amount cannot be lower than ${formatNumber(
              minimumLoanAmount
            )}`,
          });
          setLoanAmount(parseInt(loanAmount));
          return false;
        } else {
          setLoanAmount(parseInt(loanAmount));
          if (error.error && error.type === Errors.LOAN_AMOUNT_ERROR_TYPE) {
            setError({
              error: false,
              type: "",
              message: "",
            });
          }
          return true;
        }
      } else {
        return true;
      }
      // if (loanAmount > MaxLoanAmount) {
      //   setError({
      //     error: true,
      //     type: Errors.LOAN_AMOUNT_ERROR_TYPE,
      //     message: Errors.LOAN_AMOUNT_HIGH_LIMIT_ERROR,
      //   });
      //   setLoanAmount(parseInt(loanAmount));
      //   return false;
      // } else if (loanAmount < MinLoanAmount) {
      //   setError({
      //     error: true,
      //     type: Errors.LOAN_AMOUNT_ERROR_TYPE,
      //     message: Errors.LOAN_AMOUNT_LOW_LIMIT_ERROR,
      //   });
      //   setLoanAmount(parseInt(loanAmount));
      //   return false;
      // } else {
      //   setLoanAmount(parseInt(loanAmount));
      //   if (error.error && error.type === Errors.LOAN_AMOUNT_ERROR_TYPE) {
      //     setError({
      //       error: false,
      //       type: "",
      //       message: "",
      //     });
      //   }
      //   return true;
      // }
    }
  }
  // Function to add Journey Loan Amount
  async function addJourneyLoanAmount() {
    if (
      !error.error &&
      error.type !== Errors.LOAN_AMOUNT_ERROR_TYPE &&
      validateLoanAmount(loanAmount)
    ) {
      window.webengage.track("Loan Filter - Selected", {
        "Loan Amount Entered": loanAmount,
      });
      setLoading(true);

      setCustomerDetails({ ...customerDetails, loanAmount });
      setCustomerDetailsCookies({ loanAmount });

      const data = {
        your_desired_loan_amount: loanAmount,
      };
      const slidePayload = {
        slideName: "Success : Journey Completed",
        slideIndex: 46,
        slideIcon: "Success",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      const response = await updateJourneyData(data, slidePayload);
      if (response) {
        if (JourneyVersion === "J2") {
          if (customerDetails.userType === "Credit Institution")
            handleSendLeadDetailViaEmail(loanAmount);

          removeAllCookies(CookiePath);
          switchToNextSlide(activeSlide + 1);
        } else {
          setCookie(LAST_ACTIVE_JOURNEY_SLIDE, activeSlide + 1, {
            path: CookiePath,
            maxAge: COOKIES_MAX_AGE,
          });
          switchToNextSlide(activeSlide + 1);
        }
      }
      setLoading(false);
    }
  }
  // * Function to add Loan Amount
  async function addLoanAmount() {
    if (
      !error.error &&
      error.type !== Errors.LOAN_AMOUNT_ERROR_TYPE &&
      validateLoanAmount(loanAmount)
    ) {
      window.webengage.track("Loan Filter - Selected", {
        "Loan Amount Entered": loanAmount,
      });
      setLoading(true);

      setCustomerDetails({ ...customerDetails, loanAmount });
      setCustomerDetailsCookies({ loanAmount });

      const data = {
        your_desired_loan_amount: loanAmount,
      };
      const slidePayload = {
        slideName: "Loan Amount",
        slideIndex: 19,
        slideIcon: "LoanAmount",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      const response = await updateJourneyData(data, slidePayload);
      if (response) {
        if (JourneyVersion === "J2") {
          if (customerDetails.userType === "Credit Institution")
            handleSendLeadDetailViaEmail(loanAmount);

          removeAllCookies(CookiePath);
          switchToNextSlide(38);
        } else {
          setCookie(LAST_ACTIVE_JOURNEY_SLIDE, activeSlide + 1, {
            path: CookiePath,
            maxAge: COOKIES_MAX_AGE,
          });
          switchToNextSlide(activeSlide + 1);
        }
      }
      setLoading(false);
    }
  }
  // * Function to add Auto Login
  function realTimeAutoLogin() {
    let headerData = {
      apiKey: apiKey,
      apiSecret: apiSecret,
    };
    const realTimeLeadPushFun = {
      id: customerDetails.appID,
    };
    const payload = {
      headerData,
      // bodyDetails,
      authToken: customerDetails.authToken,
      realTimeLeadPushFun,
    };
    // * Auto Bureau Pull (CRIF Data)
    props.getAutoPullBureauDetails(payload);
  }
  // * Function to Pull Customer's Bureau data
  async function pullBureauDetails() {
    let headerData = {
      apiKey: apiKey,
      apiSecret: apiSecret,
    };
    // const bodyDetails = {
    //   ...queryValues,
    // };
    const realTimeLeadPushFun = {
      id: customerDetails.appID,
    };
    const payload = {
      headerData,
      // bodyDetails,
      authToken: customerDetails.authToken,
      realTimeLeadPushFun,
    };
    props.getAutoPullBureauDetails(payload);
  }

  const finishJourney = () => {
    switchToNextSlide(38);
    removeAllCookies(CookiePath);
  };

  const getLoanDetails = () => {
    if (JourneyVersion === "J3") return <></>;
    else if (domainName === "recapitafinance")
      return <RecapitaLoanDetails scrollPosition={scrollPosition} />;
    else if (domainName === "myfindoc")
      return <MyfindocLoanDetails scrollPosition={scrollPosition} />;
    else return "";
    // <PersonalLoanDetails scrollPosition={scrollPosition} />;
  };

  const restoredData = (data) => {
    if (data?.name === "email") {
      console.log("restore name", data?.name);
      personalEmailRef.current.value = customerDetails?.email;
    }
  };

  const editEmailDetails = () => {
    personalEmailRef.current.value = customerDetails?.email;
    setIsEditEmail(!isEditEmail);
  };

  const editAddress = () => {
    console.log("edit Address=>", isEditAddress);
    setIsEditAddress(!isEditAddress);
    residentialAddress1Ref.current.value = customerDetails
      ?.communication_address?.line_1
      ? customerDetails?.communication_address?.line_1 +
        " " +
        customerDetails?.communication_address?.street_name
      : "";
    residentialAddress2Ref.current.value = customerDetails
      ?.communication_address?.line_2
      ? customerDetails?.communication_address?.line_2
      : "";
    residentialPinRef.current.value = customerDetails?.communication_address
      ?.zip
      ? customerDetails?.communication_address?.zip
      : "";
    residentialCityRef.current.value = customerDetails?.communication_address
      ?.city
      ? customerDetails?.communication_address?.city
      : "";
    residentialStateRef.current.value = customerDetails?.communication_address
      ?.state
      ? customerDetails?.communication_address?.state
      : "";
  };

  const editProfEmailDetails = () => {
    setIsEditProfEmail(!isEditProfEmail);
  };

  const editCommAddressHandler = () => {
    setIsEditCommAddress(!isEditCommAddress);
  }

  return (
    <div
      className={`journeyRightSection ${
        activeSlide ? "fullScreenMobileView" : null
      }`}
    >
      <div className="wrapper">
        {/* Slide Back Button */}
        <div
          className={`slideHeader ${
            [0, 2, 38].includes(activeSlide)
              ? "hideSlideHeader"
              : "showSlideHeader"
          }`}
        >
          <button
            className={`backBtn`}
            title="Go Back"
            // onClick={() => switchToPreviousSlide(previousSlide)}
            onClick={goBack}
          >
            <ArrowBackRoundedIcon sx={{ color: "#2e3092" }} />
            Back
          </button>
        </div>
        {/* Carousel Container */}
        <div className="journeyCarouselContainer">
          <Carousel
            indicators=""
            controls={false}
            interval={null}
            activeIndex={activeSlide}
            id="carousel_id"
          >
            {/* Mobile Number Slide */}
            <Carousel.Item id="1" className="mobileNumberSlide">
              <div className="carouselSlideWrapper mobileNumberSlideWrapper">
                {/* User Greetings Container */}
                <div className="userGreetingsContainer"></div>
                {/* Form Content */}
                <div className="formContent userMobileNumberForm">
                  {domainName === "maxemocapital" ? (
                    <TextInputField
                      type="tel"
                      label="Mobile Number"
                      placeholder="Mobile number"
                      name="mobileNumber"
                      id="mobileNumber"
                      reference={mobileNumberRef}
                      required={true}
                      maxLength="10"
                      minLength="10"
                      disabled={true}
                      // onEnterHandler={sendOTP}
                      errorType={Errors.MOBILE_NUMBER_ERROR_TYPE}
                      handler={(mobileNumber) =>
                        validateMobileNumber(
                          mobileNumber,
                          Errors.MOBILE_NUMBER_ERROR_TYPE,
                          "customerMobileNumber"
                        )
                      }
                      autoFocus={true}
                    />
                  ) : (
                    <TextInputField
                      type="tel"
                      label="Enter your mobile number to proceed further"
                      placeholder="Enter 10 digit mobile number"
                      name="mobileNumber"
                      id="mobileNumber"
                      reference={mobileNumberRef}
                      required={true}
                      maxLength="10"
                      minLength="10"
                      // onEnterHandler={sendOTP}
                      errorType={Errors.MOBILE_NUMBER_ERROR_TYPE}
                      handler={(mobileNumber) =>
                        validateMobileNumber(
                          mobileNumber,
                          Errors.MOBILE_NUMBER_ERROR_TYPE,
                          "customerMobileNumber"
                        )
                      }
                      autoFocus={true}
                    />
                  )}

                  <div className="getOtpBtnContainer">
                    <ContinueButton
                      loading={loading}
                      error={error}
                      // handler={eNachCallBack}
                      handler={sendOTP}
                      // handler={() => setActiveSlide(19)}
                    />
                  </div>

                  {/* Terms and Conditions */}
                  <div className="tncCheckBox">
                    <div className="tnc">
                      <p>
                        By continuing you agree to our{" "}
                        <a
                          href="https://roopya.money/terms-conditions/"
                          target="_blank"
                        >
                          Terms of Use
                        </a>{" "}
                        and{" "}
                        {domainName === "myfindoc" ? (
                          <a
                            href="https://findocfinvest.com/privacy-policy.docx"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                        ) : (
                          <a
                            href="https://roopya.money/privacy-policy/"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                        )}
                      </p>
                    </div>
                    {domainName !== "quickfinshop" ? <PowerdByRoopya /> : null}
                  </div>
                </div>
                {/* Personal Loan Details */}
                {getLoanDetails()}
              </div>
            </Carousel.Item>
            {/* OTP Verification Slide */}
            <Carousel.Item id="2" className={"otp_slide"}>
              {activeSlide === 1 && (
                <OTPSlide
                  domainName={domainName}
                  mobileNumber={mobileNumberRef?.current?.value || ""}
                  setActiveSlide={setActiveSlide}
                  handleOtp={handleOtp}
                  verifyOTP={verifyOTP}
                  // resendOtp={resendOtp}
                  otpRefKey1={otpRefKey1}
                  otpRefKey2={otpRefKey2}
                  otpRefKey3={otpRefKey3}
                  otpRefKey4={otpRefKey4}
                  verifyOTPLoading={verifyLeadOtpAPI.isPending}
                />
              )}
            </Carousel.Item>
            {/* PAN  */}
            <Carousel.Item id="3">
              <PANSlide
                switchToNextSlide={switchToNextSlide}
                loading={loading}
                getUpdatedCustomerDetailsAPI={getUpdatedCustomerDetailsAPI}
                updateJourneyData={updateJourneyData}
                setCustomerDetailsCookies={setCustomerDetailsCookies}
                setLoading={setLoading}
              />
            </Carousel.Item>
            {/* FullName DOB PAN Slide */}
            <Carousel.Item id="4">
              <FullNameDobPANSlide
                ageRef={ageRef}
                loading={updateLeadDataAPI.isPending}
                setLoading={setLoading}
                domainName={domainName}
                fullNameDet={fullName}
                activeSlide={activeSlide}
                switchToNextSlide={switchToNextSlide}
                setCustomerDetailsCookies={setCustomerDetailsCookies}
                updateJourneyData={updateJourneyData}
                fullnameRef={fullnameRef}
                smsTemplates={smsTemplates}
                sendStageWiseSMS={handleSendStageWiseIndividualSms}
                handleCreateTimeline={handleCreateTimeline}
              />
            </Carousel.Item>
            {/* Gender Slide *** Selector *** */}
            <Carousel.Item id="5">
              <div className="carouselSlideWrapper">
                <div className="typographyContainer">
                  <h1>Gender</h1>
                  <p>Please select your gender.</p>
                </div>
                <div className="genderInputContainer dataFieldsContainer selectInputContainer">
                  <div className="maleGenderBtnBox">
                    <SelectorButton
                      label="Male"
                      elementValue={selectorValues.gender}
                      error={error}
                      errorType={Errors.GENDER_ERROR_TYPE}
                      handler={(gender) =>
                        addSelectorsData(
                          "gender",
                          gender,
                          Errors.GENDER_ERROR_TYPE,
                          {
                            slideName: "Gender",
                            slideIndex: 4,
                            slideIcon: "Gender",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      icon={<MaleIcon sx={{ fontSize: "30px" }} />}
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                  <div className="femaleGenderBtnBox">
                    <SelectorButton
                      label="Female"
                      elementValue={selectorValues.gender}
                      error={error}
                      errorType={Errors.GENDER_ERROR_TYPE}
                      handler={(gender) =>
                        addSelectorsData(
                          "gender",
                          gender,
                          Errors.GENDER_ERROR_TYPE,
                          {
                            slideName: "Gender",
                            slideIndex: 4,
                            slideIcon: "Gender",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      icon={<FemaleIcon sx={{ fontSize: "30px" }} />}
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                </div>
              </div>
            </Carousel.Item>
            {/* Purpose of Loan Slide*/}
            <Carousel.Item id="6">
              <div className="carouselSlideWrapper">
                <div className="typographyContainer">
                  <h1>Purpose of Loan</h1>
                  <p>Please select purpose of Loan</p>
                </div>
                <div className="genderInputContainer dataFieldsContainer selectInputContainer">
                  <div className="maleGenderBtnBox">
                    <SelectorButton
                      label="Home Building"
                      elementValue={selectorValues.purposeOfLoan}
                      error={error}
                      errorType={Errors.PURPOSE_OF_LOAN}
                      handler={(purposeOfLoan) =>
                        addSelectorsData(
                          "purposeOfLoan",
                          purposeOfLoan,
                          Errors.PURPOSE_OF_LOAN,
                          {
                            slideName: "Purpose of Loan",
                            slideIndex: 5,
                            slideIcon: "PurposeOfLoan",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      icon={<TbHomePlus sx={{ fontSize: "30px" }} />}
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                  <div className="femaleGenderBtnBox">
                    <SelectorButton
                      label="Home Renovation"
                      elementValue={selectorValues.purposeOfLoan}
                      error={error}
                      errorType={Errors.PURPOSE_OF_LOAN}
                      handler={(purposeOfLoan) =>
                        addSelectorsData(
                          "purposeOfLoan",
                          purposeOfLoan,
                          Errors.PURPOSE_OF_LOAN,
                          {
                            slideName: "Purpose of Loan",
                            slideIndex: 5,
                            slideIcon: "PurposeOfLoan",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      icon={<TbHomeStar sx={{ fontSize: "30px" }} />}
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                </div>
                <div className="genderInputContainer dataFieldsContainer selectInputContainer">
                  <div className="femaleGenderBtnBox">
                    <SelectorButton
                      label="Travel"
                      elementValue={selectorValues.purposeOfLoan}
                      error={error}
                      errorType={Errors.PURPOSE_OF_LOAN}
                      handler={(purposeOfLoan) =>
                        addSelectorsData(
                          "purposeOfLoan",
                          purposeOfLoan,
                          Errors.PURPOSE_OF_LOAN,
                          {
                            slideName: "Purpose of Loan",
                            slideIndex: 5,
                            slideIcon: "PurposeOfLoan",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      icon={<GiCommercialAirplane sx={{ fontSize: "30px" }} />}
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                  <div className="maleGenderBtnBox">
                    <SelectorButton
                      label="Education"
                      elementValue={selectorValues.purposeOfLoan}
                      error={error}
                      errorType={Errors.PURPOSE_OF_LOAN}
                      handler={(purposeOfLoan) =>
                        addSelectorsData(
                          "purposeOfLoan",
                          purposeOfLoan,
                          Errors.PURPOSE_OF_LOAN,
                          {
                            slideName: "Purpose of Loan",
                            slideIndex: 5,
                            slideIcon: "PurposeOfLoan",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      icon={<IoBookOutline sx={{ fontSize: "30px" }} />}
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                </div>
                <div className="genderInputContainer dataFieldsContainer selectInputContainer">
                  <div className="femaleGenderBtnBox">
                    <SelectorButton
                      label="Ocassion in family"
                      elementValue={selectorValues.purposeOfLoan}
                      error={error}
                      errorType={Errors.PURPOSE_OF_LOAN}
                      handler={(purposeOfLoan) =>
                        addSelectorsData(
                          "purposeOfLoan",
                          purposeOfLoan,
                          Errors.PURPOSE_OF_LOAN,
                          {
                            slideName: "Purpose of Loan",
                            slideIndex: 5,
                            slideIcon: "PurposeOfLoan",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      icon={
                        <MdOutlineEventAvailable sx={{ fontSize: "30px" }} />
                      }
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                  <div className="maleGenderBtnBox">
                    <SelectorButton
                      label="Purchase of household"
                      elementValue={selectorValues.purposeOfLoan}
                      error={error}
                      errorType={Errors.PURPOSE_OF_LOAN}
                      handler={(purposeOfLoan) =>
                        addSelectorsData(
                          "purposeOfLoan",
                          purposeOfLoan,
                          Errors.PURPOSE_OF_LOAN,
                          {
                            slideName: "Purpose of Loan",
                            slideIndex: 5,
                            slideIcon: "PurposeOfLoan",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      icon={<BiPurchaseTagAlt sx={{ fontSize: "30px" }} />}
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                </div>
                <div className="genderInputContainer dataFieldsContainer selectInputContainer">
                  <div className="femaleGenderBtnBox">
                    <SelectorButton
                      label="Business use"
                      elementValue={selectorValues.purposeOfLoan}
                      error={error}
                      errorType={Errors.PURPOSE_OF_LOAN}
                      handler={(purposeOfLoan) =>
                        addSelectorsData(
                          "purposeOfLoan",
                          purposeOfLoan,
                          Errors.PURPOSE_OF_LOAN,
                          {
                            slideName: "Purpose of Loan",
                            slideIndex: 5,
                            slideIcon: "PurposeOfLoan",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      icon={
                        <MdOutlineEventAvailable sx={{ fontSize: "30px" }} />
                      }
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                  <div className="maleGenderBtnBox">
                    <SelectorButton
                      label="Office Renovation"
                      elementValue={selectorValues.purposeOfLoan}
                      error={error}
                      errorType={Errors.PURPOSE_OF_LOAN}
                      handler={(purposeOfLoan) =>
                        addSelectorsData(
                          "purposeOfLoan",
                          purposeOfLoan,
                          Errors.PURPOSE_OF_LOAN,
                          {
                            slideName: "Purpose of Loan",
                            slideIndex: 5,
                            slideIcon: "PurposeOfLoan",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      icon={<SiMicrosoft sx={{ fontSize: "30px" }} />}
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                </div>

                <div className="genderInputContainer dataFieldsContainer selectInputContainer">
                  <div className="femaleGenderBtnBox">
                    <SelectorButton
                      label="Working capital"
                      elementValue={selectorValues.purposeOfLoan}
                      error={error}
                      errorType={Errors.PURPOSE_OF_LOAN}
                      handler={(purposeOfLoan) =>
                        addSelectorsData(
                          "purposeOfLoan",
                          purposeOfLoan,
                          Errors.PURPOSE_OF_LOAN,
                          {
                            slideName: "Purpose of Loan",
                            slideIndex: 5,
                            slideIcon: "PurposeOfLoan",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      icon={<PiCoinsThin sx={{ fontSize: "30px" }} />}
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                  <div className="maleGenderBtnBox">
                    <SelectorButton
                      label="Marriage"
                      elementValue={selectorValues.purposeOfLoan}
                      error={error}
                      errorType={Errors.PURPOSE_OF_LOAN}
                      handler={(purposeOfLoan) =>
                        addSelectorsData(
                          "purposeOfLoan",
                          purposeOfLoan,
                          Errors.PURPOSE_OF_LOAN,
                          {
                            slideName: "Purpose of Loan",
                            slideIndex: 5,
                            slideIcon: "PurposeOfLoan",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      icon={<LiaRingSolid sx={{ fontSize: "30px" }} />}
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                </div>

                <div className="genderInputContainer dataFieldsContainer selectInputContainer">
                  <div className="femaleGenderBtnBox">
                    <SelectorButton
                      label="Personal crisis"
                      elementValue={selectorValues.purposeOfLoan}
                      error={error}
                      errorType={Errors.PURPOSE_OF_LOAN}
                      handler={(purposeOfLoan) =>
                        addSelectorsData(
                          "purposeOfLoan",
                          purposeOfLoan,
                          Errors.PURPOSE_OF_LOAN,
                          {
                            slideName: "Purpose of Loan",
                            slideIndex: 5,
                            slideIcon: "PurposeOfLoan",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      icon={
                        <MdOutlineHealthAndSafety sx={{ fontSize: "30px" }} />
                      }
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                  <div className="maleGenderBtnBox">
                    <SelectorButton
                      label="Others"
                      elementValue={selectorValues.purposeOfLoan}
                      error={error}
                      errorType={Errors.PURPOSE_OF_LOAN}
                      handler={(purposeOfLoan) =>
                        addSelectorsData(
                          "purposeOfLoan",
                          purposeOfLoan,
                          Errors.PURPOSE_OF_LOAN,
                          {
                            slideName: "Purpose of Loan",
                            slideIndex: 5,
                            slideIcon: "PurposeOfLoan",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      icon={<GrAccessibility sx={{ fontSize: "30px" }} />}
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                </div>
              </div>
            </Carousel.Item>
            {/* Marital Status Slide *** Selector *** */}
            <Carousel.Item id="7">
              <div className="carouselSlideWrapper">
                <div className="typographyContainer">
                  <h1>Marital Status</h1>
                  <p>Please select your marital status.</p>
                </div>
                <div className="genderInputContainer dataFieldsContainer selectInputContainer">
                  <div className="marriedStatusBtnBox">
                    <SelectorButton
                      label="Unmarried"
                      elementValue={selectorValues.maritalStatus}
                      error={error}
                      errorType={Errors.MARITAL_STATUS_ERROR_TYPE}
                      handler={(maritalStatus) =>
                        addSelectorsData(
                          "marital_status",
                          maritalStatus,
                          Errors.MARITAL_STATUS_ERROR_TYPE,
                          {
                            slideName: "Marital Status",
                            slideIndex: 6,
                            slideIcon: "MaritalStatus",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                  <div className="marriedStatusBtnBox">
                    <SelectorButton
                      label="Married"
                      elementValue={selectorValues.maritalStatus}
                      error={error}
                      errorType={Errors.MARITAL_STATUS_ERROR_TYPE}
                      handler={(maritalStatus) =>
                        addSelectorsData(
                          "marital_status",
                          maritalStatus,
                          Errors.MARITAL_STATUS_ERROR_TYPE,
                          {
                            slideName: "Marital Status",
                            slideIndex: 6,
                            slideIcon: "MaritalStatus",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                </div>
              </div>
            </Carousel.Item>
            {/* Email Slide */}
            <Carousel.Item id="8">
              <div className="carouselSlideWrapper">
                {domainName !== "maxemocapital" ? (
                  <div className="typographyContainer">
                    <h1>Enter Your Email</h1>
                    <p>Please provide a correct personal or office email.</p>
                  </div>
                ): (
                  <div className="typographyContainer">
                  <h1>Your Email</h1>
                  <p>Please confirm your personal email and provide office email.</p>
                </div>
                )}
                <div className="emailInputContainer autoFieldsContainer">
                  {domainName === "maxemocapital" && (
                     <>
                     <div className="typographyContainer">
                       <p>Personal Email</p>
                       <h1>
                         {eqaroEmail}
                       </h1>
                     </div>
                   </>
                  )}
                  {!isEditProfEmail && customerDetails?.customerDetails?.email && domainName !== "maxemocapital" ? (
                     <>
                     <div className="typographyContainer">
                       <p>Personal Email</p>
                       <h1>
                         {domainName === "maxemocapital"
                           ? eqaroEmail
                           : customerDetails?.customerDetails?.email}
                       </h1>
                     </div>
                   </>
                  ) : isEditProfEmail && domainName !== "maxemocapital" &&  (
                    <TextInputField
                    type="text"
                    label="Personal Email"
                    placeholder="Email address"
                    name="email"
                    id="email"
                    reference={personalEmailRef}
                    handler={(personalEmail) =>
                      handleEmail(
                        personalEmail,
                        Errors.PERSONAL_EMAIL_ERROR_TYPE
                      )
                    }
                    required={true}
                    errorType={Errors.PERSONAL_EMAIL_ERROR_TYPE}
                    // onEnterHandler={addEmail}
                  />
                  )}
                  {/* {isEditProfEmail && domainName !== "maxemocapital" ? (
                    <TextInputField
                      type="text"
                      label="Personal Email"
                      placeholder="Email address"
                      name="email"
                      id="email"
                      reference={personalEmailRef}
                      handler={(personalEmail) =>
                        handleEmail(
                          personalEmail,
                          Errors.PERSONAL_EMAIL_ERROR_TYPE
                        )
                      }
                      required={true}
                      errorType={Errors.PERSONAL_EMAIL_ERROR_TYPE}
                     
                    />
                  ) : (
                    <>
                      <div className="typographyContainer">
                        <p>Personal Email</p>
                        <h1>
                          {domainName === "maxemocapital"
                            ? eqaroEmail
                            : customerDetails?.customerDetails?.email}
                        </h1>
                      </div>
                    </>
                  )} */}
                  {/* <TextInputField
                    type="text"
                    label="Personal Email"
                    placeholder="Email address"
                    name="email"
                    id="email"
                    reference={personalEmailRef}
                    disabled={
                      isEditEmail
                        ? true
                        : domainName === "maxemocapital"
                        ? true
                        : false
                    }
                    handler={(personalEmail) =>
                      handleEmail(
                        personalEmail,
                        Errors.PERSONAL_EMAIL_ERROR_TYPE
                      )
                    }
                    required={true}
                    errorType={Errors.PERSONAL_EMAIL_ERROR_TYPE}
                    // onEnterHandler={addEmail}
                  /> */}
                  {/* <div className="typographyContainer">
                    <p>Personal Email</p>
                    <h1>
                      {domainName === "maxemocapital"
                        ? eqaroEmail
                        : customerDetails?.customerDetails?.email}
                    </h1>
                  </div> */}
                  {domainName !== "maxemocapital" && (
                    <TextInputField
                      type="text"
                      label="Professional Email"
                      placeholder="Email address"
                      name="officeEmail"
                      id="officeEmail"
                      reference={professionalEmailRef}
                      handler={(professionalEmail) =>
                        handleEmail(
                          professionalEmail,
                          Errors.PROFESSIONAL_EMAIL_ERROR_TYPE
                        )
                      }
                      errorType={Errors.PROFESSIONAL_EMAIL_ERROR_TYPE}
                      // onEnterHandler={addEmail}
                    />
                  )}
                </div>

                <div className="emailContinueBtn">
                  <ContinueButton
                    loading={loading}
                    handler={addEmail}
                    isAutoFilled={domainName !== "maxemocapital" ? isEditProfEmail ? false : true : false}
                    // handler={() => setActiveSlide((prev) => prev + 1)}
                  />
                </div>
                {domainName !== "maxemocapital" && (
                  <div>
                  <EditButton
                    isEdit={isEditProfEmail}
                    handler={editProfEmailDetails}
                  />
                </div>
                )}
                
              </div>
            </Carousel.Item>
            {/* Education Qualifications Slide */}
            <Carousel.Item id="9">
              <div className="carouselSlideWrapper">
                <div className="typographyContainer">
                  <h1>Educational Qualification</h1>
                  <p>Select your maximum qualification.</p>
                </div>
                <div className="educationInputContainer dataFieldsContainer selectInputContainer">
                  {/* <DropDownField
                    label="Educational Qualification"
                    name="educationQualification"
                    reference={educationalQualificationRef}
                    required={true}
                    options={qualificationExamples}
                    errorType={Errors.EDUCATIONAL_QUALIFICATION_ERROR_TYPE}
                    nameKey="name"
                    valueKey="value"
                    handler={(value) =>
                      validateDropdownInputs(
                        value,
                        Errors.EDUCATIONAL_QUALIFICATION_ERROR_TYPE,
                        Errors.NULL_EDUCATIONAL_QUALIFICATION_ERROR
                      )
                    }
                  /> */}

                  {qualificationExamples.map((ele) => (
                    <div className="maleGenderBtnBox" key={ele.name}>
                      <SelectorButton
                        label={ele.name}
                        // elementValue={selectorValues.gender}
                        error={error}
                        errorType={Errors.EDUCATIONAL_QUALIFICATION_ERROR_TYPE}
                        handler={(education) =>
                          addSelectorsData(
                            "educational_qualification",
                            education,
                            Errors.EDUCATIONAL_QUALIFICATION_ERROR_TYPE,
                            {
                              slideName: "Educational Qualification",
                              slideIndex: 8,
                              slideIcon: "EducationalQualification",
                              totalSlides: TOTAL_SLIDES,
                              journeyVersion: JourneyVersion,
                            }
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Carousel.Item>
            {/* Employement type *** Selector *** */}
            <Carousel.Item id="10">
              {customerDetails?.company_type === "Proprietorship" ? (
                <GSTConsentSlides
                  switchToNextSlide={switchToNextSlide}
                  updateJourneyData={updateJourneyData}
                ></GSTConsentSlides>
              ) : (
                <div className="carouselSlideWrapper">
                  <div className="typographyContainer">
                    <h1>Employment Type</h1>
                    <p>Please select your employment type.</p>
                  </div>
                  <div className="employementType selectInputContainer">
                    <div className="employementTypeBtnBox">
                      <SelectorButton
                        label="Salaried"
                        elementValue={selectorValues.employementType}
                        error={error}
                        errorType={Errors.EMPLOYEMENT_TYPE_ERROR}
                        handler={(employementType) =>
                          addSelectorsData(
                            "employment_type",
                            employementType,
                            Errors.EMPLOYEMENT_TYPE_ERROR,
                            {
                              slideName: "Employement Type",
                              slideIndex: 9,
                              slideIcon: "EmployementType",
                              totalSlides: TOTAL_SLIDES,
                              journeyVersion: JourneyVersion,
                            }
                          )
                        }
                        icon={<CurrencyRupeeIcon sx={{ fontSize: "30px" }} />}
                      />
                    </div>
                    <div className="employementTypeBtnBox">
                      <SelectorButton
                        label="Self-Employed"
                        elementValue={selectorValues.employementType}
                        error={error}
                        errorType={Errors.EMPLOYEMENT_TYPE_ERROR}
                        handler={(employementType) =>
                          addSelectorsData(
                            "employment_type",
                            employementType,
                            Errors.EMPLOYEMENT_TYPE_ERROR,
                            {
                              slideName: "Employement Type",
                              slideIndex: 9,
                              slideIcon: "EmployementType",
                              totalSlides: TOTAL_SLIDES,
                              journeyVersion: JourneyVersion,
                            }
                          )
                        }
                        icon={
                          <BusinessCenterRoundedIcon
                            sx={{ fontSize: "30px" }}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </Carousel.Item>
            {/* Monthly Income Slide */}
            <Carousel.Item id="11">
              <div className="carouselSlideWrapper">
                <div className="typographyContainer">
                  <h1>Net Monthly Income</h1>
                  <p>Amount credited in your bank account</p>
                </div>
                <div className="monthlyIncomeInputContainer">
                  <TextInputField
                    type="text"
                    label="Net Monthly Income"
                    placeholder="Your monthly income"
                    name="monthlyIncome"
                    id="monthlyIncome"
                    reference={monthlyIncomeRef}
                    handler={handleMonthlyIncome}
                    required={true}
                    errorType={Errors.MONTHLY_INCOME_ERROR_TYPE}
                    // onEnterHandler={addMonthlyIncome}
                  />
                  {customerDetails?.employment_type !== "Self-Employed" ? (
                    <DropDownField
                      label="Salary Credit Type"
                      name="salaryCreditTypes"
                      reference={salaryTypeRef}
                      required={true}
                      options={salaryCreditTypes}
                      nameKey="name"
                      valueKey="value"
                      errorType={Errors.SALARY_CREDIT_TYPE_ERROR_TYPE}
                      handler={(value) =>
                        validateDropdownInputs(
                          value,
                          Errors.SALARY_CREDIT_TYPE_ERROR_TYPE,
                          Errors.NULL_SALARY_CREDIT_TYPE_ERROR
                        )
                      }
                    />
                  ) : null}
                </div>
                <div className="monthlyIncomeContinueBtn">
                  <ContinueButton
                    loading={loading}
                    handler={addMonthlyIncome}
                    // handler={() => setActiveSlide((prev) => prev + 1)}
                  />
                </div>
              </div>
            </Carousel.Item>
            {/* Communication Address Details */}
            <Carousel.Item id="12">
              <div className="carouselSlideWrapper residentAddressSlideWrapper">
                <div className="typographyContainer">
                  <h1>Communication Address</h1>
                  {console.log(
                    "Communication Address=>",
                    customerDetails?.customerDetails
                  )}
                </div>
                <div className="residentialInputContainer dataFieldsContainer">
                  {JourneyVersion === "J3" ? (
                    <TextInputField
                      type="text"
                      label="Shop Name"
                      placeholder="Shop Name"
                      name="shopName"
                      id="shopName"
                      reference={shopNameRef}
                      required={true}
                      errorType={Errors.SHOP_NAME_ERROR_TYPE}
                      handler={(shopName) => validateShopName(shopName)}
                    />
                  ) : null}
                  {console.log('communication address=>',isEditCommAddress)}
                  {!isEditCommAddress && customerDetails?.customerDetails?.address ? (
                       <div className="typographyContainer">
                       <p>Address Line 1</p>
                       <h1>{customerDetails?.customerDetails?.address}</h1>
                     </div>
                  ): isEditCommAddress && (
                    <TextInputField
                      type="text"
                      label="Address Line 1"
                      placeholder="Address"
                      name="address1"
                      id="address1"
                      reference={residentialAddress1Ref}
                      required={true}
                      // disabled={isEditAddress ? true : false}
                      errorType={Errors.RESIDENTIAL_ADDRESS1_ERROR_TYPE}
                      handler={(address1) =>
                        validateAddress(
                          address1,
                          Errors.RESIDENTIAL_ADDRESS1_ERROR_TYPE,
                          Errors.RESIDENTIAL_ADDRESS1_ERROR
                        )
                      }
                    />
                  )}
                  {/* {isEditCommAddress? (
                    <TextInputField
                      type="text"
                      label="Address Line 1"
                      placeholder="Address"
                      name="address1"
                      id="address1"
                      reference={residentialAddress1Ref}
                      required={true}
                      // disabled={isEditAddress ? true : false}
                      errorType={Errors.RESIDENTIAL_ADDRESS1_ERROR_TYPE}
                      handler={(address1) =>
                        validateAddress(
                          address1,
                          Errors.RESIDENTIAL_ADDRESS1_ERROR_TYPE,
                          Errors.RESIDENTIAL_ADDRESS1_ERROR
                        )
                      }
                    />
                  ) : (
                    <div className="typographyContainer">
                      <p>Address Line 1</p>
                      <h1>{customerDetails?.customerDetails?.address}</h1>
                    </div>
                  )} */}
                  {/* <TextInputField
                    type="text"
                    label="Address Line 1"
                    placeholder="Address"
                    name="address1"
                    id="address1"
                    reference={residentialAddress1Ref}
                    required={true}
                    disabled={isEditAddress ? true : false}
                    errorType={Errors.RESIDENTIAL_ADDRESS1_ERROR_TYPE}
                    handler={(address1) =>
                      validateAddress(
                        address1,
                        Errors.RESIDENTIAL_ADDRESS1_ERROR_TYPE,
                        Errors.RESIDENTIAL_ADDRESS1_ERROR
                      )
                    }
                  /> */}
                  {/* <div className="typographyContainer">
                    <p>Address Line 1</p>
                    <h1>{customerDetails?.customerDetails?.address}</h1>
                  </div> */}
                  {!isEditCommAddress && customerDetails?.customerDetails?.address2 ? (
                       <div className="typographyContainer">
                       <p>Address Line 2</p>
                       <h1>{customerDetails?.customerDetails?.address2}</h1>
                     </div>
                  ) : isEditCommAddress && (
                    <TextInputField
                    type="text"
                    label="Address Line 2"
                    placeholder="Address"
                    name="address2"
                    id="address2"
                    required={true}
                    // disabled={isEditAddress ? true : false}
                    reference={residentialAddress2Ref}
                    errorType={Errors.RESIDENTIAL_ADDRESS2_ERROR_TYPE}
                    handler={(address2) =>
                      validateAddress(
                        address2,
                        Errors.RESIDENTIAL_ADDRESS2_ERROR_TYPE,
                        Errors.RESIDENTIAL_ADDRESS2_ERROR
                      )
                    }
                  />
                  )}
                  {/* {isEditCommAddress ? (
                    <TextInputField
                      type="text"
                      label="Address Line 2"
                      placeholder="Address"
                      name="address2"
                      id="address2"
                      required={true}
                      // disabled={isEditAddress ? true : false}
                      reference={residentialAddress2Ref}
                      errorType={Errors.RESIDENTIAL_ADDRESS2_ERROR_TYPE}
                      handler={(address2) =>
                        validateAddress(
                          address2,
                          Errors.RESIDENTIAL_ADDRESS2_ERROR_TYPE,
                          Errors.RESIDENTIAL_ADDRESS2_ERROR
                        )
                      }
                    />
                  ) : (
                    <div className="typographyContainer">
                      <p>Address Line 2</p>
                      <h1>{customerDetails?.customerDetails?.address2}</h1>
                    </div>
                  )} */}
                  {/* <TextInputField
                    type="text"
                    label="Address Line 2"
                    placeholder="Address"
                    name="address2"
                    id="address2"
                    required={true}
                    disabled={isEditAddress ? true : false}
                    reference={residentialAddress2Ref}
                    errorType={Errors.RESIDENTIAL_ADDRESS2_ERROR_TYPE}
                    handler={(address2) =>
                      validateAddress(
                        address2,
                        Errors.RESIDENTIAL_ADDRESS2_ERROR_TYPE,
                        Errors.RESIDENTIAL_ADDRESS2_ERROR
                      )
                    }
                  /> */}
                  {/* <div className="typographyContainer">
                    <p>Address Line 2</p>
                    <h1>{customerDetails?.customerDetails?.address2}</h1>
                  </div> */}
                  {/* <TextInputField
                    type="number"
                    label="Pincode"
                    placeholder="Pin"
                    name="pin"
                    id="pin"
                    reference={residentialPinRef}
                    required={true}
                    disabled={isEditAddress ? true : false}
                    maxLength={6}
                    errorType={Errors.RESIDENTIAL_PIN_ERROR_TYPE}
                    handler={(pin) =>
                      validatePinCode(
                        pin,
                        Errors.RESIDENTIAL_PIN_ERROR_TYPE,
                        residentialPinRef,
                        residentialCityRef,
                        residentialStateRef
                      )
                    }
                  /> */}
                  {!isEditCommAddress && customerDetails?.customerDetails?.pincode ? (
                     <div className="typographyContainer">
                     <p>Pincode</p>
                     <h1>{customerDetails?.customerDetails?.pincode}</h1>
                   </div>
                  ): isEditCommAddress && (
                    <TextInputField
                    type="number"
                    label="Pincode"
                    placeholder="Pin"
                    name="pin"
                    id="pin"
                    reference={residentialPinRef}
                    required={true}
                    // disabled={isEditCommAddress ? true : false}
                    maxLength={6}
                    errorType={Errors.RESIDENTIAL_PIN_ERROR_TYPE}
                    handler={(pin) =>
                      validatePinCode(
                        pin,
                        Errors.RESIDENTIAL_PIN_ERROR_TYPE,
                        residentialPinRef,
                        residentialCityRef,
                        residentialStateRef
                      )
                    }
                  />
                  )}
                  {/* {isEditCommAddress  ? (
                    <TextInputField
                      type="number"
                      label="Pincode"
                      placeholder="Pin"
                      name="pin"
                      id="pin"
                      reference={residentialPinRef}
                      required={true}
                      // disabled={isEditCommAddress ? true : false}
                      maxLength={6}
                      errorType={Errors.RESIDENTIAL_PIN_ERROR_TYPE}
                      handler={(pin) =>
                        validatePinCode(
                          pin,
                          Errors.RESIDENTIAL_PIN_ERROR_TYPE,
                          residentialPinRef,
                          residentialCityRef,
                          residentialStateRef
                        )
                      }
                    />
                  ) : (
                    <div className="typographyContainer">
                      <p>Pincode</p>
                      <h1>{customerDetails?.customerDetails?.pincode}</h1>
                    </div>
                  )} */}
                  {/* <div className="typographyContainer">
                    <p>Pincode</p>
                    <h1>{customerDetails?.customerDetails?.pincode}</h1>
                  </div> */}
                  {/* <TextInputField
                    type="text"
                    label="City"
                    placeholder="City"
                    name="city"
                    id="city"
                    reference={residentialCityRef}
                    required={true}
                    disabled={isEditAddress ? true : false}
                    errorType={Errors.RESIDENTIAL_CITY_ERROR_TYPE}
                    handler={(residentialCity) =>
                      validateCityAndState(
                        residentialCity,
                        Errors.RESIDENTIAL_CITY_ERROR_TYPE
                      )
                    }
                    onEnterHandler={addResidentialAddress}
                  /> */}
                  {!isEditCommAddress && customerDetails?.customerDetails?.city ? (
                       <div className="typographyContainer">
                       <p>City</p>
                       <h1>{customerDetails?.customerDetails?.city}</h1>
                     </div>
                  ) :isEditCommAddress && (
                    <TextInputField
                    type="text"
                    label="City"
                    placeholder="City"
                    name="city"
                    id="city"
                    reference={residentialCityRef}
                    required={true}
                    // disabled={isEditCommAddress ? true : false}
                    errorType={Errors.RESIDENTIAL_CITY_ERROR_TYPE}
                    handler={(residentialCity) =>
                      validateCityAndState(
                        residentialCity,
                        Errors.RESIDENTIAL_CITY_ERROR_TYPE
                      )
                    }
                    onEnterHandler={addResidentialAddress}
                  />
                  )}
                  {/* {isEditCommAddress ? (
                    <TextInputField
                      type="text"
                      label="City"
                      placeholder="City"
                      name="city"
                      id="city"
                      reference={residentialCityRef}
                      required={true}
                      // disabled={isEditCommAddress ? true : false}
                      errorType={Errors.RESIDENTIAL_CITY_ERROR_TYPE}
                      handler={(residentialCity) =>
                        validateCityAndState(
                          residentialCity,
                          Errors.RESIDENTIAL_CITY_ERROR_TYPE
                        )
                      }
                      onEnterHandler={addResidentialAddress}
                    />
                  ) : (
                    <div className="typographyContainer">
                      <p>City</p>
                      <h1>{customerDetails?.customerDetails?.city}</h1>
                    </div>
                  )} */}
                  {/* <div className="typographyContainer">
                    <p>City</p>
                    <h1>{customerDetails?.customerDetails?.city}</h1>
                  </div> */}
                  {/* <TextInputField
                    type="text"
                    label="State"
                    placeholder="State"
                    name="state"
                    id="state"
                    reference={residentialStateRef}
                    required={true}
                    disabled={isEditAddress ? true : false}
                    errorType={Errors.RESIDENTIAL_STATE_ERROR_TYPE}
                    handler={(residentialCity) =>
                      validateCityAndState(
                        residentialCity,
                        Errors.RESIDENTIAL_STATE_ERROR_TYPE
                      )
                    }
                    onEnterHandler={addResidentialAddress}
                  /> */}
                  {!isEditCommAddress && customerDetails?.customerDetails?.state ? (
                     <div className="typographyContainer">
                     <p>State</p>
                     <h1>{customerDetails?.customerDetails?.state}</h1>
                   </div>
                  ) : !isEditCommAddress && (
                    <TextInputField
                    type="text"
                    label="State"
                    placeholder="State"
                    name="state"
                    id="state"
                    reference={residentialStateRef}
                    required={true}
                    // disabled={isEditCommAddress ? true : false}
                    errorType={Errors.RESIDENTIAL_STATE_ERROR_TYPE}
                    handler={(residentialCity) =>
                      validateCityAndState(
                        residentialCity,
                        Errors.RESIDENTIAL_STATE_ERROR_TYPE
                      )
                    }
                    onEnterHandler={addResidentialAddress}
                  />
                  )}
                  {/* {isEditCommAddress ? (
                    <TextInputField
                      type="text"
                      label="State"
                      placeholder="State"
                      name="state"
                      id="state"
                      reference={residentialStateRef}
                      required={true}
                      // disabled={isEditCommAddress ? true : false}
                      errorType={Errors.RESIDENTIAL_STATE_ERROR_TYPE}
                      handler={(residentialCity) =>
                        validateCityAndState(
                          residentialCity,
                          Errors.RESIDENTIAL_STATE_ERROR_TYPE
                        )
                      }
                      onEnterHandler={addResidentialAddress}
                    />
                  ) : (
                    <div className="typographyContainer">
                      <p>State</p>
                      <h1>{customerDetails?.customerDetails?.state}</h1>
                    </div>
                  )} */}
                  {/* <div className="typographyContainer">
                    <p>State</p>
                    <h1>{customerDetails?.customerDetails?.state}</h1>
                  </div> */}
                  {/* <DropDownField
                    label="Address Type"
                    name="addressType"
                    reference={residenceAddressTypeRef}
                    required={true}
                    options={addressTypes}
                    nameKey="name"
                    valueKey="value"
                    errorType={Errors.RESIDENTIAL_ADDRESS_TYPE_ERROR_TYPE}
                    handler={(value) =>
                      validateDropdownInputs(
                        value,
                        Errors.RESIDENTIAL_ADDRESS_TYPE_ERROR_TYPE,
                        Errors.RESIDENTIAL_NULL_ADDRESS_TYPE_PINCODE_ERROR
                      )
                    }
                  /> */}
                  {JourneyVersion !== "J3" ? (
                    <>
                      <DropDownField
                        label="Nature of Address"
                        name="addressNature"
                        reference={residenceNatureOfAddressRef}
                        required={true}
                        options={residenceNature}
                        nameKey="name"
                        valueKey="value"
                        errorType={
                          Errors.RESIDENTIAL_NATURE_OF_ADDRESS_ERROR_TYPE
                        }
                        handler={(value) =>
                          validateDropdownInputs(
                            value,
                            Errors.RESIDENTIAL_NATURE_OF_ADDRESS_ERROR_TYPE,
                            Errors.RESIDENTIAL_NULL_NATURE_OF_ADDRESS_ERROR
                          )
                        }
                      />
                      <TextInputField
                        type="text"
                        label="Months at Current Address"
                        placeholder="Months at Current Address"
                        name="monthsAtCurrentAddress"
                        id="monthsAtCurrentAddress"
                        reference={residenceYearsAtCurrentAddressRef}
                        required={true}
                        errorType={
                          Errors.RESIDENTIAL_YEARS_AT_CURRENT_ADDRESS_ERROR_TYPE
                        }
                        handler={(monthsAtCurrentAddress) =>
                          validateMonthsAtCurrentAddress(monthsAtCurrentAddress)
                        }
                        onEnterHandler={addResidentialAddress}
                      />
                      <div className="permanentAddressConfirmation">
                        <label htmlFor="communicaitonAddresssUse">
                          Use this address as permanent address as well.
                        </label>
                        <input
                          type="checkbox"
                          id="communicaitonAddresssUse"
                          ref={useResidentialAddressAsPermanentRef}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="residentContinueBtn">
                  <ContinueButton
                    loading={loading}
                    isAutoFilled={isEditCommAddress ? false : true}
                    handler={addResidentialAddress}
                    // handler={() => setActiveSlide((prev) => prev + 1)}
                  />
                </div>
                <div>
                  {/* {customerDetails?.communication_address?.line_1 ? ( */}
                  <EditButton isEdit={isEditCommAddress} handler={editCommAddressHandler} />
                  {/* ) : (
                    ""
                  )} */}
                </div>
              </div>
            </Carousel.Item>
            {/* Permanent Address Details */}
            <Carousel.Item id="13">
              <div className="carouselSlideWrapper officeAddressSlideWrapper">
                <div className="typographyContainer">
                  <h1>Permanent Address</h1>
                </div>
                <div className="officeAddressFields dataFieldsContainer">
                  <TextInputField
                    type="text"
                    label="Address Line 1"
                    placeholder="Address"
                    name="permanentsAddress1"
                    id="permanentsAddress1"
                    reference={permanentAddress1Ref}
                    required={true}
                    errorType={Errors.PERMANENT_ADDRESS1_ERROR_TYPE}
                    handler={(address1) =>
                      validateAddress(
                        address1,
                        Errors.PERMANENT_ADDRESS1_ERROR_TYPE,
                        Errors.PERMANENT_ADDRESS1_ERROR
                      )
                    }
                    // onEnterHandler={addOfficeAddress}
                  />
                  <TextInputField
                    type="text"
                    label="Address Line 2"
                    placeholder="Address"
                    name="permanentAddress2"
                    id="permanentAddress2"
                    reference={permanentAddress2Ref}
                    required={true}
                    errorType={Errors.PERMANENT_ADDRESS2_ERROR_TYPE}
                    handler={(address2) =>
                      validateAddress(
                        address2,
                        Errors.PERMANENT_ADDRESS2_ERROR_TYPE,
                        Errors.PERMANENT_ADDRESS2_ERROR
                      )
                    }
                    // onEnterHandler={addOfficeAddress}
                  />
                  <TextInputField
                    type="number"
                    label="Pincode"
                    placeholder="Pin"
                    name="permanentAddressPin"
                    id="permanentAddressPin"
                    reference={permanentPinRef}
                    required={true}
                    maxLength={6}
                    errorType={Errors.PERMANENT_PIN_ERROR_TYPE}
                    handler={(pin) =>
                      validatePinCode(
                        pin,
                        Errors.PERMANENT_PIN_ERROR_TYPE,
                        permanentPinRef,
                        permanentCityRef,
                        permanentStateRef
                      )
                    }
                    // onEnterHandler={addOfficeAddress}
                  />
                  <TextInputField
                    type="text"
                    label="City"
                    placeholder="City"
                    name="permanentAddressCity"
                    id="permanentAddressCity"
                    reference={permanentCityRef}
                    required={true}
                    errorType={Errors.PERMANENT_CITY_ERROR_TYPE}
                    handler={(city) =>
                      validateCityAndState(
                        city,
                        Errors.PERMANENT_CITY_ERROR_TYPE
                      )
                    }
                    onEnterHandler={addOfficeAddress}
                  />
                  <TextInputField
                    type="text"
                    label="State"
                    placeholder="State"
                    name="permanentAddressState"
                    id="permanentAddressState"
                    reference={permanentStateRef}
                    required={true}
                    errorType={Errors.PERMANENT_STATE_ERROR_TYPE}
                    handler={(state) =>
                      validateCityAndState(
                        state,
                        Errors.PERMANENT_STATE_ERROR_TYPE
                      )
                    }
                    onEnterHandler={addOfficeAddress}
                  />
                </div>
                <div className="officeAddressContinueBtn">
                  <ContinueButton
                    loading={loading}
                    handler={addPermanentAddress}
                  />
                </div>
              </div>
            </Carousel.Item>
            {/* Office Address Details */}
            <Carousel.Item id="14">
              <div className="carouselSlideWrapper officeAddressSlideWrapper">
                <div className="typographyContainer">
                  <h1>
                    {customerDetails?.company_type === "Proprietorship"
                      ? "Business Address"
                      : "Office Address"}
                  </h1>
                </div>
                <div className="officeAddressFields dataFieldsContainer">
                  <TextInputField
                    type="text"
                    label="Address Line 1"
                    placeholder="Address"
                    name="officeAddress1"
                    id="officeAddress1"
                    reference={officeAddress1Ref}
                    required={true}
                    errorType={Errors.OFFICE_ADDRESS1_ERROR_TYPE}
                    handler={(address1) =>
                      validateAddress(
                        address1,
                        Errors.OFFICE_ADDRESS1_ERROR_TYPE,
                        Errors.OFFICE_ADDRESS1_ERROR
                      )
                    }
                    // onEnterHandler={addOfficeAddress}
                  />
                  <TextInputField
                    type="text"
                    label="Address Line 2"
                    placeholder="Address"
                    name="officeAddress2"
                    id="officeAddress2"
                    reference={officeAddress2Ref}
                    required={true}
                    errorType={Errors.OFFICE_ADDRESS2_ERROR_TYPE}
                    handler={(address2) =>
                      validateAddress(
                        address2,
                        Errors.OFFICE_ADDRESS2_ERROR_TYPE,
                        Errors.OFFICE_ADDRESS2_ERROR
                      )
                    }
                    // onEnterHandler={addOfficeAddress}
                  />
                  <TextInputField
                    type="number"
                    label="Pincode"
                    placeholder="Pin"
                    name="officePin"
                    id="officePin"
                    reference={officePinRef}
                    required={true}
                    maxLength={6}
                    errorType={Errors.OFFICE_PIN_ERROR_TYPE}
                    handler={(pin) =>
                      validatePinCode(
                        pin,
                        Errors.OFFICE_PIN_ERROR_TYPE,
                        officePinRef,
                        officeCityRef,
                        officeStateRef
                      )
                    }
                    // onEnterHandler={addOfficeAddress}
                  />
                  <TextInputField
                    type="text"
                    label="City"
                    placeholder="City"
                    name="officeAddressCity"
                    id="officeAddressCity"
                    reference={officeCityRef}
                    required={true}
                    errorType={Errors.OFFICE_CITY_ERROR_TYPE}
                    handler={(officeCity) =>
                      validateCityAndState(
                        officeCity,
                        Errors.OFFICE_CITY_ERROR_TYPE
                      )
                    }
                    onEnterHandler={addOfficeAddress}
                  />
                  <TextInputField
                    type="text"
                    label="State"
                    placeholder="State"
                    name="officeAddressState"
                    id="officeAddressState"
                    reference={officeStateRef}
                    required={true}
                    errorType={Errors.OFFICE_STATE_ERROR_TYPE}
                    handler={(officeState) =>
                      validateCityAndState(
                        officeState,
                        Errors.OFFICE_STATE_ERROR_TYPE
                      )
                    }
                    onEnterHandler={addOfficeAddress}
                  />
                </div>
                <div className="officeAddressContinueBtn">
                  <ContinueButton
                    loading={loading}
                    handler={addOfficeAddress}
                    // handler={() => setActiveSlide((prev) => prev + 1)}
                  />
                </div>
              </div>
            </Carousel.Item>
            {/* Company Details Slide */}
            <Carousel.Item id="15">
              <div className="carouselSlideWrapper loanAmountSlide">
                <div className="typographyContainer">
                  <h1>Company Details</h1>
                  <p>Please enter your company details</p>
                </div>
                <div className="companyName dataFieldsContainer">
                  {customerDetails?.customerDetails?.customer_type ===
                  "Proprietorship" ? (
                    <div className="typographyContainer">
                      <p>Company Name</p>
                      <h1>
                        {
                          customerDetails?.customerDetails
                            ?.ProprietorBusinessDetails[0]?.company_name
                        }
                      </h1>
                    </div>
                  ) : (
                    <TextInputField
                      type="text"
                      label="Company Name"
                      placeholder="Company name"
                      name="companyName"
                      id="companyName"
                      reference={companyNameRef}
                      required={true}
                      errorType={Errors.COMPANY_NAME_ERROR_TYPE}
                      handler={(companyName) =>
                        validateCompanyName(
                          companyName,
                          Errors.COMPANY_TYPE_ERROR_TYPE,
                          Errors.NULL_COMPANY_TYPE_ERROR
                        )
                      }
                      // onEnterHandler={addCompanyDetails}
                    />
                  )}
                  {customerDetails?.customerDetails?.customer_type ===
                  "Proprietorship" ? (
                    <div className="typographyContainer">
                      <p>Company Type</p>
                      <h1>{customerDetails?.customerDetails?.company_type}</h1>
                    </div>
                  ) : (
                    <DropDownField
                      label="Company Type"
                      name="companyType"
                      reference={companyTypeRef}
                      required={true}
                      options={companyTypes}
                      nameKey="name"
                      valueKey="value"
                      errorType={Errors.COMPANY_TYPE_ERROR_TYPE}
                      handler={(value) =>
                        validateDropdownInputs(
                          value,
                          Errors.COMPANY_TYPE_ERROR_TYPE,
                          Errors.NULL_COMPANY_TYPE_ERROR
                        )
                      }
                    />
                  )}

                  {customerDetails?.employment_type === "Salaried" ? (
                    <TextInputField
                      type="text"
                      label="Designation"
                      placeholder="Designation"
                      name="designation"
                      id="designation"
                      reference={designationRef}
                      required={true}
                      errorType={Errors.DESIGNATION_ERROR_TYPE}
                      handler={(designation) =>
                        validateDesignation(designation)
                      }
                    />
                  ) : null}
                  <TextInputField
                    type="number"
                    label="Total Work Experience (In Years)"
                    placeholder="Experience"
                    name="experience"
                    id="experience"
                    reference={totalWorkExperienceRef}
                    required={true}
                    errorType={Errors.WORK_EXPERIENCE_ERROR_TYPE}
                    handler={(workExperience) =>
                      validateWorkExperience(workExperience)
                    }
                  />
                </div>
                <div className="companyNameContinueBtn">
                  <ContinueButton
                    loading={loading}
                    handler={addCompanyDetails}
                  />
                </div>
              </div>
            </Carousel.Item>
            {/* Business Details */}
            <Carousel.Item id="16">
              <div className="carouselSlideWrapper businessDetailsWrapper">
                <div className="typographyContainer">
                  <h1>Professional Details</h1>
                  <p>Please fill all the details carefully.</p>
                </div>
                <div className="businessDetailsFields dataFieldsContainer">
                  <DropDownField
                    label="Profession Type"
                    name="natureOfBusiness"
                    reference={natureOfBusinessRef}
                    required={true}
                    options={natureOfBusinessOptions}
                    nameKey="name"
                    valueKey="value"
                    errorType={Errors.BUSSINES_NATURE_ERROR_TYPE}
                    handler={(value) =>
                      validateDropdownInputs(
                        value,
                        Errors.BUSSINES_NATURE_ERROR_TYPE,
                        Errors.BUSINESS_NATURE_ERROR
                      )
                    }
                  />
                  {/* <TextInputField
                    type="date"
                    label="Date of Incorporation"
                    placeholder="Incorporation Date"
                    name="incorporationDate"
                    id="incorporationDate"
                    maxLength={`${new Date().getFullYear()}-01-01`}
                    reference={incorporationDateRef}
                    required={true}
                    errorType={Errors.INCORPORATION_DATE_ERROR_TYPE}
                    handler={(businessCorporationDate) =>
                      handleDateOfIncorporation(businessCorporationDate)
                    }
                  /> */}
                </div>
                <div className="businessDetailsContinueBtn">
                  <ContinueButton
                    loading={loading}
                    handler={addBusinessDetails}
                  />
                </div>
              </div>
            </Carousel.Item>
            {/* Want to add Co-Applicant *** Selector *** */}
            <Carousel.Item id="17">
              <div className="carouselSlideWrapper askAddCoApplicantWrapper">
                <div className="typographyContainer">
                  <h1>Do you have any Co-Applicant?</h1>
                  <p>Add a Co-Applicant if you have.</p>
                </div>
                <div className="askAddCoApplicantFields selectInputContainer">
                  <div className="addCoApplicant">
                    <SelectorButton
                      label="Yes Add Co-Applicant"
                      error={error}
                      errorType={Errors.CO_APPLICANT_TYPE_ERROR}
                      elementValue={selectorValues.isCoApplicantAvailable}
                      handler={(isCoApplicantAvailable) =>
                        addSelectorsData(
                          "has_coApplicant",
                          isCoApplicantAvailable,
                          Errors.CO_APPLICANT_TYPE_ERROR,
                          {
                            slideName: "Want to add Co-Applicant",
                            slideIndex: 15,
                            slideIcon: "WantToAddCoApplicant",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                  <div className="noCoApplicant">
                    <SelectorButton
                      label="No I don't have a Co-Applicant"
                      error={error}
                      errorType={Errors.CO_APPLICANT_TYPE_ERROR}
                      elementValue={selectorValues.isCoApplicantAvailable}
                      handler={(isCoApplicantAvailable) =>
                        addSelectorsData(
                          "has_coApplicant",
                          isCoApplicantAvailable,
                          Errors.CO_APPLICANT_TYPE_ERROR,
                          {
                            slideName: "Want to add Co-Applicant",
                            slideIndex: 15,
                            slideIcon: "WantToAddCoApplicant",
                            totalSlides: TOTAL_SLIDES,
                            journeyVersion: JourneyVersion,
                          }
                        )
                      }
                      // handler={() => setActiveSlide((prev) => prev + 1)}
                    />
                  </div>
                </div>
              </div>
            </Carousel.Item>
            {/* Add Co-Applicatnt Details*/}
            <Carousel.Item id="18">
              <div className="carouselSlideWrapper addCoApplicantSlide">
                <div className="typographyContainer">
                  <h1>Add Co-Applicant</h1>
                  <p>Tell us about your co-applicant.</p>
                </div>
                <div className="addCoApplicantDetails dataFieldsContainer">
                  {/* Co-Applicant Mobile Number Input Field */}
                  <TextInputField
                    type="text"
                    label="Name"
                    placeholder="Co-Applicant Name"
                    name="coApplicantName"
                    id="coApplicantName"
                    reference={coApplicantNameRef}
                    required={true}
                    errorType={Errors.CO_APPLICANT_FULLNAME_ERROR_TYPE}
                    handler={(coApplicantFullName) =>
                      handleFullName(
                        coApplicantFullName,
                        Errors.CO_APPLICANT_FULLNAME_ERROR_TYPE
                      )
                    }
                    // onEnterHandler={addCoApplicantData}
                  />
                  {/* Co-Applicant Date of Birth Input Field */}
                  <TextInputField
                    type="date"
                    label="Date of Birth"
                    placeholder="Date of Birth"
                    name="coApplicantDOB"
                    id="coApplicantDOB"
                    reference={coApplicantDOBRef}
                    required={true}
                    errorType={Errors.CO_APPLICANT_DOB_ERROR_TYPE}
                    // handler={(dob) => handlePanDetails(dob, dobRef)}
                    handler={(coApplicantDOB) =>
                      handleDOB(
                        coApplicantDOB,
                        Errors.CO_APPLICANT_DOB_ERROR_TYPE
                      )
                    }
                    // onEnterHandler={addCoApplicantData}
                  />
                  {/* Co-Applicant PAN Input Field */}
                  <TextInputField
                    type="text"
                    label="PAN"
                    maxLength="10"
                    min="10"
                    placeholder="Co-Applicant PAN"
                    name="coApplicantPan"
                    id="coApplicantPan"
                    reference={coApplicantPANRef}
                    required={true}
                    uppercase={true}
                    errorType={Errors.CO_APPLICANT_PAN_ERROR_TYPE}
                    handler={(coApplicantPAN) =>
                      handlePAN(
                        coApplicantPAN,
                        Errors.CO_APPLICANT_PAN_ERROR_TYPE
                      )
                    }
                    // onEnterHandler={addCoApplicantData}
                  />
                </div>
                <div className="residentContinueBtn">
                  <ContinueButton
                    loading={loading}
                    handler={addCoApplicantData}
                    // handler={() => setActiveSlide((prev) => prev + 1)}
                  />
                </div>
              </div>
            </Carousel.Item>
            {/* Add more Co-Applicant Details */}
            <Carousel.Item id="19">
              <div className="carouselSlideWrapper addCoApplicantSlide">
                <div className="typographyContainer">
                  <h1>Little more details</h1>
                  <p>Tell us more about your co-applicant.</p>
                </div>

                <div className="moreCoApplicantDetails dataFieldsContainer">
                  {/* Co-Applicant Mobile Number */}
                  <TextInputField
                    type="text"
                    maxLength="10"
                    minLength="10"
                    label="Mobile Number"
                    placeholder="Enter 10 digit mobile number"
                    name="coApplicantMobile"
                    id="coApplicantMobile"
                    reference={coApplicantMobileRef}
                    required={true}
                    errorType={Errors.CO_APPLICANT_MOBILE_ERROR_TYPE}
                    handler={(coApplicantMobile) =>
                      validateMobileNumber(
                        coApplicantMobile,
                        Errors.CO_APPLICANT_MOBILE_ERROR_TYPE,
                        "coApplicant"
                      )
                    }
                  />
                  {/* Co-Applicant Email Address */}
                  <TextInputField
                    type="email"
                    label="Personal Email"
                    placeholder="Email Address"
                    name="coApplicantEmail"
                    id="coApplicantEmail"
                    reference={coApplicantEmailRef}
                    required={true}
                    errorType={Errors.CO_APPLICANT_EMAIL_ERROR_TYPE}
                    handler={(coApplicantEmail) =>
                      handleEmail(
                        coApplicantEmail,
                        Errors.CO_APPLICANT_EMAIL_ERROR_TYPE
                      )
                    }
                  />
                  {/* Relationship with Co-Applicant */}
                  <DropDownField
                    label="Relation with Co-Applicant"
                    name="coApplicantRelation"
                    required={true}
                    reference={coApplicantRelationRef}
                    options={coApplicantRelations}
                    nameKey="name"
                    valueKey="value"
                    errorType={Errors.CO_APPLICANT_RELATION_ERROR_TYPE}
                    handler={(value) =>
                      validateDropdownInputs(
                        value,
                        Errors.CO_APPLICANT_RELATION_ERROR_TYPE,
                        Errors.NULL_CO_APPLICANT_RELATION_ERROR
                      )
                    }
                  />
                  {/* Co-Applicant Gender */}
                  {/* <DropDownField
                    label="Gender"
                    name="coApplicantGender"
                    defaultValue="Gender"
                    required={true}
                    reference={coApplicantGenderRef}
                    // options={["Male", "Female"]}
                    options={genderOptions}
                    nameKey="name"
                    valueKey="value"
                  /> */}
                </div>

                <div className="moreCoApplicantDetailsContinueBtn">
                  <ContinueButton
                    loading={loading}
                    handler={addMoreCoApplicantDetails}
                  />
                </div>
              </div>
            </Carousel.Item>
            {/* Loan Amount */}
            <Carousel.Item id="20">
              <LoanAmount
                loanAmt={loanAmt}
                addLoanAmount={addLoanAmount}
                validateLoanAmount={validateLoanAmount}
                loading={loading}
              />
            </Carousel.Item>

            {/* Selfie Slide (Photo Liveness Check) */}
            <Carousel.Item id="21">
              <TakeSelfie
                switchToNextSlide={(slide) => switchToNextSlide(slide)}
                updateLastVisitSlide={handleUpdateLastVisitedSlide}
              />
            </Carousel.Item>

            {/* Salary Slip */}
            <Carousel.Item id="22">
              <SalarySlipVerificationSlide
                // customerSalaries={salary}
                // setSalary={setSalary}
                switchToNextSlide={switchToNextSlide}
                updateLastVisitSlide={handleUpdateLastVisitedSlide}
              />
            </Carousel.Item>

            {/* Bank Statement */}
            <Carousel.Item id="23">
              <BankStatementVerification
                // switchToNextSlide={() => switchToNextSlide(activeSlide + 1)}
                // setSalariesInBankStatement={setSalariesInBankStatement}
                switchToNextSlide={switchToNextSlide}
                updateLastVisitSlide={handleUpdateLastVisitedSlide}
              />
            </Carousel.Item>

            {/* PAN Card */}
            <Carousel.Item id="24">
              <PanVerificationSlide
                switchToNextSlide={(slide) => switchToNextSlide(slide)}
                updateLastVisitSlide={handleUpdateLastVisitedSlide}
              />
            </Carousel.Item>

            {/* Address Verification */}
            <Carousel.Item id="25" style={{ minHeight: "90vh" }}>
              <CustomerAddressVerification
                activeSlide={activeSlide}
                switchToNextSlide={(slide) => switchToNextSlide(slide)}
                updateLastVisitSlide={handleUpdateLastVisitedSlide}
              />
            </Carousel.Item>

            {/* Aadhaar Card*/}
            <Carousel.Item id="26">
              <AadharVerificationSlide
                activeSlide={activeSlide}
                switchToNextSlide={(slide) => switchToNextSlide(slide)}
                updateLastVisitSlide={handleUpdateLastVisitedSlide}
              />
            </Carousel.Item>

            {/* Aadhaar Number */}
            <Carousel.Item id="27">
              <AadhaarNumberSlide
                switchToNextSlide={() => switchToNextSlide(activeSlide + 1)}
                updateLastVisitSlide={handleUpdateLastVisitedSlide}
              />
            </Carousel.Item>

            {/* Aadhaar OTP */}
            <Carousel.Item id="28">
              {activeSlide === 27 ? (
                <AadhaarOTPSlide
                  switchToNextSlide={(slide) => switchToNextSlide(slide)}
                  updateLastVisitSlide={handleUpdateLastVisitedSlide}
                  switchToPreviousSlide={() => goBack(activeSlide + 1)}
                />
              ) : null}
            </Carousel.Item>

            {/* Penny Drop */}
            <Carousel.Item id="29">
              {activeSlide === 28 ? (
                <PennyDrop
                  pipelineSettings={pipelineSettings}
                  switchToNextSlide={switchToNextSlide}
                  updateLastVisitSlide={handleUpdateLastVisitedSlide}
                  rejectLead={rejectLead}
                />
              ) : null}
            </Carousel.Item>

            {/* Offer Screen */}
            <Carousel.Item id="30">
              {activeSlide === 29 && (
                <LoanOffer
                  loanAmt={loanAmt}
                  tenure={tenure}
                  setAgreement={setAgreement}
                  pipelineSettings={pipelineSettings}
                  setSanctionLetter={setSanctionLetter}
                  updateLastVisitSlide={handleUpdateLastVisitedSlide}
                  switchToNextSlide={(slide) => switchToNextSlide(slide)}
                  handleSendLeadDetailViaEmail={handleSendLeadDetailViaEmail}
                  handleUpdateLeadStageStatus={handleUpdateLeadStageStatus}
                  smsTemplates={smsTemplates}
                  sendStageWiseSMS={handleSendStageWiseIndividualSms}
                  rejectLead={rejectLead}
                  handleCreateTimeline={handleCreateTimeline}
                />
              )}
            </Carousel.Item>

            {/* Sanction Letter */}
            <Carousel.Item id="31" style={{ height: "90vh" }}>
              {activeSlide === 30 && (
                <SanctionLetterSlide
                  loanAmt={loanAmt}
                  sanctionLetter={sanctionLetter}
                  updateLastVisitSlide={handleUpdateLastVisitedSlide}
                  switchToNextSlide={switchToNextSlide}
                />
              )}
            </Carousel.Item>

            {/* Sanction Letter Signature  */}
            <Carousel.Item id="32" style={{ minHeight: "90vh" }}>
              {activeSlide === 31 ? (
                <SanctionLetterSign
                  activeSlide={activeSlide}
                  sanctionLetter={sanctionLetter}
                  setSanctionLetter={setSanctionLetter}
                  updateLastVisitSlide={handleUpdateLastVisitedSlide}
                  switchToNextSlide={switchToNextSlide}
                  setSignedDocument={setSignedDocument}
                />
              ) : null}
            </Carousel.Item>

            {/* Signed Sanction Letter */}
            <Carousel.Item id="33" style={{ height: "90vh" }}>
              {activeSlide === 32 ? (
                <SignedDocument
                  title="Executed Sanction Letter"
                  documentType="sanctionLetter"
                  document={signedDocument.sanctionLetter}
                  switchToNextSlide={switchToNextSlide}
                  updateLastVisitSlide={handleUpdateLastVisitedSlide}
                />
              ) : null}
            </Carousel.Item>

            {/* Agreement */}
            <Carousel.Item id="34" style={{ height: "90vh" }}>
              {activeSlide === 33 && (
                <AgreementSlide
                  agreement={agreement}
                  updateLastVisitSlide={handleUpdateLastVisitedSlide}
                  switchToNextSlide={switchToNextSlide}
                />
              )}
            </Carousel.Item>

            {/* Agreement Signature */}
            <Carousel.Item id="35" style={{ minHeight: "90vh" }}>
              {activeSlide === 34 ? (
                <AgreementSign
                  activeSlide={activeSlide}
                  agreement={agreement}
                  setAgreement={setAgreement}
                  updateLastVisitSlide={handleUpdateLastVisitedSlide}
                  switchToNextSlide={switchToNextSlide}
                  setSignedDocument={setSignedDocument}
                />
              ) : null}
            </Carousel.Item>

            {/* Signed Agreement */}
            <Carousel.Item id="36" style={{ height: "90vh" }}>
              {activeSlide === 35 ? (
                <SignedDocument
                  title="Executed Loan Agreement"
                  documentType="agreement"
                  document={signedDocument.agreement}
                  switchToNextSlide={handleCreateMandateForm}
                  updateLastVisitSlide={handleUpdateLastVisitedSlide}
                  loading={
                    updateJourneyLastVisitedSlideAPI.isPending ||
                    createMandateFormAPI.isPending ||
                    sendLeadDetailsViaEmailAPI.isPending ||
                    getAuthTokenAPI.isPending
                  }
                />
              ) : null}
            </Carousel.Item>

            {/* Business Details (J3) */}
            <Carousel.Item id="37">
              <BusinessDetails
                loading={loading}
                setLoading={setLoading}
                updateJourneyData={updateJourneyData}
                switchToNextSlide={switchToNextSlide}
              />
            </Carousel.Item>

            {/* Pre Approved Slide */}
            <Carousel.Item id="38">
              <PreApprovedOffers
                loanOffers={loanOffers}
                updateLastVisitSlide={handleUpdateLastVisitedSlide}
              />
            </Carousel.Item>

            {/* Journey Finish */}
            <Carousel.Item id="39">
              <JourneySuccessSlide redirectURL={props.profileData} />
            </Carousel.Item>

            {/* Commercial Journey Slides */}

            {/* Udyam Registration slide */}
            <Carousel.Item id="40">
              <UdyamRegistrationSlide
                switchToNextSlide={switchToNextSlide}
                loading={loading}
                updateJourneyData={updateJourneyData}
                setCustomerDetailsCookies={setCustomerDetailsCookies}
                setLoading={setLoading}
              />
            </Carousel.Item>

            {/* Company Details Slide */}
            <Carousel.Item id="41">
              <CompanyDetailsSlide
                switchToNextSlide={switchToNextSlide}
                loading={loading}
                updateJourneyData={updateJourneyData}
                setCustomerDetailsCookies={setCustomerDetailsCookies}
                setLoading={setLoading}
              />
            </Carousel.Item>

            {/* Company Address Details Slide */}
            <Carousel.Item id="42">
              <CompanyAddressDetailsSlide
                switchToNextSlide={switchToNextSlide}
                loading={loading}
                updateJourneyData={updateJourneyData}
                setCustomerDetailsCookies={setCustomerDetailsCookies}
                setLoading={setLoading}
              />
            </Carousel.Item>

            {/* GSTConsentSlides */}
            <Carousel.Item id="43">
              <GSTConsentSlides
                switchToNextSlide={switchToNextSlide}
                updateJourneyData={updateJourneyData}
              ></GSTConsentSlides>
            </Carousel.Item>

            {/* Authorized Signatory PAN Slides */}
            <Carousel.Item id="44">
              <AuthorizedSignatoryPANSlide
                switchToNextSlide={switchToNextSlide}
                loading={loading}
                updateJourneyData={updateJourneyData}
                setCustomerDetailsCookies={setCustomerDetailsCookies}
                setLoading={setLoading}
              />
            </Carousel.Item>

            {/* Bank Statements Slide */}
            <Carousel.Item id="45">
              <CommercialBankStatement
                switchToNextSlide={switchToNextSlide}
                updateJourneyData={updateJourneyData}
                updateLastVisitSlide={handleUpdateLastVisitedSlide}
              />
            </Carousel.Item>

            {/* Loan Amount Slide */}
            <Carousel.Item id="46">
              <LoanAmountSlide
                addJourneyLoanAmount={addJourneyLoanAmount}
                updateJourneyData={updateJourneyData}
                validateLoanAmount={validateLoanAmount}
                loading={loading}
              />
            </Carousel.Item>

            {/* Commercial Journey Success Slide */}
            <Carousel.Item id="47">
              <SuccessSlide redirectURL={props.profileData} />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  fields: state,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOtp,
      // verifyOtp,
      addJourney,
      journeyFields,
      resendOtp,
      realTimeLeadPush,
      getProfileDetails,
      getProfileDetailsSuccess,
      getAutoPullBureauDetailsSuccess,
      getAutoPullBureauDetails,
      realTimeLeadPushSuccess,
      realTimeLeadPushError,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(JourneyForm);
