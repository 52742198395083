import "./TextInputField.css";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ErrorContext from "../../../context/ErrorProvider";
import { useContext, useEffect, useState } from "react";

export default function TextInputField({
  type,
  label,
  placeholder,
  name,
  id,
  value,
  handler,
  errorType,
  onEnterHandler,
  reference,
  required,
  maxLength,
  minLength,
  uppercase = false,
  autoFocus,
  disabled,
  pattern = "",
  onCopy,
  onPaste,
}) {
  const { error } = useContext(ErrorContext);

  return (
    <div className="inputContainer">
      {label && (
        <label htmlFor={name}>
          {label}
          {required ? <span style={{ color: "red" }}>*</span> : null}
        </label>
      )}

      <div className="dataFieldBox">
        <div className={`"dataField`}>
          <input
            type={type && type}
            name={name && name}
            id={id && id}
            className={
              error.error && error.type === errorType
                ? "activeError"
                : "inActiveError"
            }
            value={value && value}
            minLength={minLength && minLength}
            maxLength={maxLength && maxLength}
            placeholder={placeholder && placeholder}
            ref={reference && reference}
            onChange={handler ? (e) => handler(e.target.value) : null}
            autoComplete="off"
            // onKeyUp={
            //   onEnterHandler
            //     ? (e) => (e.key === "Enter" ? onEnterHandler() : null)
            //     : null
            // }
            pattern={pattern && pattern}
            required={required}
            style={uppercase ? { textTransform: "uppercase" } : null}
            autoFocus={autoFocus}
            disabled={disabled && disabled}
            onCopy={onCopy}
            onPaste={onPaste}
          />
        </div>
        {error.error && error.type === errorType ? (
          <div className="errorBox">
            <ErrorOutlineRoundedIcon style={{ fontSize: "14px" }} />
            <p>{error.message}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
}
