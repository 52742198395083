import * as actions from "./types";

export const getProfileDetails = (param) => {
  return {
    type: actions.GET_PROFILE_DETAILS,
    payload: param,
  };
};

export const getProfileDetailsSuccess = () => ({
  type: actions.GET_PROFILE_DETAILS_SUCCESS,
});
