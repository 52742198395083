import { useRef, useContext, useState } from "react";
import SlideStyle from "../../../../styles/Slide/style.module.css";
import ContinueButton from "../../../Buttons/SlideContinueButton/ContinueButton";
import CustomerContext from "../../../../context/CustomerContext";
import { TOTAL_SLIDES } from "../../../../constants";

const CompanyDetailsSlide = ({ switchToNextSlide, updateJourneyData }) => {
  const { customerDetails, setCustomerDetails } = useContext(CustomerContext);
  const moveToNextSlide = async () => {
    let gstin = JSON.parse(
      customerDetails?.customerDetails?.UdyamRegistrationDetails[0]?.gstins
    );
    const slidePayload = {
      slideName: "Company Details",
      slideIndex: 40,
      slideIcon: "CompanyDetails",
      totalSlides: TOTAL_SLIDES,
    };
    let data = {};
    await updateJourneyData(data, slidePayload);
    if (customerDetails?.customerDetails?.UdyamRegistrationDetails[0]?.city) {
      switchToNextSlide(41);
    } else {
      if (gstin?.length === 0) {
        switchToNextSlide(43);
      } else {
        switchToNextSlide(42);
      }
    }
  };
  return (
    <div className={SlideStyle.carouselSlideWrapper}>
      <div className={SlideStyle.typographyContainer}>
        <h1>Company Basic Details</h1>
        {/* <p>Please fill all the details carefully.</p> */}
      </div>
      {/* Input Fields */}
      <div className={SlideStyle.autoFieldsContainer}>
        {/* <div className={SlideStyle.inputContainer}>
        
          <div className={SlideStyle.labelContainer}>
            <label>
              Company Name
            </label>
            <label className={SlideStyle.labelColor}>{customerDetails?.compane_name}</label>
          </div>
          <div className={SlideStyle.labelContainer}>
          <label>
              Company Type
            </label>
            <label className={SlideStyle.labelColor}>{customerDetails?.company_type ? customerDetails?.company_type : "-"}</label>
          </div>
          {c (
            <div className={SlideStyle.labelContainer}>
            <label>Company CIN</label>
            <label className={SlideStyle.labelColor}>{customerDetails?.company_cin}</label>
          </div>
          )}

        </div> */}
        <div className={SlideStyle.typographyContainer}>
          <p>Company Name</p>
          <h1 className="capitalize_text">
            {customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
              ?.enterprise_name
              ? customerDetails?.customerDetails?.UdyamRegistrationDetails[0]?.enterprise_name?.toLowerCase()
              : "-"}
          </h1>
        </div>

        <div className={SlideStyle.typographyContainer}>
          <p>Company Type</p>
          <h1>
            {customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
              ?.org_type
              ? customerDetails?.customerDetails?.UdyamRegistrationDetails[0]
                  ?.org_type
              : "-"}
          </h1>
        </div>

        {customerDetails?.compane_name && (
          <div className={SlideStyle.typographyContainer}>
            <p>Company CIN</p>
            <h1>
              {customerDetails?.company_cin
                ? customerDetails?.company_cin
                : "-"}
            </h1>
          </div>
        )}
      </div>

      {/* Continue Slide Button */}
      <div className={SlideStyle.continueBtn}>
        <ContinueButton
          handler={moveToNextSlide}
          isAutoFilled={true}
          disabled={false}
        />
      </div>
    </div>
  );
};

export default CompanyDetailsSlide;
