import "./OtpInputField.css";

export default function OtpInputField({
  reference,
  handler,
  error,
  errorType,
  onEnterHandler,
  tabIndex,
}) {
  return (
    <input
      tabIndex={tabIndex && tabIndex}
      type="text"
      className={`otpInputField ${
        error.error && error.type === errorType ? "otpInputError" : ""
      }`}
      name="otpKey"
      maxLength="1"
      ref={reference}
      onChange={(e) => handler(e.target.value)}
      onKeyUp={(e) =>
        e.key === "Backspace"
          ? e.target.previousSibling
            ? e.target.previousSibling?.focus()
            : null
          : null
      }
      // onKeyUp={
      //   onEnterHandler
      //     ? (e) =>
      //         e.key === "Enter"
      //           ? onEnterHandler()
      //           : e.key === "Backspace"
      //           ? e.target.previousSibling
      //             ? e.target.previousSibling?.focus()
      //             : null
      //           : null
      //     : null
      // }
    />
  );
}
