import { Document, Image, Page, View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 40,
    position: "relative",
    paddingBottom: 50
  },
  header: {
    fontSize: 20,
    marginBottom: 0,
    textAlign: "center",
  },
  paragraph: {
    marginBottom: 0,
    fontSize: 12,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 0,
  },
  footerContainer: {
    position: "absolute",
    bottom: 0,
    left: 20,
    right: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    borderTopWidth: 1,
    borderTopColor: "#000",
    paddingTop: 10,
    paddingBottom: 100,
  },
  image1: {
    width: 100,
    height: 20,
  },
  image2: {
    width: 150,
    height: 40,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    padding: 10,
  },
  contactNumber: {
    fontSize: 12,
    color: "grey",
  },
});

const PDF = ({ size, body, content }) => {
  return (
    <Document>
      <Page size={size} style={styles.page}>
        {
          content && content.length > 0 &&
          <View style={styles.headerContainer}>
            {content[0] && <Image style={styles.image1} src={`data:image/${content[0].type};base64,${content[0].base64}`} />}
            {content[1] && <Image style={styles.image2} src={`data:image/${content[1].type};base64,${content[1].base64}`} />}
          </View>
        }
        {body}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  );
};

export default PDF;
