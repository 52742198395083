import { useState, createContext, useRef } from "react";

const ModalContext = createContext({});

export function ModalContextProvider({ children }) {
  const loanOfferAgreementContainerRef = useRef();
  const initialState = {
    open: false,
    agreement: "",
  };
  const [toggleModal, setToggleModal] = useState(initialState);

  return (
    <ModalContext.Provider
      value={{ toggleModal, setToggleModal, initialState, loanOfferAgreementContainerRef }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export default ModalContext;
