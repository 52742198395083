import { useRef, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import style from "./PennyDrop.module.css";
// * Components
import TextInputField from "../../DataInputFields/TextInputField/TextInputField";
import ContinueButton from "../../Buttons/SlideContinueButton/ContinueButton";
import DropDownField from "../../DataInputFields/DropDownField/DropDownField";
// * Contexts
import ErrorContext from "../../../context/ErrorProvider";
import CustomerContext from "../../../context/CustomerContext";
import queryString from "query-string";
// * Constants
import { TOTAL_SLIDES, bankAccountTypes } from "../../../constants";
import * as Errors from "../../../utils/errors";
// * Helpers
import {
  isBlank,
  isAlphanumeric,
  containsOnlyNumbers,
  removeAllCookies,
} from "../../../helpers";
// * API Handler and APIs
import { useMutation } from "@tanstack/react-query";
import { verifyBankAccount } from "../../../api/services/Bank Verificaiton";
import { getAllSalarySlips } from "../../../api/services/MyFinDoc";

import {
  checkLeadEligibility,
  checkBREConfigureStatus,
} from "../../../api/services/BRE";
import { toast } from "react-toastify";
import { getCookiePathFromURL } from "../../../helpers";
import { useLocation } from "react-router-dom";

export default function PennyDrop({
  switchToNextSlide,
  updateLastVisitSlide,
  pipelineSettings,
  rejectLead,
}) {
  const CookiePath = getCookiePathFromURL(window.location.href);
  let domainName = window.location.host.split(".")[0];

  const { apiKey, apiSecret } = useParams();
  // * Global error state provider
  const { error, setError, resetError } = useContext(ErrorContext);
  const { customerDetails, setCustomerDetails } = useContext(CustomerContext);

  const [confirmedAccNumber, setConfirmedAccNumber] = useState(true);
  const [bankDetails, setBankDetails] = useState({
    bankDetailsFetched: false,
    name: "",
    bankName: "",
    city: "",
    branch: "",
  });

  const accountNumberRef = useRef();
  const accountNumberConfirmedRef = useRef();
  const ifscRef = useRef();
  const bankAccountTypeRef = useRef();

  let url = useLocation().search;
  let queryValues = queryString.parse(url);
  const JourneyVersion = queryValues?.jrnyTyp;

  const getAllSalarySlipsAPI = useMutation({
    mutationFn: (payload) => getAllSalarySlips(payload),
    onSuccess: (data) => handleGetAllSalarySlipsSuccess(data),
    onError: (data) => handleGetAllSalarySlipsError(data),
    enabled:
      domainName === "myfindoc" &&
      customerDetails.employment_type === "Salaried",
    retry: false,
  });
  const handleGetAllSalarySlipsSuccess = (data) => {
    if (data.data?.salarySlips?.length) {
      switchToNextSlide();
    } else {
      // * Update lead stage to reject and send reject SMS to the customer
      rejectLead();

      // * Finish Journey
      switchToNextSlide(38);
    }
  };
  const handleGetAllSalarySlipsError = (error) => {
    console.log("Error : ", error);
    switchToNextSlide(37);
  };
  const handleGetSalarySlips = () => {
    const headers = { apiKey, apiSecret };
    const data = {
      pipeline_id: customerDetails?.pipelineID,
      lead_id: customerDetails?.leadId,
      last_month_count: pipelineSettings?.salarySlipDurationInMonth,
    };
    const payload = { headers, data };
    getAllSalarySlipsAPI.mutate(payload);
  };

  const verifyBankAccountAPI = useMutation({
    mutationKey: ["verifyBankAccount"],
    mutationFn: (Payload) => verifyBankAccount(Payload),
    onSuccess: (data) => handleVerifyBankAcccountSuccess(data),
    onError: (error) => handleVerifyBankAcccountError(error),
    retry: false,
  });
  const handleVerifyBankAcccountSuccess = (data) => {
    const res = data.data;

    const bankDetails = res.BankDetail;
    setBankDetails({
      bankDetailsFetched: true,
      name: bankDetails?.account_holder_name || "-",
      bankName: bankDetails?.bank_name || "-",
      city: bankDetails?.city || "-",
      branch: bankDetails?.branch || "-",
    });
  };
  const handleVerifyBankAcccountError = (error) => {
    console.log("Bank verification error : ", error);
    toast.error("Something went wrong, please check your bank details.");
  };

  const checkBREConfigureStatusAPI = useMutation({
    mutationFn: (payload) => checkBREConfigureStatus(payload),
    onSuccess: (data) => handleCheckBREConfigureStatusSuccess(data),
  });
  const handleCheckBREConfigureStatusSuccess = (data) => {
    const BRE = data.data;

    if (BRE && Array.isArray(BRE) && BRE.length) {
      handleCheckLeadEligibility();
    } else {
      const slidePayload = {
        headers: { apiKey, apiSecret },
        leadId: customerDetails?.leadId,
        slideName: "Bank Account Verification",
        slideIndex: 28,
        slideIcon: "BankAccountVerification",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      // * Update Journey last visit slide
      updateLastVisitSlide(slidePayload);

      switchToNextSlide();
    }
  };
  const handleCheckBRECongiureStatus = () => {
    const pipelineId = customerDetails.pipelineID;
    const leadId = customerDetails.leadId;

    const headers = {
      apiKey,
      apiSecret,
    };
    const data = {
      pipeline_id: pipelineId,
      // lead_id: leadId,
    };
    const payload = {
      data,
      headers,
    };
    checkBREConfigureStatusAPI.mutate(payload);
  };

  const checkLeadEligibilityAPI = useMutation({
    mutationKey: ["checkLeadEligibility"],
    mutationFn: (Payload) => checkLeadEligibility(Payload),
    onSuccess: (data) => handleLeadEligibilitySuccess(data),
    onError: (error) => handleLeadEligibilityError(error),
    retry: false,
  });
  const handleLeadEligibilitySuccess = (data) => {
    const response = data.data;

    // switchToNextSlide();
    if (response.lead_move_final_status) {
      const slidePayload = {
        headers: { apiKey, apiSecret },
        leadId: customerDetails?.leadId,
        slideName: "Bank Account Verification",
        slideIndex: 28,
        slideIcon: "BankAccountVerification",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      // * Update Journey last visit slide
      updateLastVisitSlide(slidePayload);

      // if (
      //   customerDetails.employment_type === "Salaried" &&
      //   domainName === "myfindoc"
      // ) {
      //   handleGetSalarySlips();
      // } else {
      //   switchToNextSlide();
      // }

      switchToNextSlide();
      setCustomerDetails({ ...customerDetails, isBreFail: false });
    } else {
      setCustomerDetails({ ...customerDetails, isBreFail: true });
      const slidePayload = {
        headers: { apiKey, apiSecret },
        leadId: customerDetails?.leadId,
        slideName: "Success : Journey Completed",
        slideIndex: 38,
        slideIcon: "Success",
        totalSlides: TOTAL_SLIDES,
        journeyVersion: JourneyVersion,
      };
      // * Update Journey last visit slide
      updateLastVisitSlide(slidePayload);

      // * Update lead stage to reject and send reject SMS
      rejectLead();

      removeAllCookies(CookiePath);
      switchToNextSlide(38);
    }
  };
  const handleLeadEligibilityError = (error) => {
    // * Update Journey last visit slide
    updateLastVisitSlide(slidePayload);

    removeAllCookies(CookiePath);
    switchToNextSlide(37);
  };
  const handleCheckLeadEligibility = () => {
    const data = {
      pipeline_id: customerDetails.pipelineID,
      lead_id: customerDetails.leadId,
    };
    const headers = { apiKey, apiSecret };
    const payload = {
      data,
      headers,
    };
    checkLeadEligibilityAPI.mutate(payload);
  };

  const validateBankIfsc = (IFSC_Code) => {
    /*
      An IFSC code is an 11-digit alphanumeric code used by the central bank to identify the bank and bank branch under the NEFT network. The first four digits of the IFSC code represent the bank and the last six digits represent the branch whereas the fifth digit is always 0 for every bank and every branch.
    */
    ifscRef.current.value = IFSC_Code.toUpperCase();

    if (isBlank(IFSC_Code)) {
      setError({
        error: true,
        type: Errors.IFSC_CODE_ERROR_TYPE,
        message: Errors.NULL_IFSC_CODE_ERROR,
      });
      return false;
    }

    const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (!ifscRegex.test(IFSC_Code.toUpperCase())) {
      setError({
        error: true,
        type: Errors.IFSC_CODE_ERROR_TYPE,
        message: Errors.INVALID_IFSC_CODE_ERROR,
      });
      return false;
    }

    const invalidErrorObject = {
      error: true,
      type: Errors.IFSC_CODE_ERROR_TYPE,
      message: Errors.INVALID_IFSC_CODE_ERROR,
    };
    // Check if the first four characters are alphabets
    if (!/^[a-zA-Z]{4}/.test(IFSC_Code)) {
      setError(invalidErrorObject);
      return false;
    }

    // Check if the fifth character is 0
    if (IFSC_Code.charAt(4) !== "0") {
      setError(invalidErrorObject);
      return false;
    }

    if (!isAlphanumeric(IFSC_Code)) {
      setError(invalidErrorObject);
      return false;
    }

    if (IFSC_Code.length !== 11) {
      setError(invalidErrorObject);
      return false;
    }

    if (error.error && error.type === Errors.IFSC_CODE_ERROR_TYPE) {
      resetError();
    }
    return true;
  };

  const validateAccountNumber = (accountNumber) => {
    if (accountNumber.length < 6) {
      setConfirmedAccNumber(true);
      accountNumberConfirmedRef.current.value = "";
    } else if (accountNumber.length >= 6 && confirmedAccNumber) {
      setConfirmedAccNumber(false);
    }

    if (isBlank(accountNumber)) {
      setError({
        error: true,
        type: Errors.ACCOUNT_NUMBER_ERROR_TYPE,
        message: Errors.NULL_BANK_ACCOUNT_ERROR,
      });
      return false;
    }

    if (!containsOnlyNumbers(accountNumber)) {
      setError({
        error: true,
        type: Errors.ACCOUNT_NUMBER_ERROR_TYPE,
        message: Errors.INVALID_BANK_ACCOUNT_ERROR,
      });
      return false;
    }

    if (error.error && error.type === Errors.ACCOUNT_NUMBER_ERROR_TYPE) {
      resetError();
    }
    return true;
  };

  const handleSubmit = () => {
    const bankAccountNumber = accountNumberRef.current.value;
    const confirmBankAccountNumber = accountNumberConfirmedRef.current.value;
    const ifsc = ifscRef.current.value;
    const accountType = bankAccountTypeRef.current.value;

    if (validateAccountNumber(bankAccountNumber) && validateBankIfsc(ifsc)) {
      const headers = { apiKey, apiSecret };
      const bankDetails = {
        confirmBankAccountNumber: confirmBankAccountNumber.trim(),
        bankAccountNumber: bankAccountNumber.trim(),
        ifsc: ifsc.trim(),
        accountType,
      };
      const data = {
        contactId: customerDetails.contactID,
        pipelineId: customerDetails.pipelineID,
        leadId: customerDetails.leadId,
        bankDetails,
      };
      const Payload = {
        headers,
        data,
      };
      verifyBankAccountAPI.mutate(Payload);
    }
  };

  const handleConfirmAccountNumber = () => {
    const accountNumber = accountNumberRef.current?.value;
    const confirmedAccountNumber = accountNumberConfirmedRef.current?.value;

    if (!accountNumber && !confirmedAccNumber && !error.error) {
      setError(() => ({
        error: true,
        type: Errors.ACCOUNT_NUMBER_ERROR_TYPE,
        message: Errors.NULL_BANK_ACCOUNT_ERROR,
      }));

      return;
    }
    if (!confirmedAccountNumber && !confirmedAccNumber && !error.error) {
      setError(() => ({
        error: true,
        type: Errors.CONFIRM_ACCOUNT_NUMBER_ERROR_TYPE,
        message: Errors.NULL_BANK_ACCOUNT_ERROR,
      }));

      return;
    }

    if (accountNumber !== confirmedAccountNumber) {
      setError(() => ({
        error: true,
        type: Errors.CONFIRM_ACCOUNT_NUMBER_ERROR_TYPE,
        message: Errors.CONFIRM_BANK_ACCOUNT_MISMATCH_ERROR,
      }));

      return;
    }

    if (error.error && error.type === Errors.CONFIRM_ACCOUNT_NUMBER_ERROR_TYPE)
      resetError();

    return true;
  };

  const handleCopyPaste = (e) => {
    e.preventDefault();
  };

  return (
    <div className={style.slide}>
      <div className={style.slideHeader}>
        <h1>Bank Account Verification</h1>
        <p>
          Please enter the following bank details to verify your bank account.
        </p>
      </div>

      <div className={style.inputFieldBox}>
        <TextInputField
          type="password"
          label="Account Number"
          placeholder="Account Number"
          name="bankAccountNumber"
          id="bankAccountNUmber"
          minLength={11}
          maxLength={16}
          reference={accountNumberRef}
          handler={(accountNumber) => validateAccountNumber(accountNumber)}
          required={true}
          errorType={Errors.ACCOUNT_NUMBER_ERROR_TYPE}
          onCopy={handleCopyPaste}
          onPaste={handleCopyPaste}
          disabled={verifyBankAccountAPI.isPending}
        />
        <TextInputField
          type="text"
          label="Confirm Account Number"
          placeholder="Confirm Account Number"
          name="bankAccountNumberConfirm"
          id="bankAccountNUmberConfirm"
          minLength={11}
          maxLength={16}
          reference={accountNumberConfirmedRef}
          handler={(confirmedAccountNumber) =>
            handleConfirmAccountNumber(confirmedAccountNumber)
          }
          onCopy={handleCopyPaste}
          onPaste={handleCopyPaste}
          required={true}
          errorType={Errors.CONFIRM_ACCOUNT_NUMBER_ERROR_TYPE}
          disabled={confirmedAccNumber || verifyBankAccountAPI.isPending}
        />
        <TextInputField
          type="text"
          label="IFSC"
          placeholder="IFSC"
          name="ifscCode"
          id="ifscCode"
          maxLength={11}
          reference={ifscRef}
          handler={(IFSC_Code) => validateBankIfsc(IFSC_Code)}
          required={true}
          errorType={Errors.IFSC_CODE_ERROR_TYPE}
        />

        <DropDownField
          label="Bank Account Type"
          name="bankAccountType"
          reference={bankAccountTypeRef}
          required={true}
          options={bankAccountTypes}
          nameKey="name"
          valueKey="value"
          errorType={Errors.BANK_ACCOUNT_ERROR_TYPE}
          disabled={verifyBankAccountAPI.isPending}
          // handler={(value) =>
          //   validateDropdownInputs(
          //     value
          //   )
          // }
        />

        {bankDetails.bankDetailsFetched ? (
          <div className={style.bankDetails}>
            <div className={style.bankDetail}>
              <span>Name</span>
              <span style={{ fontWeight: 500 }}>{bankDetails.name}</span>
            </div>
            <div className={style.bankDetail}>
              <span>Bank Name</span>
              <span style={{ fontWeight: 500 }}>{bankDetails.bankName}</span>
            </div>
            <div className={style.bankDetail}>
              <span>City</span>
              <span style={{ fontWeight: 500 }}>{bankDetails.city}</span>
            </div>
            <div className={style.bankDetail}>
              <span>Branch</span>
              <span style={{ fontWeight: 500 }}>{bankDetails.branch}</span>
            </div>
          </div>
        ) : null}
      </div>

      <div>
        <ContinueButton
          loading={
            verifyBankAccountAPI.isPending ||
            checkBREConfigureStatusAPI.isPending ||
            checkLeadEligibilityAPI.isPending
          }
          label={
            bankDetails.bankDetailsFetched ? "Continue" : "Verify Bank Account"
          }
          handler={
            bankDetails.bankDetailsFetched
              ? handleCheckBRECongiureStatus
              : handleSubmit
          }
        />
      </div>
    </div>
  );
}
