import style from "./RangeInputField2.module.css";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ErrorContext from "../../../../context/ErrorProvider";
import { useContext } from "react";
// * Icons
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function RangeInputField({
  label,
  name,
  id,
  handler,
  min,
  max,
  step,
  value,
  errorType,
  reference,
  required,
  defaultValue,
  inputPlaceholder,
  inputDisabled,
  icon,
  customerNote,
}) {
  const { error } = useContext(ErrorContext);
  return (
    <div className={style.rangeInputField}>
      <label htmlFor={name}>
        {label && label}
        {required ? <span style={{ color: "red" }}>*</span> : null}
      </label>
      <div className={style.rangeInputBox}>
        {/* Decrement Button */}
        <button
          className={
            value <= min
              ? style.stateUpdateBtnInActive
              : style.stateUpdateBtnActive
          }
          disabled={value <= min}
          onClick={() => handler(value - step)}
        >
          <RemoveIcon className={style.stateUpdateIcon} />
        </button>

        <input
          type="range"
          id={name && name}
          name={name && name}
          onChange={(e) => handler(e.target.value)}
          min={min && min}
          max={max && max}
          step={step && step}
          value={value && value}
          // defaultValue={defaultValue && defaultValue}
        />

        {/* Increment Button */}
        <button
          className={
            value >= max
              ? style.stateUpdateBtnInActive
              : style.stateUpdateBtnActive
          }
          disabled={value >= max ? true : false}
          onClick={() => handler(value + step)}
        >
          <AddIcon className={style.stateUpdateIcon} />
        </button>
      </div>
      <div className="loanAmountInputContainer">
        <div
          className={`${style.loanAmount} ${
            error.error && error.type === errorType
              ? style.activeError
              : style.inActiveError
          }`}
        >
          {icon && icon}
          <input
            type="text"
            placeholder={inputPlaceholder && inputPlaceholder}
            id={id}
            ref={reference && reference}
            className={style.loanAmountInput}
            value={value && Intl.NumberFormat("en-US").format(value)}
            onChange={(e) => (handler ? handler(e.target.value) : null)}
            disabled={inputDisabled && inputDisabled}
          />
        </div>
        {error.error && error.type === errorType ? (
          <div className="errorBox">
            <ErrorOutlineRoundedIcon style={{ fontSize: "14px" }} />
            <p>{error.message}</p>
          </div>
        ) : null}
        {customerNote && (
          <span className={style.valueLimits}>*{customerNote}</span>
        )}
      </div>
    </div>
  );
}
