import * as actions from "../actions/types";
import * as urls from "../../urls";
import { all, call, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

function addJourney(params) {
  let domain = window.location.host.split(".")[0];
  if (window.location.host.includes("localhost")) {
    domain = "maxemocapital";
  }

  const { headers, body } = params;

  return axios.request({
    // withCredentials: true,
    method: "post",
    url: `${process.env.REACT_APP_API_BASE_URL}${urls.ADD_JOURNEY}${body.app_id}`,
    headers: {
      domain_name: domain,
      ...headers,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Methods": "*",
    },
    data: body,
  });
}

export function* addJourneyActionEffect(addJourneyActon) {
  const { payload, resolve, reject } = addJourneyActon;

  try {
    const { data } = yield call(addJourney, payload);
    if (data) {
      if (resolve) resolve(data);
    } else {
      reject(data);
    }
  } catch (e) {
    if (reject) reject(e);
  }
}

export function* addJourneyActonWatcher() {
  yield takeLatest(actions.ADD_JOURNEY, addJourneyActionEffect);
}

export default function* rootSaga() {
  yield all([fork(addJourneyActonWatcher)]);
}
