import "./ContinueButton.css";
// import { useState } from "react";
import Loader from "../../Loader/Loader";
// import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { useContext } from "react";
import ErrorConext from "../../../context/ErrorProvider";

export default function ContinueButton({
  handler,
  label,
  loading,
  tabIndex,
  disabled = false,
  isAutoFilled = false,
  cardButton,
  isUdyamAadhaarSlide = false,
}) {
  const { error } = useContext(ErrorConext);
  // const [isButtonClicked, setButtonClicked] = useState(false);

  // * Debounce
  // const handleClick = () => {
  //   if (!isButtonClicked) {
  //     setButtonClicked(true);
  //     handler();

  //     setTimeout(() => {
  //       setButtonClicked(false);
  //     }, 1000);
  //   }
  // };
  {
    console.log("LLoading=>", loading);
  }
  return (
    <div className="continueBtnContainer">
      {loading ? (
        <Loader />
      ) : (
        <button
          tabIndex={tabIndex && tabIndex}
          className={`continueBtn ${
            isUdyamAadhaarSlide
              ? "continueActiveButton"
              : error.error || disabled
              ? "continueInActiveButton"
              : "continueActiveButton"
          }`}
          disabled={error.error || disabled ? true : false}
          onClick={handler}
        >
          {label ? label : isAutoFilled ? "Confirm & Continue" : "Continue"}
        </button>
      )}
    </div>
  );
}
