// * Styles
import style from "./ImageInputField.module.css";
import { useState, useEffect } from "react";
// * Components
// import UplaodButton from "../../Buttons/UploadButton/UploadButton";
import ContinueButton from "../../Buttons/SlideContinueButton/ContinueButton";
// * Icons
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AddIcon from "@mui/icons-material/Add";

const ImageInputField = ({
  accept,
  allowedFileTypes,
  file,
  setFile,
  multiple,
  max = false,
  isDocAvailable,
  isLoading,
  hasDocument = false,
}) => {
  const [toggleUploadModal, setToggleUploadModal] = useState(false);
  const [currentFiles, setCurrentFiles] = useState([]);

  useEffect(() => {
    if (hasDocument) {
      setToggleUploadModal(false);
    }
  }, [hasDocument]);

  // handle drag event
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // handle drop event
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (multiple) {
      if (max && e?.dataTransfer?.files?.length > max) {
        let files = [...e?.dataTransfer?.files];
        files.length = max;
        setCurrentFiles(files);
      } else {
        setCurrentFiles([...e?.dataTransfer?.files]);
      }
    } else {
      setCurrentFiles([e?.dataTransfer?.files[0]]);
    }
  };

  // handle file input change event
  const handleFileChange = (e) => {
    if (multiple) {
      let files = [...e?.target?.files];
      if (max && files?.length > max) {
        files.length = max;
        setCurrentFiles(files);
      } else {
        setCurrentFiles((prev) => [...prev, ...e?.target?.files]);
      }
    } else {
      setCurrentFiles([e?.target?.files[0]]);
    }
  };

  const closeImageUploadModal = () => {
    setToggleUploadModal(false);
    setCurrentFiles([]);
  };

  const removeFiles = (fileName) => {
    setCurrentFiles(() =>
      currentFiles.filter((file) => file?.name !== fileName)
    );
  };

  const addDocument = () => {
    setFile([...file, ...currentFiles]);
    setCurrentFiles([]);
    setToggleUploadModal(false);
  };
  return (
    <>
      <button
        className={`${style.imageBtn}  ${
          isDocAvailable ? style.activeErrorButton : null
        }`}
        onClick={() =>
          file.length !== max ? setToggleUploadModal(true) : null
        }
      >
        <AddIcon
          style={{ fontSize: "40px" }}
          className={
            file?.length === max
              ? style.inActiveAddDocumentIcon
              : isDocAvailable
              ? style.errorAddDocumentIcon
              : style.activeAddDocumentIcon
          }
        />
      </button>

      {toggleUploadModal ? (
        <div className={style.imageUploadModalWrapper}>
          <div className={style.imageUploadModal}>
            <div className={style.modalHeader}>
              <button
                className={style.closeModalBtn}
                onClick={closeImageUploadModal}
                disabled={isLoading ? true : false}
              >
                <ClearIcon
                  className={`${isLoading ? style.closeModalBtnIcon : null}${
                    style.closeBtnIcon
                  }`}
                />
              </button>
            </div>

            <div className={style.dragAndDropInputContainer}>
              <div
                className={style.dragAndDropBox}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              >
                {currentFiles.length ? (
                  <div className={style.filePreview}>
                    {currentFiles.map((doc) =>
                      doc.type.includes("image") ? (
                        <div key={doc.name}>
                          <img src={URL.createObjectURL(doc)} alt="Preview" />
                          <ClearIcon
                            className={style.removeImageBtnIcon}
                            onClick={() => removeFiles(doc.name)}
                          />
                        </div>
                      ) : (
                        <div className={style.fileNamePreview} key={doc.name}>
                          <p className={style.fileName}>{doc.name}</p>
                          <ClearIcon
                            className={style.removeFileBtn}
                            onClick={() => removeFiles(doc.name)}
                          />
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div className={style.fileUpload}>
                    <CloudUploadIcon className={style.imageUploadIcon} />
                    <label htmlFor="file-input">
                      Drag and drop an image <br />
                      or click here to select a {multiple ? "files" : "file"}
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept={accept && accept}
                      onChange={handleFileChange}
                      multiple={multiple && multiple}
                    />
                  </div>
                )}
              </div>

              <div className={style.allowedFileTypes}>
                <p>
                  Allowed file types : {allowedFileTypes && allowedFileTypes}
                </p>
              </div>
            </div>

            <div className={style.uploadBtnContainer}>
              <ContinueButton
                label="Add Document"
                loading={isLoading}
                handler={addDocument}
                disabled={currentFiles.length ? false : true}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ImageInputField;
