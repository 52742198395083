// * API URLs
const JOURNEY_BASE_URL = "/customer"; // * Base URL
export const ADD_JOURNEY = "/api/v2/customer/lead/add/";
export const GET_OTP_URL = JOURNEY_BASE_URL + "/api/v2/customer/lead";
export const VERIFY_OTP_URL =
  JOURNEY_BASE_URL + "/api/v2/customer/lead/otp/verifiy";
// export const RESEND_OTP_URL =
//   JOURNEY_BASE_URL + "/api/v2/customer/lead/otp/resend";
export const RESEND_OTP_URL = "/api/v2/customer/lead/otp/resend";
export const JOURNEY_FILEDS = JOURNEY_BASE_URL + "/api/v2/customer/fields";
export const BUREAU_AUTO_PULL =
  JOURNEY_BASE_URL + "/api/v2/bank/checkAutoBureauPull";

// * API to Get Auth Token
export const GET_TOKEN = "/api/v2/auth/get-token";

// * Upload(Save)/Verify(Single Doc Verification)/Kyc Response Customer Documents
export const VERIFY_CUSTOMER_DOCUMENT = "/api/v2/customer/lead/journey";
// export const SAVE_CUSTOMER_DOCUMENT = "/api/v2/customer";
export const SAVE_CUSTOMER_DOCUMENT = "/api/v2/customer/lead/document-upload";
export const BULK_SAVE_CUSTOMER_DOCUMENT =
  "/api/v2/customer/lead/bulk-document-upload";
export const UPDATE_CUSTOMER_DOCUMENT =
  "/api/v2/customer/lead/updateCustomerDocument";
export const DELETE_CUSTOMER_DOCUMENT =
  "/api/v2/customer/deleteCustomerDocument";
export const SAVE_CUSTOMER_DOCUMENT_KYC_RESPONSE =
  "/api/v2/customer/lead/journey/saveDocumentKycResponse";
export const GET_CUSTOMER_DOCUMENTS = "/api/v2/customer/getCustomerDocuments";
export const GET_LEAD_DETAILS = "/api/v2/pipeline/journey/leads";
export const GET_CONSENT = "/api/v2/customer/journey/consent";
// * CRIF
export const GET_CRIF_DATA = "/api/v2/customer/lead/journey/crif";
export const GET_CRIF_DB_DATA = "/api/v2/customer/lead/journey/crif/detail";
// * Customer Offer Settings
export const GET_OFFER_SETTINGS =
  "/api/v2/customer/lead/journey/get-offer-setting";

// * Arya APIs URLs
export const UPLOAD_BANK_STATEMENT = "/api/v2/doc/verifyBankStatement";
export const UPLOAD_SALARY_SLIP = "/api/v2/doc/verifySalarySlip";
export const UPLOAD_FORM_16 = "/api/v2/doc/verifyForm16";
export const VALIDATE_CUSTOMER_PHOTO_LIVENESS =
  "/api/v2/doc/validatePhotoLiveness";

// * Others
export const GET_PROFILE_DETAILS = "/api/v2/users/getLenderDetails";
export const SEND_SMS_TO_CUSTOMER = "/api/v2/users/sendSMS";
export const CREATE_TIMELINE = "/api/v2/customer/journey/createTimeline";

// * Aadhaar Verfication
export const SEND_AADHAAR_OTP = "/api/v2/customer/lead/sendAadhaarOtp";
export const VERIFY_AADHAAR_OTP = "/api/v2/customer/lead/verifyAadhaarOtp";

// Commercial Journey APIs endpoint
export const FETCH_COMPANY_BASIC_DETAILS =
  "/api/v2/customer/lead/fetchCompanyDetails";
export const FETCH_PROBE42_COMPANY_BASIC_DETAILS =
  "/api/v2/customer/lead/company_base_details";
export const FETCH_UDYAM_REGISTRATION_DETAILS =
  "/api/v2/customer/lead/verifyUdyamRegNo";
export const GENERATE_OTP_FOR_CONSENT =
  "/api/v2/probe/business-details/generateOTP";
export const GET_COMPANY_BASIC_DETAILS =
  "/api/v2/customer/lead/getcompanyBasicDetails";
export const SUBMIT_CONSENT_OTP = "/api/v2/probe/business-details/submitOTP";

// * Loan Offer Agreement
export const LOAN_OFFER_AGREEMENT =
  "/api/v2/customer/lead/getLoanOfferAgreement";
export const ADD_LOAN_OFFFER_DETAIL = "/api/v2/agreement/addLoanOfferDetails";
export const GET_PRE_APPROVED_OFFER = "/api/v2/customer/getPreApprovedLoans";
// * Penny Drop (Bank Verification)
export const PENNY_DROP = "/api/v2/customer/lead/verifyBankAccount";
// To Fetch Bank Details
export const GET_BANK_DETAILS = "/api/v2/customer/lead/getBankDetails";

export const REAL_TIME_LEAD_PUSH_URL = "/api/v2/bank/realTimeLead/push";
export const UPDATE_JOURNEY_LAST_VISITED_SLIDE =
  "/api/v2/customer/updateJourneyLastVisitedSlide";
export const UPDATE_TIMESTAMP_APPLY_LINK =
  "/api/v2/customer/preApproved/timestamp";

// * Sanction Letter
export const GET_SANCTION_LETTER_TEMPLATE =
  "/api/v2/sanctionLetter/journey/getSanctionLetterTemplate";
export const GET_SANCTION_LETTER_DOCUMENT =
  "/api/v2/sanctionLetter/journey/getSanctionLetterDocument";
export const ADD_SANCTION_LETTER_DOCUMENT =
  "/api/v2/sanctionLetter/journey/addSanctionLetterDocument";
export const DELETE_SANCTION_LETTER_DOCUMENT =
  "/api/v2/sanctionLetter/journey/deleteSanctionLetterDocument";
export const UPDATE_SANCTION_LETTER_DOCUMENT =
  "/api/v2/sanctionLetter/journey/updateSanctionLetterDocument";
export const SEND_SANCTION_LETTER_MAIL =
  "/api/v2/sanctionLetter/journey/sendSanctionLetter ";

// * Agreement
export const GET_AGREEMENT_TEMPLATE =
  "/api/v2/agreement/journey/getLoanAgreement";
export const GET_AGREEMENT_DOCUMENT =
  "/api/v2/agreement/journey/getAgreementDocument";
export const ADD_AGREEMENT_DOCUMENT =
  "/api/v2/agreement/journey/addAgreementDocument";
export const UPDATE_AGREEMENT_DOCUMENT =
  "/api/v2/agreement/journey/updateAgreementDocument";
export const DELETE_AGREEMENT_DOCUMENT =
  "/api/v2/agreement/journey/deleteAgreementDocument";
export const SEND_AGREEMENT_MAIL = "/api/v2/agreement/journey/sendAgreement";

// * eNach - (Cashfree | Digio)

// * eNach - Cashfree
export const CREATE_PLAN = "/api/v2/customer/lead/createPlan";
export const CREAET_SUBSCRIPTION_WITH_PLAN_ID =
  "/api/v2/customer/lead/createSubscription";
export const CREATE_AUTHORISATION_REQUEST =
  "/api/v2/customer/lead/createAuthorisationRequest";
export const GET_AUTHORISATION_REQUEST =
  "/api/v2/customer/lead/getAuthorisationStatus";
// * eNach - Digio
export const CREATE_MANDATE_FORM = "/api/v2/customer/lead/createMandateForm";
export const GET_MANDATE_DETAILS = "/api/v2/customer/lead/getMandateDetails";

// * eSign
export const UPLOAD_DOCUMENT_FOR_ESIGN = "/api/v2/customer/lead/uploadDocument";
export const SAVE_SIGNED_DOCUMENT = "/api/v2/customer/lead/saveSignedDocument";

// * Befisc
export const CHECK_PHONE_CONNECTED_WITH_PAN_OR_NOT =
  "/api/v2/customer/lead/pan-ocr";

// Comercial Journey APIs

export const ADD_AUTHORIZED_SIGNATORY_PAN =
  "/api/v2/customer/lead/addAuthorizedSignatoryPAN";

export const FETCHED_PAN_DETAILS =
  "/api/v2/customer/lead/fetchPANCompanyDetails";

export const MOBILE_TO_MSME = "/api/v2/customer/lead/fetchMobileToMSME";

export const ALL_IN_ONE_PAN = "/api/v2/customer/lead/fetchAllPANDetails";

export const GET_UPDATED_CUSTOMER_DETAILS =
  "/api/v2/customer/lead/fetchUpdatedContactDetails";

export const VALIDATE_PAN = "/api/v2/customer/lead/validatePAN";

export const GET_UDYAM_DETAILS = "/api/v2/customer/lead/getUdyamDetails";

// Last slide Details
export const GET_LAST_SLIDE_DETAILS =
  "/api/v2/customer/lead/journey/getLastVisitedSlide";

// * LMS
export const GET_AUTH_TOKEN = "/api/v2/lms/get-auth-token";
export const CREATE_CLIENT = "/api/v2/lms/create-customer";
export const CREATE_CLIENT_IDENTIFIER = "/api/v2/lms/create-client-identifiers";
export const CREATE_ADDRESS_DETAILS = "/api/v2/lms/create-address-details";
export const CREATE_BANK_ACCOUNT = "/api/v2/lms/create-customer-bank-account";
export const UPLOAD_IDENTIFIER_DOCUMENTS =
  "/api/v2/lms/upload-document-identifier";
export const UPLOAD_CLEINT_DOCUMENT = "/api/v2/lms/upload-document";
export const CREATE_LOAN = "/api/v2/lms/create-loan";
export const APPROVE_LOAN_AMOUNT = "/api/v2/lms/approve-loan";
export const DISBURSE_LOAN_AMOUNT = "/api/v2/lms/disburse-loan";

// * Others
export const SEND_LEAD_DETAILS_TO_ADMING_VIA_MAIL =
  "/api/v2/customer/lead/sendLeadToAdmin";
export const GET_LEAD_DETAILS_MAXIMO =
  "/api/v2/customer/lead/journey/get-lead-details";
export const UPDATE_LEAD_STAGE_STATUS =
  "/api/v2/bank/journey/lead/stage-change/update";

export const GET_REPAYMENT_HISTORY_MAXEMO =
  "/api/v2/customer/lead/genarate-emi-schedule/repayment";

// * BRE
export const CHECK_BRE_CONFIGURE_STATUS =
  "/api/v2/bre-checks/get-assigned-bre-list";
export const CHECK_LEAD_ELIGIBILITY =
  "/api/v2/bre-checks/check-lead-is-eligible-or-not";

// * Pipeline
export const GET_PIPELINE_SETTINGS =
  "/api/v2/customer/lead/journey/getPipelineSettings";

// * AuthBridge
export const ANALYZE_BANK_STATEMENT =
  "/api/v2/customer/lead/journey/analyzeBankStatement";
export const ANALYZE_SALARY_SLIP =
  "/api/v2/customer/lead/journey/analyzeSalarySlip";

// * Maxemo
export const GET_ALL_SALARY_SLIPS =
  "/api/v2/customer/lead/get-all-salary-slips";
export const GET_INTEREST_RATE = "/api/v2/customer/lead/get-rate-of-interest";

// * SMS
export const GET_SMS_STAGE_WISE = "/api/v2/pipeline/journey/get-sms-stage-wise";
export const SEND_STAGE_WISE_INDIVIDUAL_SMS =
  "/api/v2/sms-template/journey/send-stage-wise-individual-sms";
