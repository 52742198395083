import * as actions from "../actions/types";
import * as urls from "../../urls";
import { all, call, fork, takeLatest } from "redux-saga/effects";
import axios from "axios";

function resendOtpApi(params) {
  let domain = window.location.host.split(".")[0];
  if (window.location.host.includes("localhost")) {
    domain = "maxemocapital";
  }

  let headerObj = { apiKey: params.apiKey, apiSecret: params.apiSecret };
  let bodyObj = params.resendOtpData;

  return axios.request({
    withCredentials: true,
    method: "post",
    url: `${urls.RESEND_OTP_URL}`,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Methods": "*",
      domain_name: domain,
      ...headerObj,
    },
    data: bodyObj,
  });
}

export function* resendOtpActionEffect(getOtpAction) {
  const { payload, resolve, reject } = getOtpAction;

  try {
    const { data } = yield call(resendOtpApi, payload);
    if (data) {
      if (resolve) resolve(data);
    } else {
      reject(data);
    }
  } catch (e) {
    if (reject) reject(e);
  }
}

export function* resendOtpActionWatcher() {
  yield takeLatest(actions.RESEND_OTP, resendOtpActionEffect);
}

export default function* rootSaga() {
  yield all([fork(resendOtpActionWatcher)]);
}
