import imageCompression from "browser-image-compression";

const base64ToBlob = (base64) => {
  const byteString = atob(base64.split(",")[1]);
  const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const compressBase64Image = async (
  base64,
  options = { maxSizeMB: 1, useWebWorker: true, alwaysKeepResolution: true }
) => {
  try {
    const originalBlob = base64ToBlob(base64);
    console.log(
      "Original file size:",
      (originalBlob.size / 1024 / 1024).toFixed(2),
      "MB"
    );

    const compressedBlob = await imageCompression(originalBlob, options);
    console.log(
      "Compressed file size:",
      (compressedBlob.size / 1024 / 1024).toFixed(2),
      "MB"
    );

    const compressedBase64 = await blobToBase64(compressedBlob);
    return compressedBase64;
  } catch (error) {
    console.error("Error compressing the image:", error);
    throw error;
  }
};

export default compressBase64Image;
